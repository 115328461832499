import { commonrequest } from "./ApiCall";
import {BASE_URL} from "./helper";

//<------------------------------------------- User Apis ------------------------------------------->
export const checkUserEmailExist = async(email)=>{
    return await commonrequest("GET", `${BASE_URL}/check-user-email/${email}`, "", "");
}

export const socialLogin = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/social-login`, data, header);
}

export const socialSignUp = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/social-signup`, data, header);
}

export const signUpFunc = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/signup`, data, header);
}

export const verifyEmailFunc = async(token, header)=>{
    return await commonrequest("POST", `${BASE_URL}/verify-email`, token, header);
}

export const getAllUsers = async(search)=>{
    return await commonrequest("GET", `${BASE_URL}/all-users?search=${search}`, "", "");
}

export const completeProfile = async(data, header, id)=>{
    return await commonrequest("PUT", `${BASE_URL}/complete-profile/${id}`, data, header);
}

export const getSingleUser = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/single-user/${username}`, "", "");
}

export const userLogin = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/user-login`, data, header);
}

export const userStatus = async(id, data)=>{
    return await commonrequest("PUT", `${BASE_URL}/user-status/${id}`,{data})
}

export const userDelete = async(id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-user/${id}`,{});
}

export const exportUserData = async(id)=>{
    return await commonrequest("GET", `${BASE_URL}/export-user-data/${id}`,"")
}
//<------------------------------------------- Profile Update Apis ------------------------------------------->
export const generalUpdate = async(data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/general-update`, data, header);
}

export const profileUpdate = async(data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/profile-update`, data, header);
}

export const userCoverImgUpdate = async(data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/user-coverimg-update`, data, header);
}

export const passwordUpdate = async(data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/password-update`, data, header);
}

//<------------------------------------------- Social Link Apis ------------------------------------------->
export const socialAdd = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/add-social-links`, data, header);
}

export const socialUpdate = async(data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/update-social-links`, data, header);
}

export const userSocial = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/get-social-links/${username}`, "", "");
}

//<------------------------------------------- Posts Apis ------------------------------------------->
export const addPost = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/add-post`, data, header);
}

export const getAllPosts = async()=>{
    return await commonrequest("GET", `${BASE_URL}/all-posts`, "", "");
}

export const getLatestPosts = async()=>{
    return await commonrequest("GET", `${BASE_URL}/latest-posts`, "", "");
}

export const getAllPostsWithUser = async(search,featured, username, tags)=>{
    return await commonrequest("GET", `${BASE_URL}/all-post-user?search=${search}&featured=${featured}&currentUser=${username}&tags=${tags}`, "", "");
}

export const getUserPosts = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/user-posts/${username}`, "", "");
}

export const getSinglePost = async(id)=>{
    return await commonrequest("GET", `${BASE_URL}/single-post/${id}`, "", "");
}

export const postStatus = async(id, data)=>{
    return await commonrequest("PUT", `${BASE_URL}/post-status/${id}`,{data})
}

export const postUpdate = async(id, data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/post-update/${id}`,data, header);
}

export const postDelete = async(id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-post/${id}`,{});
}

export const addPostCat = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/add-post-cat`, data, header);
}

export const getAllCats = async()=>{
    return await commonrequest("GET", `${BASE_URL}/all-cats`, "", "");
}

export const featuredCats = async()=>{
    return await commonrequest("GET", `${BASE_URL}/featured-cats`, "", "");
}

export const updateCatFeatured = async(id)=>{
    return await commonrequest("PUT", `${BASE_URL}/update-cat-featured/${id}`, {});
}

export const catDelete = async(id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-cat/${id}`,{});
}

export const addPostTag = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/add-post-tag`, data, header);
}

export const getAllTags = async()=>{
    return await commonrequest("GET", `${BASE_URL}/all-tags`, "", "");
}

export const featuredTags = async()=>{
    return await commonrequest("GET", `${BASE_URL}/featured-tags`, "", "");
}

export const updatePostTags = async(id, data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/update-post-tags/${id}`,data, header);
}

export const tagDelete = async(id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-tag/${id}`,{});
}

//<------------------------------------------- Like Apis ------------------------------------------->
export const postLikeUnlike = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/post-like`, data, header);
}

export const getUserLikedPosts = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/user-liked-post/${username}`, "", "");
}

export const increasePostShareViews = async(id, data)=>{
    return await commonrequest("PUT", `${BASE_URL}/increase-post-share-views/${id}`,data)
}

//<------------------------------------------- Comment Apis ------------------------------------------->
export const addComment = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/post-comment`, data, header);
}

export const getCommentsByPostid = async(post_id)=>{
    return await commonrequest("GET", `${BASE_URL}/comments-by-postid/${post_id}`, "", "");
}

export const getAllCommentsWithUser = async()=>{
    return await commonrequest("GET", `${BASE_URL}/all-comments-with-user`, "", "");
}
//<------------------------------------------- Jobs Apis ------------------------------------------->
export const addJob = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/job-post`, data, header);
}

export const getUserPostedJobs = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/user-posted-jobs/${username}`, "", "");
}

export const getAllJobs = async()=>{
    return await commonrequest("GET", `${BASE_URL}/all-jobs`, "", "");
}

export const getFilteredJobs = async(search, designerType, workplaceType, location, employmentType)=>{
    return await commonrequest("GET", `${BASE_URL}/filtered-jobs?search=${search}&designer_type=${designerType}&workplace_type=${workplaceType}&location=${location}&employment_type=${employmentType}`, "", "");
}

export const getSingleJob = async(id)=>{
    return await commonrequest("GET", `${BASE_URL}/single-job/${id}`, "", "");
}

export const jobStatus = async(id, data)=>{
    return await commonrequest("PUT", `${BASE_URL}/job-status/${id}`,{data})
}

export const jobUpdate = async(id, data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/job-update/${id}`,data, header);
}

export const jobDelete = async(id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-job/${id}`,{});
}

//<------------------------------------------- Message Apis ------------------------------------------->
export const sendUserMessage = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/send-message`, data, header);
}

export const getUserMessages = async(sender, receiver)=>{
    return await commonrequest("GET", `${BASE_URL}/get-messages/${sender}/${receiver}`, "", "");
}

export const createChat = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/create-chat`, data, header);
}

export const getUserChats = async(sender, chatSearch)=>{
    return await commonrequest("GET", `${BASE_URL}/get-chat/${sender}?chatSearchKey=${chatSearch}`, "", "");
}  

export const getUnseenMsgs = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/get-unseen-messages/${username}`, "", "");
}

export const updateMsgStatus = async(data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/mark-as-seen`, data, header);
}

export const getAllChats = async()=>{
    return await commonrequest("GET", `${BASE_URL}/all-chats`, "", "");
}

export const msgByChatId = async(id)=>{
    return await commonrequest("GET", `${BASE_URL}/msg-by-chatid/${id}`,{});
}

export const chatById = async(id)=>{
    return await commonrequest("GET", `${BASE_URL}/chat-by-id/${id}`,{});
}

export const chatDelete = async(id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-chat/${id}`,{});
}
//<------------------------------------------- Collection Apis ------------------------------------------->
export const addCollection = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/add-collection`, data, header);
}

export const getUserCollections = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/user-collections/${username}`, "", "");
}

export const updateTagFeatured = async(id)=>{
    return await commonrequest("PUT", `${BASE_URL}/update-tag-featured/${id}`, {});
}
//<------------------------------------------- Collection Apis ------------------------------------------->
export const userFollowUnfollow = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/user-follow-unfollow`, data, header);
}

export const getUserFollowers = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/user-followers/${username}`, "", "");
}

export const getUserFollowings = async(username)=>{
    return await commonrequest("GET", `${BASE_URL}/user-followings/${username}`, "", "");
}

//<------------------------------------------- Admin Apis ------------------------------------------->
export const addAdmin = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/add-admin`, data, header);
}

export const adminLogin = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/admin-login`, data, header);
}

export const getAllAdmins = async()=>{
    return await commonrequest("GET", `${BASE_URL}/all-admins`, "", "");
}

export const getSingleAdmin= async(id)=>{
    return await commonrequest("GET", `${BASE_URL}/single-admin/${id}`,"","");   
}

export const adminUpdate = async(id, data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/update-admin/${id}`,data, header);
}

export const adminDelete = async(id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-admin/${id}`,{});
}

export const adminStatus = async(id, data)=>{
    return await commonrequest("PUT", `${BASE_URL}/admin-status/${id}`,{data})
}

export const adminRole = async(id, data)=>{
    return await commonrequest("PUT", `${BASE_URL}/admin-role/${id}`,{data})
}

//<------------------------------------------- Admin Access Apis ------------------------------------------->
export const addAdminAccess = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/add-admin-access`, data, header);
}

export const getSingleAdminAccess = async(user_id)=>{
    return await commonrequest("GET", `${BASE_URL}/single-admin-access/${user_id}`,"","");   
}

export const updateAdminAccess = async(user_id, data, header)=>{
    return await commonrequest("PUT", `${BASE_URL}/update-admin-access/${user_id}`,data, header);
}

export const deleteAdminAccess = async(user_id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-admin-access/${user_id}`,{});
}

//<------------------------------------------- Notification Apis ------------------------------------------->
export const sendNotif = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/send-notif`, data, header);
}

export const getSingleUserNotifs = async(receiver)=>{
    return await commonrequest("GET", `${BASE_URL}/single-user-notif/${receiver}`,"","");   
}

export const getSingleUserUnseenNotifs = async(receiver)=>{
    return await commonrequest("GET", `${BASE_URL}/unseen-user-notif/${receiver}`,"","");   
}

export const updateNotifStatus = async(id)=>{
    return await commonrequest("PUT", `${BASE_URL}/update-notif-status/${id}`,{});
}

export const getNotifsByAdmin = async()=>{
    return await commonrequest("GET", `${BASE_URL}/notifs-by-admin`,"","");   
}

export const deleteNotif = async(id)=>{
    return await commonrequest("DELETE", `${BASE_URL}/delete-notif/${id}`,{});
}

//<------------------------------------------- Email Apis ------------------------------------------->
export const sendEmail = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/send-email`, data, header);
}

export const getAllEmails = async()=>{
    return await commonrequest("GET", `${BASE_URL}/all-emails`, "", "");
}

//<------------------------------------------- contactUs Apis ------------------------------------------->
export const contactUsApi = async(data, header)=>{
    return await commonrequest("POST", `${BASE_URL}/contact-us`, data, header);
}