import React, { useEffect, useState } from 'react';
import './search.css';
import { Link, useNavigate } from 'react-router-dom';
import SmallFooter from '../../components/footer/SmallFooter';
import { FaMagnifyingGlass } from "react-icons/fa6";
import CommonSmallHeader from '../../components/header/small-screen/CommonSmallHeader';
import { createChat, getAllPostsWithUser, getAllUsers } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import { isTokenValid } from '../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';

const SearchPage = () => {
    const [isValid, setIsValid] = useState(false);
    useEffect(() => {
        const checkTokenValidity = async () => {
            try {
                const valid = await isTokenValid();
                setIsValid(valid);
            } catch (error) {
                console.error("Error checking token validity:", error);
            }
        };
        checkTokenValidity();
    }, []);

    const navigate = useNavigate();
    const username = sessionStorage.getItem('user_username');

    const [searchKey, setSearchKey] = useState('');

    const [allUsers, setAllUsers] = useState([]);
    const fetchAllUsers = async () => {
        try {
            const res = await getAllUsers(searchKey);
            if (res.status === 200) {
                setAllUsers(res.data);
            } else {
                console.log('Error in Fetching Users.');
            }
        } catch (error) {
            console.error('Error fetching Users:', error);
        }
    };

    const [allPosts, setAllPosts] = useState([]);
    const fetchAllPosts = async () => {
        try {
            const res = await getAllPostsWithUser(searchKey, "", "", "");
            if (res.status === 200) {
                setAllPosts(res.data);
                console.log(res.data);
            } else {
                console.log('Error in Fetching Users.');
            }
        } catch (error) {
            console.error('Error fetching Users:', error);
        }
    };

    useEffect(() => {
        fetchAllUsers();
        fetchAllPosts();
    }, [searchKey]);

    const openChat = async (receiver) => {
        if (isValid === true) {
            const data = { sender: username, receiver };
            try {
                const config = { 'Content-Type': 'application/json', };
                const res = await createChat(data, config);
                if (res.status === 200) {
                    navigate("/message");
                }
            } catch (error) {
                console.error('Error:', error.response.data);
            }
        }
        else {
            // alert("Please Login First");
            // navigate("/login");
            toast.error('Please Login First', {
                onClose: () => navigate('/login')
            });
        }
    }

    return (
        <>
            <CommonSmallHeader headerTitle={"Search"} />
            <div className="container-fluid" id="small-page-topbar">
                <div className="row">
                    <div className="col-12">
                        <div className='search-bar'>
                            <form class="d-flex">
                                <button class="btn-search" type="submit"><FaMagnifyingGlass /></button>
                                <input class="form-control me-2" type="search" placeholder="Search User..." aria-label="Search" onChange={(e) => setSearchKey(e.target.value)} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="small-search-page">

                {searchKey ? (<div className="head">Search Result Users</div>) : (<div className="head">Latest Users</div>)}
                <hr />
                <div className="latest-users row">
                    {allUsers.length > 0 ? (
                        allUsers.slice(0, 5).map((user, index) => (
                            <Link to={`/${user.username}`} className='profile-link'>
                            <div className="col-12 user-div" key={index}>
                                <div className="user-img">
                                    <img src={`${BASE_URL}/uploads/admins-users-img/${user.user_img}`} alt={user.user_img} />
                                </div>
                                <div className="user-name">
                                    <p style={{"fontWeight":"500"}}>{user.username}</p>
                                    <p>{user.fname + " " + user.lname}</p>
                                    <p>{user.location}</p>
                                </div>
                                {/* <div className="options">
                                    <Link to={`/${user.username}`}><i className="fa-solid fa-user"></i></Link>
                                    <button type="button" onClick={() => openChat(user.username)}><i className="fa-solid fa-comment-dots"></i></button>
                                </div> */}
                            </div>
                            </Link>
                        ))
                    ) : null}

                </div>

                {searchKey ? (<div className="head mt-3">Search Result Posts</div>) : (<div className="head  mt-3">Latest Posts</div>)}
                <hr />
                {/* <div className="latest-posts row"> */}
                <div className="latest-posts">
                    {allPosts.length > 0 ? (
                        allPosts.slice(0, 4).map((post, index) => (
                            // <div className="col-6 post-div" key={index} onClick={() => navigate(`/view-post/${post.user.username}/${post._id}`)}>
                            //     <div className="img-div">
                            //         <img src={`${BASE_URL}/uploads/posts-img/${post.post_img}`} alt={post.post_img} />
                            //     </div>
                            // </div>
                            <div className="post-div" key={index} onClick={() => navigate(`/view-post/${post.user.username}/${post._id}`)}>
                                <div className="img-div">
                                    <img src={`${BASE_URL}/uploads/posts-img/${post.post_img}`} alt={post.post_img} />
                                </div>
                            </div>
                        ))
                    ) : null}
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={1000} />
            <SmallFooter />
        </>
    )
}

export default SearchPage