import React, { useState } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { WorldAlytics } from './Charts/GeoCharts';
function WorldMapGraph() {
    const [duration, setDuration] = useState('Monthly');
    const[blockNone,setBlockNone]=useState('none');
    // const blockNone='none';
    const handleDisplayFlexNone=()=>{
        if(blockNone==='none')
            setBlockNone('flex');
        else
        setBlockNone('none');
    }
    return (
        <div id='worldMapGraph'>
            <div id='worldMapGraphHeader'>
                <h4>Sale by Country</h4>
                <h4>Repoted By: <span>{duration} <IoIosArrowDown onClick={handleDisplayFlexNone}/></span></h4>
                <div style={{display:blockNone}} className="dropdownMenu " >
                    <a href="#">Yearly</a>
                    <a href="#">Monthly</a>
                    <a href="#">Weekly</a>
                    <a href="#">Today</a>
                </div>
            </div>
            <div id='worldMapGraphSvg'>
                <WorldAlytics id='worldGraph'/>
            </div>
            <div id='worldMapGraphRegion'>
                <p >USA <span>75%</span></p>
                <div style={{ borderRadius: '30px', display: 'flex', alignItems: 'center', width: "100%", height: "6%", backgroundColor: "#d4dae5" }}>
                    <div style={{ borderRadius: '30px', width: "75%", height: "60%", backgroundColor: "#6d7fcc" }} >
                    </div>
                </div>

                <p >Russia <span>55%</span></p>
                <div style={{ borderRadius: '30px', display: 'flex', alignItems: 'center', width: "100%", height: "6%", backgroundColor: "#d4dae5" }}>
                    <div style={{ borderRadius: '30px', width: "55%", height: "60%", backgroundColor: "#6d7fcc" }} >
                    </div>
                </div>

                <p >Australia <span>85%</span></p>
                <div style={{ borderRadius: '30px', display: 'flex', alignItems: 'center', width: "100%", height: "6%", backgroundColor: "#d4dae5" }}>
                    <div style={{ borderRadius: '30px', width: "85%", height: "60%", backgroundColor: "#6d7fcc" }} >
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorldMapGraph