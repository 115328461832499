import React, { useContext } from "react";
import { Chart } from "react-google-charts";
import { pageTheme } from "../../../components/context/ContextProvider";

export const data = [
  ["Year", "Sales", "Expenses"],
  ["2020", 1000, 400],
  ["2021", 1170, 460],
  ["2022", 660, 1120],
  ["2023", 1030, 540],
];



export function  Performance() {
  const { theme } = useContext(pageTheme);
   const options = {
    title: "Website Performance",
    curveType: "function",
    legend: { position: "bottom" },
    backgroundColor: theme === 'dark-th' ? '#032d46' : '#fff',
  };
  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="110%"
      data={data}
      options={options}
    />
  );
}