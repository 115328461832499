import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { getAllAdmins, adminStatus, adminRole, adminDelete, addAdminAccess, deleteAdminAccess, getAllUsers, userStatus, userDelete } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import { NavLink } from 'react-router-dom';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';

const ViewAdmin = () => {

  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const res = await getAllUsers("");
      if (res.status === 200) {
        setUsers(res.data);
      } else {
        console.log('Error in Fetching Users.');
      }
    } catch (error) {
      console.error('Error fetching Users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const roleChange = async (id, prev_role, curr_role) => {
    const response = await adminRole(id, curr_role);
    if (response.status === 200) {
      if (prev_role === "super_admin" && curr_role === "minor_admin") {
        const data = { user_id: id };
        const config2 = { "Content-Type": "application/json" };
        const res = await addAdminAccess(data, config2);
        if (res.status === 200) {
          alert("Admin Role Changed to Minor Admin");
        }
      }
      else if (prev_role === "minor_admin" && curr_role === "super_admin") {
        const res = await deleteAdminAccess(id);
        if (res.status === 200) {
          alert("Admin Role Changed to Super Admin");
        }
      }
      fetchUsers();
    }
    else {
      alert("Cannot Update Admin Role");
    }
  }

  const statusChange = async (id, status) => {
    const response = await userStatus(id, status);
    if (response.status === 200) {
      fetchUsers();
    }
    else {
      alert("Cannot Update User Status");
    }
  }

  const handleDeleteAdmin = async (id) => {
    const response = await userDelete(id);
    if (response.status === 200) {
      fetchUsers();
    }
    else {
      alert("Cannot Delete Admin");
    }
  }


  return (
    <>
      <AccessChecker accessKey="view_admins" />
      <div className='form-card expand-card'>
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Admins</h1>
            <div><Link to="/admin/add-admin"><button className='btn btn-primary'>Add Admin</button></Link></div>
          </div>
        </div>
        <div>
          <hr />
          <table class="table table-bordered" style={{ width: '90%', margin: 'auto', marginTop: '30px' }}>
            <thead>
              <th className='text-left'>Sl No.</th>
              <th>Profile</th>
              <th>Fullname</th>
              <th>Username</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>
            </thead>

            <tbody>
              {users.filter(user => user.user_type !== "user").map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <img src={`${BASE_URL}/uploads/admins-users-img/${user.user_img}`} width="60px" height="60px" style={{ borderRadius: "50%" }} />
                  </td>
                  <td>{user.fname + " " + user.lname}</td>
                  <td>{user.username}</td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                        <Badge bg={user.user_type == "minor_admin" ? "success" : "primary"}>
                          {user.user_type === "minor_admin" ? "Minor Admin" : "Super Admin"} <i className="fa-solid fa-angle-down"></i>
                        </Badge>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => roleChange(user._id, user.user_type, "super_admin")}>Super Admin</Dropdown.Item>
                        <Dropdown.Item onClick={() => roleChange(user._id, user.user_type, "minor_admin")}>Minor Admin</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                        <Badge bg={user.status == "active" ? "primary" : "danger"}>
                          {user.status === "active" ? "Active" : "InActive"} <i className="fa-solid fa-angle-down"></i>
                        </Badge>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => statusChange(user._id, "active")}>Active</Dropdown.Item>
                        <Dropdown.Item onClick={() => statusChange(user._id, "inactive")}>InActive</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle variant="light" className="action" id="dropdown-basic">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink to={`/admin/single-admin/${user.username}`} className="text-decoration-none">
                            <i className="fa-solid fa-eye" style={{ color: "green" }}></i> <span>View</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink to={`/admin/edit-admin/${user._id}`} className="text-decoration-none">
                            <i className="fa-solid fa-pen-to-square" style={{ color: "blue" }}></i> <span>Edit</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          {user.username === "yoeavnu_admin" ? null : (
                            <div onClick={() => handleDeleteAdmin(user._id)}>
                              <i className="fa-solid fa-trash" style={{ color: "red" }}></i> <span>Delete</span>
                            </div>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}

            </tbody>

          </table>
        </div>

      </div>
    </>
  )
}

export default ViewAdmin;