import { BASE_URL } from '../../../services/helper';
import axios from 'axios';
// For Encryption and Decryption of Password
import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = "84ee106cf2019ad8a3f445644e590134becb7c6daa61783fc2fea649ee8e1b51";

function decrypt(text) {
    const decrypted = CryptoJS.AES.decrypt(text, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return decrypted;
}

const isTokenValid = async () => {
    const user_token = sessionStorage.getItem('user_token');

    if (user_token) {
        try {
            const res = await axios.get(`${BASE_URL}/user-token-validation`, {
                headers: {
                    Authorization: `Bearer ${user_token}`
                }
            });
            return res.status === 200; // Return true if response status is 200
        } catch (error) {
            sessionStorage.clear();
            return false;
        }
    }
    else {
        sessionStorage.clear();
        return false;
    }
};

function isValidUsername(username) {
    if (username.length < 3 || username.length > 30) {
        return false;
    }
    const alphabetRegex = /[a-zA-Z]/;
    if (!alphabetRegex.test(username)) {
        return false;
    }
    const regex = /^[a-zA-Z0-9._]+$/;
    if (!regex.test(username)) {
        return false;
    }

    return true;
}

//This Converts date time to 22 May, 2025
function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        console.error('Invalid date format:', dateString);
        return 'Invalid date';
    }

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month}, ${year}`;
}

//This Converts date time to dd/mm/yyyy
function formatDate2(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export { isTokenValid, isValidUsername, formatDate, formatDate2, decrypt };