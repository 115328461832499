import React, { useState, useEffect } from 'react';
import './Admin.css';
import { Link } from 'react-router-dom';
import { getAllAdmins, getAllUsers } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';

const MinorAdmins = () => {

    const [admins, setAdmins] = useState([]);

    const fetchAdmins = async () => {
        try {
            const res = await getAllUsers("");
            if (res.status === 200) {
                setAdmins(res.data);
                console.log(admins);
            } else {
                console.log('Error in Fetching Admins.');
            }
        } catch (error) {
            console.error('Error fetching Admins:', error);
        }
    };

    useEffect(() => {
        fetchAdmins();
    }, []);

    return (
        <>
            <AccessChecker accessKey="change_admin_access" />
            <div className="form-card" id="minor-admin">
                <div className="row">
                    <div className="col-md-12 form-head">
                        <h1>Minor Admins</h1>
                    </div>
                </div>
                <hr />
                <div className="form-div">
                    <div className="row d-flex justify-content-center">
                        {admins.filter(admin => admin.user_type === "minor_admin").map((admin, index) => (
                            <div className="col-lg-3 admin-wrapper" key={index}>
                                <Link to={`/admin/admin-access/${admin._id}`}>
                                    <div className='admin-card'>
                                        <div className='admin-img'><img src={`${BASE_URL}/uploads/admins-users-img/${admin.user_img}`} alt={admin.username} /></div>
                                        <div className='info'><span className='tag'>Username</span> : <span className='value'>{admin.username}</span></div>
                                        <div className='info'><span className='tag'>Status</span> : <span className={`value ${admin.status === "active" ? "text-success" : "text-danger"}`}>{admin.status}</span></div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MinorAdmins