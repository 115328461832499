import React from 'react'

function OrdersData() {
    return (
        <div id='orderDataContainer'>
            <h4> OrdersData</h4>
        </div>
    )
}

export default OrdersData