import React from 'react'

const ProfileProjects = () => {
  return (
    <>
    <div className='col-12 d-flex justify-content-center'>
    <div>
      <img src="assets/images/no-data.png" width="300px" />
      <h4 className='text-center mt-2 text'>No Projects to Show</h4>
    </div>
    </div>
    </>
  )
}

export default ProfileProjects