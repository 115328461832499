import React, {useState, useEffect} from 'react'
import '../editProfile.css' ;
import { socialAdd, userSocial, socialUpdate } from '../../../../../services/Apis';
import { ToastContainer, toast } from 'react-toastify';

const Social = () => {
  const user_id = sessionStorage.getItem("user_id");
  const username = sessionStorage.getItem("user_username");
  const [socialLinks, setSocialLinks] = useState({ user_id: "", username: "", facebook: "", instagram: "", twitter: "", linkedin: "", github: "", youtube: ""});
  const [linkPresent, setLinkPresent] = useState();

  const getSocialLinks = async () => {
    const res = await userSocial(username);
    if (res.status === 200) {
      if(res.data===null){
        setLinkPresent(false)
      }
      else{ 
        setLinkPresent(true)
        setSocialLinks(res.data);
      }
    } else {
      console.log("Error in Fetching User Details.");
    }
  };

  useEffect(() => {
    getSocialLinks();
  }, [username]);

  const handleChange =(e)=>{
    const {name, value} = e.target;
    setSocialLinks({...socialLinks, [name]:value}) ;
  }

  const handleSubmit = async(e) =>{
    e.preventDefault();
    if(linkPresent){
      const {user_id , facebook, instagram, twitter, linkedin, github, youtube} = socialLinks;
      try {
        const dataToSend = { user_id, facebook, instagram, twitter, linkedin, github, youtube };
        const config = { "Content-Type":"application/json" }
        const response = await socialUpdate(dataToSend, config);
        if (response.status === 200) {
          // alert("Successfully Updated Social Links");
          toast.success("Successfully Updated Social Links");
        } else {
          // alert("Sorry Social Links Update Unsuccessful");
          toast.error("Sorry Social Links Update Unsuccessful");
        }
      } catch (error) {
        console.error("Error:", error);
        // alert("An error occurred while updating");
        toast.error("An error occurred while updating");
      }
    }
    else{
      try {
        const config = { "Content-Type":"application/json" }
        socialLinks.user_id = user_id;
        socialLinks.username = username;
        const response = await socialAdd(socialLinks, config);

        if (response.status === 200) {
          // alert("Successfully Added Social Links");
          toast.success("Successfully Added Social Links");
        } else {
          // alert("Sorry Unable to Add Social Links Now");
          toast.error("Sorry Unable to Add Social Links Now");
        }
      } catch (error) {
        console.error("Error:", error);
        // alert("An error occurred while adding");
        toast.error("An error occurred while adding");
      }
    }
  }

  return (
    <>
    <form id="general-form" onSubmit={handleSubmit}>
    <div class="mb-4 mt-3">
    <label htmlFor="fb-link" class="form-label">Facebook Link :</label>
    <input type="text" class="form-control" id="fb-link" name="facebook" value={socialLinks.facebook} onChange={handleChange}/>
    </div>
    <div class="mb-4">
    <label htmlFor="insta-link" class="form-label">Instagram Link :</label>
    <input type="text" class="form-control" id="insta-link" name="instagram" value={socialLinks.instagram} onChange={handleChange}/>
    </div>
    <div class="mb-4">
    <label htmlFor="twitter-link" class="form-label">Twitter Link :</label>
    <input type="text" class="form-control" id="twitter-link" name="twitter" value={socialLinks.twitter} onChange={handleChange}/>
    </div>
    <div class="mb-4">
    <label htmlFor="linkedin-link" class="form-label">LinkedIn Link :</label>
    <input type="text" class="form-control" id="linkedin-link" name="linkedin" value={socialLinks.linkedin} onChange={handleChange}/>
    </div>
    <div class="mb-4">
    <label htmlFor="github-link" class="form-label">Github Link :</label>
    <input type="text" class="form-control" id="github-link" name="github" value={socialLinks.github} onChange={handleChange}/>
    </div>
    <div class="mb-4">
    <label htmlFor="youtube-link" class="form-label">YouTube Link :</label>
    <input type="text" class="form-control" id="youtube-link" name="youtube" value={socialLinks.youtube} onChange={handleChange}/>
    </div>
    <div className='mb-4 edit-profile-btn'>
    <button type="submit">Update Social Profiles</button>
    </div>
    </form>
      <ToastContainer position="top-center" autoClose={1000}/>
    </>
  )
}

export default Social