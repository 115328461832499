import React from 'react';
import { NavLink } from 'react-router-dom';
import { GoHomeFill } from "react-icons/go";
import { RiEditBoxFill } from "react-icons/ri";
import { FaMoon, FaSun } from "react-icons/fa";
import { FaBrush } from "react-icons/fa6";
import { MdPalette } from "react-icons/md";
import { TbTriangleInvertedFilled, TbTriangleFilled } from "react-icons/tb";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { IoChatbubblesSharp } from "react-icons/io5";
import { BiSolidBriefcaseAlt2 } from "react-icons/bi";
import { useLocation } from 'react-router-dom';

const HomeSidebar = ({ showMenu, checkAndNavigate, setPageTheme, themeMenu, setThemeMenu }) => {
  // const location = useLocation();
  // console.log("curerent path ="+location.pathname)
  return (
    <>
      <div className={`main-sidebar half-sidebar ${showMenu ? 'd-none' : ''}`}>
        <div id="sidebar-wrapper">
          <div className='icon-wrap active-icon-wrap'>
            <NavLink to="/"><div className='side-icon'><GoHomeFill /></div><div className="icon-title text-center">Home</div></NavLink>
          </div>
          <div className='icon-wrap'>
            <div className='side-icon' onClick={()=>checkAndNavigate("/post")}><RiEditBoxFill /></div><div className="icon-title text-center">Create Post</div>
          </div>
          <div className='icon-wrap'>
            <NavLink to="/jobs"><div className='side-icon'><BiSolidBriefcaseAlt2 /></div><div className="icon-title text-center">Jobs</div></NavLink>
          </div>
          <div className='icon-wrap'>
          <div className='side-icon' onClick={()=>checkAndNavigate("/message")} ><IoChatbubblesSharp /></div><div className="icon-title text-center">Chats</div>
          </div>
          <DropdownButton className='theme icon-wrap' id="dropdown-button-drop-end" title={<>
            <div className='side-icon'><MdPalette /></div><div className="icon-title text-center">Theme</div>
          </>} key={'end'} drop={'end'}>
            <Dropdown.Item onClick={() => setPageTheme("dark_th")}><div className='icon-wrap'><div className='side-icon'><FaMoon /></div></div></Dropdown.Item>
            <Dropdown.Item onClick={() => setPageTheme("light_th")}><div className='icon-wrap'><div className='side-icon'><FaSun /></div></div></Dropdown.Item>
            <Dropdown.Item onClick={() => setPageTheme("blue_th")}><div className='icon-wrap'><div className='side-icon'><FaBrush /></div></div></Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className={`main-sidebar full-sidebar ${showMenu ? '' : 'd-none'}`}>
        <div id="sidebar-wrapper">
          <div className='icon-wrap'>
            <NavLink to="/"><div className='side-icon'><GoHomeFill /><p className="icon-title">Home</p></div></NavLink>
          </div>
          <div className='icon-wrap'>
            <div className='side-icon' onClick={()=>checkAndNavigate("/post")}><RiEditBoxFill /><p className="icon-title">Create Post</p></div>
          </div>
          <div className='icon-wrap'>
            <NavLink to="/jobs"><div className='side-icon'><BiSolidBriefcaseAlt2 /><p className="icon-title">Jobs</p></div></NavLink>
          </div>
          <div className='icon-wrap'>
            <div className='side-icon' onClick={()=>checkAndNavigate("/message")} ><IoChatbubblesSharp /><p className="icon-title">Chats</p></div>
          </div>
          <div className='icon-wrap d-flex justify-content-between align-items-center' onClick={() => setThemeMenu(!themeMenu)}><div className='side-icon'><MdPalette /><p className="icon-title">Theme</p></div>&nbsp;{themeMenu ? (<TbTriangleFilled style={{ "fontSize": "17px" }} />) : (<TbTriangleInvertedFilled style={{ "fontSize": "17px" }} />)}</div>
          <ul className={themeMenu ? ('') : ('d-none')}>
            <li><div className='icon-wrap'><div onClick={() => setPageTheme("dark_th")}><div className='side-icon'><FaMoon /><p className="icon-title">Dark Theme</p></div></div></div></li>
            <li><div className='icon-wrap'><div onClick={() => setPageTheme("light_th")}><div className='side-icon'><FaSun /><p className="icon-title">Light Theme</p></div></div></div></li>
            <li><div className='icon-wrap'><div onClick={() => setPageTheme("blue_th")}><div className='side-icon'><FaBrush /><p className="icon-title">Blue Theme</p></div></div></div></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default HomeSidebar;