import React, { useState, useEffect } from 'react';
import "./Notif.css";
import { getAllUsers, sendNotif } from '../../../services/Apis';
import { useNavigate } from 'react-router-dom';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';

const SendNotif = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const res = await getAllUsers();
      if (res.status === 200) {
        setUsers(res.data);
      } else {
        console.log('Error in Fetching Users.');
      }
    } catch (error) {
      console.error('Error fetching Users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const username = sessionStorage.getItem("admin_username");

  const [data, setData] = useState({
    receiver: "",
    sender: "yoeavnu_123",
    sender_type: "admin",
    redirect_link: "",
    content: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = { "Content-Type": "application/json" }
    const res = await sendNotif(data, config);
    if (res.status === 200) {
      alert("Notification Sent Successfully");
      navigate("/admin/view-notifications");
    }
    else {
      if (res.response.data.message) {
        alert(res.response.data.message)
      }
      else {
        alert("Some Unknown Error Occured")
      }
    }
  };

  return (
    <>
      <AccessChecker accessKey="send_notification" />
      <div className="form-card" id="send-notif">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Send Notification</h1>
          </div>
        </div>
        <hr />
        <div className="form-div">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <label className='pb-3'>Select Reeceiver</label>
                <select className='form-select form-control' name="receiver" onChange={handleChange}>
                  <option>-------- Select Receiever --------</option>
                  {users.map((user) => (
                    <option value={user.username}>{user.username}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6">
                <label className='pb-3'>Redirect URL (Leave empty if no URL)</label>
                <input type='text' className='form-control' name="redirect_link" onChange={handleChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10">
                <label className='py-3'>Notification Content</label>
                <textarea className='form-control' rows="2" name="content" onChange={handleChange}></textarea>
              </div>
              <div className="col-lg-2">
                <div className='d-flex w-100 h-100 align-items-end justify-content-end'>
                  <button className='btn btn-primary send-btn' type="submit">Send</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default SendNotif;