import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmailFunc } from "../../../services/Apis";
import { jwtDecode } from 'jwt-decode';

const VerifyEmail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const navigate = useNavigate();
  //   console.log("Token from URL:", token);
  
  const [verifClick, setVerifClick] = useState(true);

  const verifyToken = async () => {
    try {
      const config = { "Content-Type": "application/json" };
      const res = await verifyEmailFunc({token}, config);
      if (res.status === 200) {
        // setVerifClick(false);
        // setTimeout(() => {
        //   navigate("/login");
        // }, 3000);
        // console.log(res.data);
        const token = res.data;
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.userId;
            const username = decodedToken.username;
            sessionStorage.setItem('user_token', token);
            sessionStorage.setItem('user_id', userId);
            sessionStorage.setItem('user_username', username);
            navigate(`/complete-profile/${userId}`);
      }
      else{
        // console.log(res.response.data.message);
        if(res.response.data.message){
            alert(res.response.data.message);
        }
        else{
            alert("Invalid Link Please Signup Again");
        }
      }
    } catch (error) {
      alert("Verification failed. Invalid or expired token.");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {verifClick ? (
            <div className="col-lg-12">
              <button onClick={() => verifyToken()}>Confirm Email</button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
