import React, { useState } from 'react';
import './Mail.css';
import { sendEmail } from '../../../services/Apis';
import { useNavigate } from 'react-router-dom';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';

const SendMail = () => {

  const navigate = useNavigate();

  const [mailData, setMailData] = useState({
    sender_email: "",
    receiver_email: "",
    subject: "",
    message: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = { "Content-Type": "application/json" }
    const res = await sendEmail(mailData, config);
    if (res.status === 200) {
      alert("Notification Sent Successfully");
      // navigate("/admin/view-user");
    }
    else {
      if (res.response.data.message) {
        alert(res.response.data.message)
      }
      else {
        alert("Some Unknown Error Occured")
      }
    }
  };

  return (
    <>
      <AccessChecker accessKey="send_mail" />
      <div className="form-card" id="send-mail">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Send Mail</h1>
          </div>
        </div>
        <hr />
        <form className='px-3' onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <label className='py-3'>Your Email</label>
              {/* <input type="email" name="sender_email" className='form-control' onChange={handleChange} /> */}
              <select type="email" name="sender_email" className='form-control' onChange={handleChange} required>
                <option value="" >------ Choose your Email ------</option>
                <option value="contact@yoeavnu.com">contact@yoeavnu.com</option>
                <option value="no-reply@yoeavnu.com">no-reply@yoeavnu.com</option>
              </select>
            </div>
            <div className="col-lg-6">
              <label className='py-3'>Receiver Email</label>
              <input type="email" name="receiver_email" className='form-control' onChange={handleChange} required/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <label className='py-3'>Subject</label>
              <input type='text' name="subject" className='form-control' onChange={handleChange} required/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <label className='py-3'>Message</label>
              <textarea rows="5" name="message" className='form-control' onChange={handleChange} required></textarea>
            </div>
          </div>
          <div className="col-lg-12 mt-3 d-flex justify-content-end">
            <button className='btn btn-primary' type="submit">Send Email</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default SendMail