import React, { useState, useEffect } from 'react'
import HeaderTop from '../../components/header/large-screen/HeaderTop'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSingleJob } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import SmallFooter from '../../components/footer/SmallFooter';

const ViewJob = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [inputData, setInputData] = useState({
    username: "",
    title: "",
    description: "",
    location: "",
    workplace_type: "",
    designer_type: "",
    employment_type: "",
    apply_link: "",
    company_name: "",
    company_logo: "",
    website: "",
    offer_date: "",
    status: ""
  });

  const { job_id } = useParams();
  const singleJobData = async () => {
    const response = await getSingleJob(job_id);
    if (response.status === 200) {
      setInputData(response.data.singleJob);
      console.log(response.data.singleJob);
    }
    else {
      console.log("Error in Fetching Job Details.");
    }
  }

  useEffect(() => {
    singleJobData();
  }, [])

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  return (
    <>
    {isSmallScreen?(''):(<HeaderTop />)}
      <div className="container-fluid" id="view-user-job">
        <div className="go-back">
          <Link to="/jobs"><i class="fa-solid fa-angle-left"></i>&nbsp;All Jobs</Link>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="job-details">
              <div className="head">Job Details</div>
              <div className="title">{inputData.title}</div>
              <div className="description" dangerouslySetInnerHTML={{ __html: inputData.description }} />
              <div className="apply-btn btn-1"><a href={inputData.apply_link} target='_blank'>Apply for this position</a></div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="profile-wrap">
                <div className="comp-profile">
                  <div className="logo-div"><img src={`${BASE_URL}/uploads/jobs-img/${inputData.company_logo}`} /></div>
                  <div className="comp-name">{inputData.company_name}</div>
                  <div className="web-link"><Link>Visit Website</Link></div>
                  <div className="apply-btn"><a href={inputData.apply_link} target='_blank'>Apply for this position</a></div>
                </div>
                <div className="divider"></div>
                <div className="more-details">
                  <p className='head'>Job Type</p>
                  <p className='info'>{inputData.employment_type}</p>
                </div>
                <div className="more-details">
                  <p className='head'>Location</p>
                  <p className='info'>{inputData.location}</p>
                  <p className='info'>{inputData.workplace_type}</p>
                </div>
                <div className="more-details" style={{"margin":"0"}}>
                  <p className='head'>Date Posted</p>
                  <p className='info'>{formatDate(inputData.offer_date)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSmallScreen?(<SmallFooter />):(<Footer />)}
      
    </>
  )
}

export default ViewJob