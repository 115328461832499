import React, { useState, useEffect } from 'react';
import './functions.css';
const LocationSuggestion = ({ query, limit, onSuggestionClick }) => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.trim() !== '') {
        try {
          const response = await fetch(`https://photon.komoot.io/api/?q=${query}&limit=${limit}`);
          const data = await response.json();
          if (data.features) {
            const uniqueSuggestions = new Set();
            data.features.forEach(feature => {
              const city = feature.properties.name || '';
              const state = feature.properties.state || '';
              const country = feature.properties.country || '';
              if (state && country) {
                uniqueSuggestions.add(`${city}, ${state}, ${country}`);
              } else if (country) {
                uniqueSuggestions.add(`${city}, ${country}`);
              } else {
                uniqueSuggestions.add(city);
              }
            });
            setSuggestions(Array.from(uniqueSuggestions));
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    };
    fetchSuggestions();
  }, [query, limit]);

  return (
    <div id='loc-suggestion'>
      <ul>
        {suggestions.map((suggestion, index) => (
          <li  key={index} onClick={() => onSuggestionClick(suggestion)}>{suggestion}</li>
        ))}
      </ul>
    </div>
  );
};

export default LocationSuggestion;
