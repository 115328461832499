import React,{useState, useEffect} from 'react';
import LMessage from './LMessage';
import SMessage from './SMessage';

const MessagePage = () => {
  
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {isSmallScreen?(<>
    <SMessage />
    </>):(<>
    <LMessage />
    </>)}
    </>
  )
}

export default MessagePage