import React, { useEffect, useState } from 'react';
import './Login.css';
import light_logo from '../../assets/images/logo/light_logo.jpg';
import { useNavigate } from 'react-router-dom';
import { adminLogin, userLogin } from '../../../services/Apis';
import { jwtDecode } from 'jwt-decode';
import { isTokenValid } from '../../components/OtherFunctions/otherFunctions';
import { toast } from 'react-toastify';

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenValidity = async () => {
      const isValid = await isTokenValid();
      if (isValid) {
        navigate("/admin");
      }
    };
    checkTokenValidity();
  }, [navigate]);

  const [loginData, setLoginData] = useState({
    username: "",
    password: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        "Content-Type": "application/json"
      }
      const res = await userLogin(loginData, config);
      if (res.status === 200) {
        const token = res.data;
        const decodedToken = jwtDecode(token);
        if ((decodedToken.user_type === "super_admin") || (decodedToken.user_type === "minor_admin")) {
          sessionStorage.setItem('admin_token', token);
          sessionStorage.setItem('admin_id', decodedToken.userId);
          sessionStorage.setItem('admin_username', decodedToken.username);
          navigate("/admin");
        }
        else {
          toast.error("Sorry only Admins are allowed");
          navigate("/");
        }
      }
      else {
        if (res.response.data.message) {
          alert(res.response.data.message)
        }
        else {
          alert("Some Unknown Error Occured")
        }
      }
    }
    catch (error) {
      console.error('Error:', error.res.response.data);
    }
  };

  return (
    <>
      <div className="container-fluid" id="admin-login">
        <div className="row admin-row">
          <div className="col-lg-4">
            <div className="login-wrapper">
              <div className='login-heading'>
                <div className="welcome-txt">Hello Admin ! Welcome to</div>
                <div className="m-auto"><img src={light_logo} className='logo' /></div>
              </div>
              <form onSubmit={handleSubmit}>
                {/* <div className="form-group">
                  <label>Enter Email</label>
                  <input type="email" className='form-control' name="email" onChange={handleChange} required />
                </div> */}
                <div className="form-group">
                  <label>Enter Username</label>
                  <input type="text" className='form-control' name="username" onChange={handleChange} required />
                </div>
                <div className="form-group">
                  <label>Enter Password</label>
                  <input type="password" className='form-control' name="password" onChange={handleChange} required />
                </div>
                <div className="form-group d-flex justify-content-center">
                  <button className='login-btn' type='submit'>Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login