import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { chatDelete, getAllChats } from '../../../services/Apis';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';

const ViewChats = () => {
    const navigate = useNavigate();

    const [chats, setChats] = useState([]);

    const fetchAllChats = async () => {
        try {
            const res = await getAllChats();
            setChats(res.data);
        } catch (error) {
            console.error('Error fetching User Chats:', error);
        }
    };

    useEffect(() => {
        fetchAllChats();
    }, []);

    const formatDateTime = (dateTimeString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        const dateTime = new Date(dateTimeString);
        return new Intl.DateTimeFormat('en-US', options).format(dateTime);
    };

    const handleDeletChat = async (id) => {
        const response = await chatDelete(id);
        if (response.status === 200) {
            fetchAllChats();
        }
        else {
          alert("Cannot Delete Job");
        }
      }

    return (
        <>
            <AccessChecker accessKey="view_user_chats" />
            <div className='form-card expand-card'>
                <div className="row">
                    <div className="col-md-12 form-head d-flex justify-content-between">
                        <h1>View Chats</h1>
                        <div>
                            <Link to="/admin/send-message">
                                <button className='btn btn-primary'>Start Chat</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <hr />
                    <table class="table table-bordered" style={{ width: '90%', margin: 'auto', marginTop: '30px' }}>
                        <thead>
                            <th className='text-left'>Sl No.</th>
                            <th>Sender</th>
                            <th>Receiver</th>
                            <th>Chat Started</th>
                            <th>View</th>
                            <th>Delete</th>
                        </thead>
                        <tbody>
                            {chats.map((chat, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{chat.sender}</td>
                                    <td>{chat.receiver}</td>
                                    <td>{formatDateTime(chat.chatCreated)}</td>
                                    <td>
                                        <button className='btn btn-primary' onClick={() => navigate(`/admin/view-messages/${chat._id}`)}>
                                            <i className="fa-solid fa-eye"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className='btn btn-danger' onClick={()=>handleDeletChat(chat._id)}>
                                            <i className="fa-solid fa-trash" style={{"color":"#fff"}}></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ViewChats;
