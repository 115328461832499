import React, { useState } from 'react'
import '../editProfile.css' ;
import { passwordUpdate } from '../../../../../services/Apis';
import { ToastContainer, toast } from 'react-toastify';
import { decrypt } from '../../../../components/functions/OtherFunctions';

const Pwd = ({userData}) => {
  const [pass, setPass] = useState({
    old_password:"",
    new_password:"",
    confirm_new_password:""
  })

  const handleChange =(e)=>{
    const {name, value} = e.target;
    setPass({...pass, [name]:value}) ;
  }

  const handleSubmit = async(e) =>{
    e.preventDefault();
    const {old_password, new_password, confirm_new_password} = pass;
    const {_id, password} = userData;
    const old_decrypted_password = decrypt(password);
    if(old_password !== old_decrypted_password){
      // alert("Please Enter Previous Password Correctly");
      toast.error("Please Enter your Old Password Correctly");
    }
    else if( new_password !== confirm_new_password){
      // alert("Please Enter Same Password for Confirm Password");
      toast.error("Please Enter Same Password for Confirm Password");
    }
    else{  
      try {
        const config = { "Content-Type":"application/json" }
        const dataToSend = { id: _id, password: confirm_new_password};
        const response = await passwordUpdate(dataToSend, config);

        if (response.status === 200) {
          // alert("Password Successfully Updated");
          toast.success("Password Successfully Updated");
        } else {
          // alert("Sorry Password Update Unsuccessful");
          toast.error("Sorry Password Update Unsuccessful");
        }
      } catch (error) {
        console.error("Error:", error);
        // alert("An error occurred while updating");
        toast.error("An error occurred while updating");
      }
    }
  }

  return (
    <>
    <form id="general-form" onSubmit={handleSubmit}>
    <div class="mb-4 mt-3">
    <label htmlFor="old-pwd" class="form-label">Old Password :</label>
    <input type="text" class="form-control" id="old-pwd" name="old_password" onChange={handleChange} required/>
    </div>
    <div class="mb-4">
    <label htmlFor="new-pwd1" class="form-label">New Password :</label>
    <input type="text" class="form-control" id="new-pwd1" name="new_password" onChange={handleChange} required/>
    </div>
    <div class="mb-4">
    <label htmlFor="new-pwd2" class="form-label">Confirm New Password :</label>
    <input type="text" class="form-control" id="new-pwd2" name="confirm_new_password" onChange={handleChange} required/>
    </div>
    <div className='mb-4 edit-profile-btn'>
    <button type="submit">Update Password</button>
    </div>
    </form>
      <ToastContainer position="top-center" autoClose={1000}/>
    </>
  )
}

export default Pwd