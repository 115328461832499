import React, { useState, useEffect } from 'react';
import LJobs from './large-view/LJobs';
import SJobs from './small-view/SJobs';

const Jobs = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {isSmallScreen ? (
      <SJobs />
      ) : (
      <LJobs />
      )}
    </>
  )
}

export default Jobs