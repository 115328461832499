const API_KEY = 'Jkyn8IjvZuyDSodMjtI49qkE12BevLwFWw1HA9wJCJ8tQGn70DGCmbSUGc5jM97D';
const SITE_ID = 'webzed.in';

const PlausableAnalytics = async () => {
  try {
    const response = await fetch(`https://plausible.io/api/v1/stats/aggregate?site_id=${SITE_ID}&period=6mo&metrics=visitors,pageviews,bounce_rate,visit_duration`, {
      headers: {
        'Authorization': `Bearer ${API_KEY}`
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Plausible data:', error);
    return null;
  }
};

export default PlausableAnalytics;
