import React, { useContext } from 'react'
import './footer.css';
import light_logo from '../../assets/images/yoeavnu_logo.png';
import dark_logo from '../../assets/images/dark_logo.png'
import MessagePage from '../../pages/message/MessagePage';
import { Link } from 'react-router-dom';
import { mainTheme } from '../context/ContextProvider';

const Footer = () => {
  const { pageTheme, setPageTheme } = useContext(mainTheme);
  return (
    <>
      <div className="footer-gap-div"></div>
      <div id="footer">
        <div className="footer-top">
          <div className="footer-top-wrapper">
            <div><p className='footer-p1'>Ready To Unleash Your Infinite possibilities of Creativity ?</p></div>
            <div className='d-flex justify-content-between'><p className='footer-p2'>Lets's get Started</p><button className='contact-btn'>Contact Us</button></div>
          </div>
        </div>
        <footer class="footer-bottom">
          <div class="container">
            <div class="row d-flex justify-content-evenly align-items-baseline">
              <div class="footer-bottom-first col-md-12 col-lg-2">
                <div class="footer-logo">
                  <Link class="navbar-brand brand-logo pt-0" to="/">
                    {/* <img src="../../assets/images/main_logo.png" alt="logo" /> */}
                    {pageTheme == "blue_th" || pageTheme == "dark_th" ? (<img src={dark_logo} alt="Company Logo" />) : (<img src={light_logo} alt="Company Logo" />)}
                  </Link>
                </div>
              </div>
              <div class="footer-bottom-second col-md-12 col-lg-8 d-flex justify-content-center">
                <ul class="footer-dk-footers">
                  <li><a class="footer-dk-footer" href="/for-designers">For designers</a></li>
                  <li><a class="footer-dk-footer" href="/hiring">Hire talent</a></li>
                  <li><a class="footer-dk-footer" href="/shots/popular">Inspiration</a></li>
                  <li><a class="footer-dk-footer" href="/advertise">Advertising</a></li>
                  <li><a class="footer-dk-footer" href="/stories">Blog</a></li>
                  <li><a class="footer-dk-footer" href="/about">About</a></li>
                  <li><a class="footer-dk-footer" href="/careers">Careers</a></li>
                  <li><a class="footer-dk-footer" href="/contact">Support</a></li>
                </ul>
              </div>
              <div class="footer-bottom-third col-md-12 col-lg-2 d-flex justify-content-end">
                <ul class="dk-footer">
                  <li class="dk-footer">
                    <a href="#">
                      <i class="fa-brands fa-square-facebook"></i>
                    </a>
                  </li>
                  <li class="dk-footer">
                    <a href="#">
                      <i class="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  {/* </li class="dk-footer"> */}
                  <li>
                    <a href="#">
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                </ul>
                {/* <!-- End Social link --> */}
              </div>

            </div>
            {/* <!-- End Widget Row --> */}
          </div>
          {/* <!-- End Contact Container --> */}


          <div class="copyright">
            <div class="container">
              <div class="row">
                <div class="copyright-row-first col-md-5">
                  <ul className='p-0'>
                    <li class="dk-footer12">© 2023,Yoeavnu</li>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                    <li>
                      <a href="#">Cookies</a>
                    </li>
                  </ul>
                </div>
                {/* <!-- End Col --> */}
                <div class="copyright-row-second col-md-7">
                  <div class="copyright-menu">
                    <ul>
                      <li>
                        <a href="#">Jobs</a>
                      </li>
                      <li>
                        <a href="#">Designers</a>
                      </li>
                      <li>
                        <a href="#">Freelancers</a>
                      </li>
                      <li>
                        <a href="#">Tags</a>
                      </li>
                      <li>
                        <a href="#">Places</a>
                      </li>
                      <li>
                        <a href="#">Resources</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End col --> */}
              </div>
              {/* <!-- End Row --> */}
            </div>
            {/* <!-- End Copyright Container --> */}
          </div>
          {/* <!-- End Copyright --> */}
          {/* <!-- Back to top --> */}
          {/* <MessagePage /> */}
          {/* <!-- End Back to top --> */}
        </footer>
      </div>
    </>
  )
}

export default Footer