import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserRoutes from './user_site/UserRoutes';
import AdminRoutes from './admin_site/AdminRoutes';
import Login from './admin_site/pages/admin-login/Login';
import './App.css';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/admin/*" element={<AdminRoutes />} />
          <Route path="/*" element={<UserRoutes />} />
          <Route path="/admin-login" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;