import React, { useState, useEffect } from 'react';
import { addPostCat, addPostTag, catDelete, getAllCats, getAllTags, tagDelete, updateCatFeatured, updateTagFeatured } from '../../../services/Apis';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './post.css';
import { ToastContainer, toast } from 'react-toastify';

const PostSettings = () => {

  //<------------------- Add Categories ------------------->
  const [cat_title, setCatTitle] = useState("");

  const submitCategory = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json"
    }
    const res = await addPostCat({ cat_title: cat_title }, config);
    if (res.status === 200) {
      alert("Category Added Successfully");
      setCatTitle("");
      fetchCategories();
    }
    else {
      console.log(res.response.data.message);
      if (res.response.data.message) {
        alert(res.response.data.message)
      }
      else {
        alert("Some Unknown Error Occured")
      }
    }
  };

  //<------------------- Add Tags ------------------->
  const [tag, setTag] = useState("");

  const submitTags = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json"
    }
    const res = await addPostTag({ tag: tag }, config);
    if (res.status === 200) {
      alert("Tags Added Successfully");
      setTag("");
      fetchTags();
    }
    else {
      console.log(res.response.data.message);
      if (res.response.data.message) {
        alert(res.response.data.message)
      }
      else {
        alert("Some Unknown Error Occured")
      }
    }
  };

  //<------------------- View Categories ------------------->
  const [cats, setCats] = useState([]);

  const fetchCategories = async () => {
    try {
      const res = await getAllCats();
      if (res.status === 200) {
        setCats(res.data);
      } else {
        console.log('Error in Fetching Users.');
      }
    } catch (error) {
      console.error('Error fetching Users:', error);
    }
  };

  //<------------------- View Tags ------------------->
  const [tags, setTags] = useState([]);

  const fetchTags = async () => {
    try {
      const res = await getAllTags();
      if (res.status === 200) {
        setTags(res.data);
      } else {
        console.log('Error in Fetching Users.');
      }
    } catch (error) {
      console.error('Error fetching Users:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchTags();
  }, []);

  //<------------------- Delete Category ------------------->
  const handleDeleteCat = async (id) => {
    const response = await catDelete(id);
    if (response.status === 200) {
      alert("Category Deleted Successfully");
      fetchCategories();
    }
    else {
      alert("Cannot Delete User");
    }
  }

  //<------------------- Update Tag Featured------------------->
  const handleCategoryTag = async (id) => {
    const response = await updateCatFeatured(id);
    if (response.status === 200) {
      alert("Catgeory Featured Updated Successfully");
      fetchCategories();
    }
    else {
      alert("Cannot Feature Tag");
    }
  }

  //<------------------- Delete Tag ------------------->
  const handleDeleteTag = async (id) => {
    const response = await tagDelete(id);
    if (response.status === 200) {
      alert("Tag Deleted Successfully");
      fetchTags();
    }
    else {
      alert("Cannot Delete Tag");
    }
  }

  //<------------------- Update Tag Featured------------------->
  const handleFeatureTag = async (id) => {
    const response = await updateTagFeatured(id);
    if (response.status === 200) {
      toast.success("Tag Featured Updated");
      fetchTags();
    }
    else {
      alert("Cannot Feature Tag");
    }
  }

  return (
    <>
      <AccessChecker accessKey="change_post_settings" />
      <div className='form-card' id="post-settings">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Post Settings</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={submitCategory}>
          <div>
            <h5>Post Categories</h5>
            <hr />
          </div>
          <div class="form-group pt-3">
            <div className="row">
              <div className="col-lg-10">
                <label>Add Category</label>
                <input type="text" class="form-control mt-2" placeholder="Enter your Post Categories" name="cat_title" value={cat_title} onChange={(e) => setCatTitle(e.target.value)} required />
              </div>
              <div className="col-lg-2 d-grid align-items-end">
                <button type="submit" class="btn btn-primary">Add Categories</button>
              </div>
            </div>
          </div>
        </form>
        <div className="row data-row">
          <div className="col-lg-12">
            <label>Previous Categories</label>
            <div className='form-control data-div mt-2'>
              {cats.length === 0 ? (
                <div className='options'>-------- No Categories to Show --------</div>
              ) : (
                cats.map((cat, index) => (
                  <div className='options'>{cat.cat_title}
                  {cat.featured?(
                  <button className='btn btn-sm btn-success delete-btn' type='button' onClick={() => handleCategoryTag(cat._id)}><i class="fa-solid fa-star"></i></button>
                  ):(
                    <button className='btn btn-sm btn-primary delete-btn' type='button' onClick={() => handleCategoryTag(cat._id)}><i class="fa-solid fa-star"></i></button>
                  )}
                  <button className='btn btn-sm btn-danger delete-btn' type='button' onClick={() => handleDeleteCat(cat._id)}><i class="fa-solid fa-trash"></i></button>
                  </div>
                ))
              )}

            </div>
          </div>
        </div>

        <form className='form-div' onSubmit={submitTags}>
          <div>
            <h5>Post Tags</h5>
            <hr />
          </div>
          <div class="form-group pt-3">
            <div className="row">
              <div className="col-lg-10">
                <label>Add Tags</label>
                <input type="text" class="form-control mt-2" placeholder="Enter your Post Tags" name="tag" value={tag} onChange={(e) => setTag(e.target.value)} required />
              </div>
              <div className="col-lg-2 d-grid align-items-end">
                <button type="submit" class="btn btn-primary">Add Tags</button>
              </div>
            </div>
          </div>
        </form>
        <div className="row data-row">
          <div className="col-lg-12">
            <label>Previous Tags</label>
            <div className='form-control data-div mt-2'>
              {tags.length === 0 ? (
                <div className='options'>-------- No Tags to Show --------</div>
              ) : (
                tags.map((tag, index) => (
                  <div className='options'>
                  {tag.tag}
                  {tag.featured?(
                  <button className='btn btn-sm btn-success delete-btn' type='button' onClick={() => handleFeatureTag(tag._id)}><i class="fa-solid fa-star"></i></button>
                  ):(
                    <button className='btn btn-sm btn-primary delete-btn' type='button' onClick={() => handleFeatureTag(tag._id)}><i class="fa-solid fa-star"></i></button>
                  )}
                  <button className='btn btn-sm btn-danger delete-btn' type='button' onClick={() => handleDeleteTag(tag._id)}><i class="fa-solid fa-trash"></i></button>
                  </div>
                ))
              )}

            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  )
}

export default PostSettings;