import React, { useState, useEffect } from 'react';
import { getUserPostedJobs } from '../../../../services/Apis';
import JobsPosted from './JobsPosted';
import JobsApply from './JobsApply';

const ProfileJobs = ({username}) => {
  const [userJobs, setUserJobs] = useState([])
  const getJobsByUser = async () => {
    try {
      const res = await getUserPostedJobs(username);
      if (res.status === 200) {
        setUserJobs(res.data);
      } else {
        console.log('Error in Fetching Posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    getJobsByUser();
  }, []);

  const checkUser = sessionStorage.getItem('user_username');

  return (
    <>
    <div className="container" id="jobs-page">
      <div className="job-main row">
      <div class="col-lg-12">
        <div class="view-job-main row">
          {userJobs.length<=0?(<>
          <div className="col-lg-8 text-center text">
            No Jobs Posted
          </div>
          </>):(<>
          <div className="col-lg-8">
          <JobsPosted username={username} checkUser={checkUser}/>
          </div>
          <div class="col-lg-4">
          {username === checkUser?( <JobsApply />):("")}
          </div>
          </>)}
        </div>
      </div>
      </div>
    </div>
    {/* </>)} */}
    </>
  )
}

export default ProfileJobs