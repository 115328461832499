import React, { useState, useEffect } from 'react';
import LHomeContent from './large-view/LHomeContent';
import SHomeContent from './small-view/SHomeContent';

const Home = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {isSmallScreen ? (
      <SHomeContent />
      ) : (
      <LHomeContent />
      )}
    </>
  )
}

export default Home