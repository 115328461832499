import React, { useState, useEffect, useContext } from 'react';
import HeaderTop from '../../../components/header/large-screen/HeaderTop';
import Footer from '../../../components/footer/Footer';
import '../jobs.css';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../../services/helper';
import Sidebar from '../../../components/common/Sidebar';
import job1_light from '../../../assets/login-job-imgs/job-1-light.png';
import job1_dark from '../../../assets/login-job-imgs/job-1-dark.png';
import job2_light from '../../../assets/login-job-imgs/job-2-light.png';
import job2_dark from '../../../assets/login-job-imgs/job-2-dark.png';
import { commonSidebar, mainTheme } from '../../../components/context/ContextProvider';
import { getFilteredJobs } from '../../../../services/Apis';
import TimeAgoConverter from '../../../components/functions/TimeAgoConverter';
import { ToastContainer, toast } from 'react-toastify';
import { isTokenValid } from '../../../components/functions/OtherFunctions';

const LJobs = () => {
  const { pageTheme, setPageTheme } = useContext(mainTheme);

  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const [allJobs, setAllJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [employmentType, setEmploymentType] = useState([]);
  const [designerType, setDesignerType] = useState([]);
  const [location, setLocation] = useState("");
  const [workplaceType, setWorkplaceType] = useState("");

  const fetchJobs = async () => {
    try {
      const res = await getFilteredJobs(search, designerType, workplaceType, location, employmentType); // Pass employmentType to API call
      if (res.status === 200) {
        setAllJobs(res.data);
      } else {
        console.log('Error in Fetching Jobs.');
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [search]);

  const handleEmploymentChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setEmploymentType(prevState => [...prevState, value]);
    } else {
      setEmploymentType(prevState => prevState.filter(type => type !== value));
    }
  };
  
  const handleDesignerChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setDesignerType(prevState => [...prevState, value]);
    } else {
      setDesignerType(prevState => prevState.filter(type => type !== value));
    }
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchJobs();
  };


  const checkUser = () => {
    if (isValid) { navigate("/post-job"); }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First');
    }
  }
  const viewJobClick = (id) => {
    if (isValid) {
      navigate(`/view-job/${id}`);
    }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First');
    }
  }

  const { showMenu, setShowMenu } = useContext(commonSidebar);

  return (
    <>
      <HeaderTop />
      <Sidebar />
      <div className="container-fluid" id="jobs-page">
        <div className="job-main row" style={showMenu ? { "filter": "blur(2px)" } : {}}>
          <div class="col-lg-12" className='banner-wrapper'>
            <div className='job-bg-img1'>
            {((pageTheme === "dark_th") || (pageTheme === "blue_th")) ?(<img src={job1_dark} />):(<img src={job1_light} />)}
            </div>
            <div class="job-head text-center">
              <div className='d-flex justify-content-center'><h1 style={{ "maxWidth": "950px" }}>The #1 job board for <br /> all different type of jobs</h1></div>
              <div className='d-flex justify-content-center'><p style={{ "maxWidth": "950px" }}>Yoeavnu is the heart of the design community and the best resource <br /> to discover and connect with designers and jobs worldwide.</p></div>
            </div>
            <div class="job-post-btn text-center">
              <button type='button' onClick={checkUser}><i class="fa fa-plus"></i>Post a Job</button>
            </div>
            <div className='job-bg-img2'>
            {((pageTheme === "dark_th") || (pageTheme === "blue_th")) ?(<img src={job2_dark} />):(<img src={job2_light} />)}
            </div>
          </div>

          <div class="col-lg-12">
            <div class="view-job-main row" style={{ "margin": "40px 0 0 7%" }}>
              <div class="col-lg-8">
                <div class="search-job">
                  <form>
                    <div class="input-group mb-3">
                      <button class="search-icon" type="submit"><i class="fa fa-search"></i></button>
                      <input type="text" class="form-control search-bar" placeholder="Search by Company" onChange={(e) => setSearch(e.target.value)} />
                    </div>
                  </form>
                </div>
                <div class="view-jobs">
                  <h1 className='recent-jobs' style={{ "margin": "40px 0" }}>Recent Jobs</h1>
                  {allJobs.length > 0 ? allJobs.map((element, index) => {
                    return (<>
                      <div class="job-list d-flex justify-content-between mt-2 mb-4">
                        <div class="job-deatils-1 d-flex">
                          <div class="comp-img"><img src={`${BASE_URL}/uploads/jobs-img/${element.company_logo}`} /></div>
                          <div class="comp-name"><p>{element.company_name}</p><h2>{element.title}</h2></div>
                        </div>
                        <div class="job-deatils-2">
                          <div class="more-details">
                            <p style={{ "fontSize": "20px" }} class="text-center"><i class="fa-sharp fa-solid fa-location-dot" style={{ "paddingRight": "8px" }}></i>{element.workplace_type}</p>
                            <p style={{ "fontSize": "15px" }} className='date'><TimeAgoConverter dateTime={element.offer_date}/></p>
                          </div>
                          <div class="apply-btn">
                            <div className='view-job' onClick={() => viewJobClick(element._id)}>View Job</div>
                            {
                              isValid ? (
                                element.apply_link.includes('http://') || element.apply_link.includes('https://') ? (
                                  <a style={{ "marginLeft": "10px" }} href={`${element.apply_link}`} target="_blank">Apply Job</a>
                                ) : (
                                  <a style={{ "marginLeft": "10px" }} href={`http://${element.apply_link}`} target="_blank">Apply Job</a>
                                )
                              ) : (
                                <div className='view-job' style={{ "marginLeft": "10px" }} href="" onClick={checkUser}>Apply Job</div>
                              )
                            }

                          </div>
                        </div>
                      </div>
                    </>)
                  }) : ""}
                </div>
              </div>
              <div class="col-lg-4">
                <form  onSubmit={handleFilterSubmit}>
                <div class="job-filter">
                  <span class="head">Specialities</span>
                  <div style={{ "paddingTop": "20px" }}>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="animation" value="animation" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" htmlFor="animation">Animation</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="graphic" value="graphic_designer" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" htmlFor="graphic">Brand / Graphic Design</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="illustration" value="illustration" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" htmlFor="illustration">Illustration</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="leadership" value="leadership" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" htmlFor="leadership">Leadership</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="mobile" value="mobile_design" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" htmlFor="mobile">Mobile Design</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="visual" value="visual_design" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" htmlFor="visual">Visual Design</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="ux_design" value="ux_design" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" htmlFor="ux_design">UX Design</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="product" value="product_design" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" htmlFor="product">Product Design</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="web" value="web_design" name="designer_type" onChange={handleDesignerChange}/>
                      <label class="form-check-label" for="web">Web Design</label>
                    </div>
                  </div>
                  <hr class="mt-3 mb-3" />
                  <span class="head">Location</span>
                  <form>
                    <div class="input-group mt-3 mb-3">
                      <input type="text" class="form-control search-bar" name="location" placeholder="Enter Location..."  onChange={(e) => setLocation(e.target.value)} />
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="remote" name="workplace_type" value="remote" onChange={(e) => setWorkplaceType(e.target.value)} />
                      <label class="form-check-label" for="remote">Open to remote</label>
                    </div>
                  </form>
                  <hr class="mt-3 mb-3" />
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="fulltime" name="employment_type" value="fulltime" onChange={handleEmploymentChange}/>
                    <label class="form-check-label" for="fulltime">Full Time</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="freelance" name="employment_type" value="freelance" onChange={handleEmploymentChange}/>
                    <label class="form-check-label" for="freelance">Freelance / Contract</label>
                  </div>
                  <div class="filter-btn">
                    <button type="submit">Filter</button>
                  </div>
                </div>
                </form>
              </div>

            </div>
          </div>

        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000}/>
      <Footer />
    </>
  )
}

export default LJobs