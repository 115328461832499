import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { getSingleJob, jobUpdate } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './job.css';

const ViewSingleJob = () => {
  const navigate = useNavigate();

  const [inputdata, setInputData] = useState({
    username: "",
    title: "",
    location: "",
    workplace_type: "",
    designer_type: "",
    employment_type: "",
    apply_link: "",
    company_name: "",
    website: "",
    status: ""
  });
  const [description, setDescription] = useState("");
  const [showImg, setShowImg] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");

  const { id } = useParams();
  const singleJobData = async () => {
    const response = await getSingleJob(id);
    if (response.status === 200) {
      setInputData(response.data.singleJob);
      setDescription(response.data.singleJob.description);
      setCompanyLogo(response.data.singleJob.company_logo);
    }
    else {
      console.log("Error in Fetching Job Details.");
    }
  }

  useEffect(() => {
    singleJobData();
  }, [])

  const LogoChange = (e) => {
    setShowImg(URL.createObjectURL(e.target.files[0]));
    setCompanyLogo(e.target.files[0]);
  }

  const submitJob = async (e) => {
    e.preventDefault();
    const { title, location, workplace_type, designer_type, employment_type, apply_link, company_name, website, status } = inputdata;
    if (companyLogo === "") {
      alert("Company Logo is Required");
      return;
    }

    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("location", location);
    data.append("workplace_type", workplace_type);
    data.append("designer_type", designer_type);
    data.append("employment_type", employment_type);
    data.append("apply_link", apply_link);
    data.append("company_name", company_name);
    data.append("website", website);
    data.append("company_logo", companyLogo);
    data.append("status", status);

    const config = {
      "Content-Type": "multipart/form-data"
    }

    try {
      const response = await jobUpdate(id, data, config);
      if (response.status === 200) {
        alert("Job Updated Successful");
        navigate("/admin/view-jobs");
      } else {
        alert("Job Update Unsuccessful");
      }
    } catch (error) {
      console.error('Error updating job:', error);
      alert("Job Update Unsuccessful");
    }
  }

  return (
    <>
      <AccessChecker accessKey="view_jobs" />
      <div className='form-card' id="add-job">
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Job</h1>
            <div><Link to={`/admin/edit-job/${inputdata._id}`}><button className='btn btn-primary'>Edit Job</button></Link></div>
          </div>
        </div>
        <hr />
        <div className='form-div'>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Job Title</label>
                <div className='form-control'>{inputdata.title}</div>
              </div>
              <div className="col-lg-6 job-loc-div">
                <label>Job Location</label>
                <div className='form-control'>{inputdata.location}</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Job Description</label>
                <div className='form-control' dangerouslySetInnerHTML={{ __html: description }}></div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Workplace Type</label>
                <div className='form-control'>{inputdata.workplace_type}</div>
              </div>
              <div className="col-lg-6">
                <label>What type of designer are you looking for?</label>
                <div className='form-control'>{inputdata.designer_type}</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Employment Type</label>
                <div className='form-control'>{inputdata.employment_type}</div>
              </div>
              <div className="col-lg-6">
                <label>Where can people apply?</label>
                <div className='form-control'>{inputdata.apply_link}</div>
              </div>
            </div>
          </div>
          <div className="form-group img-upload-wrap mt-3">
            <div className="row w-100">
              <div className="col-lg-6 d-flex align-items-center justify-content-between px-5">
                <div>
                  <div className="upld-img-icon">
                    <a href={`${BASE_URL}/uploads/jobs-img/${companyLogo}`} target='_blank'><label style={{ cursor: 'pointer' }}><img src={`${BASE_URL}/uploads/jobs-img/${companyLogo}`} alt="Company Logo" /></label></a>
                  </div>
                  <div className='pt-2'>Your Company Logo <br /><span style={{ "fontSize": "14px" }}>(Recommended dimensions: 150x150 px)</span></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label>What's your company name?</label>
                  <div className='form-control'>{inputdata.company_name}</div>
                </div>
                <div className="mt-5">
                  <label>Your Company Website</label>
                  <div className='form-control'>{inputdata.website}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row pt-2'>
            <div className='col-lg-6'>
              <label>Select Job Status</label>
              <div className='form-control'>{inputdata.status}</div>
            </div>
            <div className='col-lg-6'>
              <label>Job Poted By</label>
              <div className='form-control'>{inputdata.username}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSingleJob;
