import React, { useContext, useEffect } from 'react';
import './assets/AdminStyle.css';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { isSideNavOpen, pageTheme } from './components/context/ContextProvider';
import Home from './pages/home/Home';
import LeftNav from './components/common/LeftNav';
import Header from './components/common/Header';
import AddAdmin from './pages/admins/AddAdmin';
import ViewAdmin from './pages/admins/ViewAdmin';
import EditAdmin from './pages/admins/EditAdmin';
import ViewSingleAdmin from './pages/admins/ViewSingleAdmin';
import MinorAdmins from './pages/admins/MinorAdmins';
import AddUser from './pages/users/AddUser';
import ViewUser from './pages/users/ViewUser';
import EditUser from './pages/users/EditUser';
import ViewSingleUser from './pages/users/ViewSingleUser';
import AddPost from './pages/posts/AddPost';
import ViewPosts from './pages/posts/ViewPosts';
import EditPost from './pages/posts/EditPost';
import ViewSinglePost from './pages/posts/ViewSinglePost';
import PostSettings from './pages/posts/PostSettings';
import AddJob from './pages/jobs/AddJob';
import ViewJobs from './pages/jobs/ViewJobs';
import EditJob from './pages/jobs/EditJob';
import ViewSingleJob from './pages/jobs/ViewSingleJob';
import SendNotif from './pages/notifications/SendNotif';
import ViewNotif from './pages/notifications/ViewNotif';
import SendMessage from './pages/messages/SendMessage';
import ViewMessages from './pages/messages/ViewMessages';
import SendMail from './pages/mails/SendMail';
import ViewMails from './pages/mails/ViewMails';
import ViewChats from './pages/messages/ViewChats';
import AdminAccess from './pages/admins/AdminAccess';
import './AdminResponsive.css';

import { isTokenValid } from './components/OtherFunctions/otherFunctions';

const AdminRoutes = () => {
  const navigate = useNavigate();
  const { theme } = useContext(pageTheme);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const isValid = await isTokenValid();
      console.log("IS VALIDDDDDDDDDDDDDD"+isValid);
      if(!isValid){
        alert("Please Login");
        navigate("/admin-login");
      }
    };

    checkTokenValidity();
  }, [navigate]);

  const {sideNav, setSideNav} = useContext(isSideNavOpen);
  return (
    <div className="App" id="admin-page">
      <div className={theme === "dark-th" ? "wrapper dark-th-bg" : "wrapper light-th-bg"}>
        <LeftNav />
        <div id="content" style={theme === "dark-th" ? { backgroundColor: "#03273c" } : {} } className={sideNav?('active'):('content-off')}>
          <Header />
          <div className="center-content">
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/add-user" element={<AddUser />} />
                <Route path="/view-user" element={<ViewUser />} />
                <Route path="/single-user/:username" element={<ViewSingleUser />} />
                <Route path="/edit-user/:username" element={<EditUser />} />

                <Route path="/add-admin" element={<AddAdmin />} />
                <Route path="/view-admins" element={<ViewAdmin />} />
                <Route path="/edit-admin/:id" element={<EditAdmin />} />
                <Route path="/single-admin/:username" element={<ViewSingleAdmin />} />
                <Route path="/minor-admins" element={<MinorAdmins/>} />
                <Route path="/admin-access/:user_id" element={<AdminAccess/>} />
                
                <Route path="/add-post" element={<AddPost />} />
                <Route path="/view-posts" element={<ViewPosts />} />
                <Route path="/edit-post/:id" element={<EditPost />} />
                <Route path="/single-post/:id" element={<ViewSinglePost />} />
                <Route path="/post-settings" element={<PostSettings />} />

                <Route path="/add-job" element={<AddJob />} />
                <Route path="/view-jobs" element={<ViewJobs />} />
                <Route path="/edit-job/:id" element={<EditJob />} />
                <Route path="/single-job/:id" element={<ViewSingleJob />} />

                <Route path="/send-notification" element={<SendNotif />} />
                <Route path="/view-notifications" element={<ViewNotif />} />

                <Route path="/send-message" element={<SendMessage />} />
                <Route path="/view-chats" element={<ViewChats />} />
                <Route path="/view-messages/:id" element={<ViewMessages />} />

                <Route path="/send-mail" element={<SendMail />} />
                <Route path="/view-mails" element={<ViewMails />} />
            </Routes>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminRoutes;
