import React, { useState, useEffect, useCallback } from 'react';
import './post.css';
import { useNavigate } from 'react-router-dom';
import { addPost, getAllCats, getAllTags } from '../../../services/Apis';
import { ToastContainer, toast } from 'react-toastify';
import { isTokenValid } from '../../components/functions/OtherFunctions';
import { useDropzone } from 'react-dropzone'; // <-- Import useDropzone

const Post = () => {
    const navigate = useNavigate();
    const username = sessionStorage.getItem("user_username");
    const user_id = sessionStorage.getItem("user_id");

    useEffect(() => {
        const checkTokenValidity = async () => {
            try {
                const valid = await isTokenValid();
                if (!valid) {
                    navigate("/");
                }
            } catch (error) {
                console.error("Error checking token validity:", error);
            }
        };
        checkTokenValidity();
    }, []);

    //<------------------- View Categories ------------------->
    const [allCats, setAllCats] = useState([]);

    const fetchCategories = async () => {
        try {
            const res = await getAllCats();
            if (res.status === 200) {
                setAllCats(res.data);
            } else {
                console.log('Error in Fetching Users.');
            }
        } catch (error) {
            console.error('Error fetching Users:', error);
        }
    };

    //<------------------- View Tags ------------------->
    const [postTags, setPostTags] = useState([]);

    const fetchTags = async () => {
        try {
            const res = await getAllTags();
            if (res.status === 200) {
                setPostTags(res.data);
            } else {
                console.log('Error in Fetching Users.');
            }
        } catch (error) {
            console.error('Error fetching Users:', error);
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchTags();
    }, []);

    const [showImg, setShowImg] = useState("");
    const [postImg, setPostImg] = useState(null); // <-- Updated to null
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");
    const [inputTags, setInputTags] = useState([]);

    const selectImg = (file) => {
        if (file) {
            const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
            if (allowedFileTypes.includes(file.type)) {
                setShowImg(URL.createObjectURL(file));
                setPostImg(file);
            } else {
                toast.error("Only png, jpg, jpeg Images are Allowed");
            }
        }
    }

    // <-- Drag-and-drop functionality -->
    const onDrop = useCallback((acceptedFiles) => {
        selectImg(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png, image/jpg",
        maxFiles: 1
    });

    const handleTagClick = (tag) => {
        if (tag.trim() === '') return; // Ignore empty tags
        let updatedTags;
        if (inputTags.includes(tag)) {
            updatedTags = inputTags.filter(item => item.trim() !== tag.trim());
        } else {
            updatedTags = [...inputTags.filter(item => item.trim() !== ''), tag.trim()];
        }

        setInputTags(updatedTags);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("post_img", postImg);
        formData.append("title", title);
        formData.append("category", category);
        formData.append("description", description);
        formData.append("tags", inputTags.join(", "));
        formData.append("username", username);
        formData.append("user_id", user_id);
        formData.append("likes", 0);
        formData.append("comments", 0);
        formData.append("status", "active");

        if (!postImg) { toast.error("Please Select Image"); }
        else if (!username) { toast.error("Please Login First"); }
        else if (!title) { toast.error("Please Add Image Title"); }
        else if (!category) { toast.error("Please Select Category"); }
        else if (!description) { toast.error("Please Add Short Description"); }
        else {
            const config = {
                "Content-Type": "multipart/form-data"
            }

            const response = await addPost(formData, config);
            if (response.status === 200) {
                navigate("/");
            }
            else {
                toast.error("Sorry Registration Unsuccessfull");
            }
        }
    }


    const resetForm = () => {
        setShowImg("");
        setTitle("");
        setCategory("");
        setDescription("");
        setInputTags([]);
        setPostImg(null); // <-- Updated to null
    }

    return (
        <>
            <div className="container-fluid" id="post-page">
                <div className="header-wrap">
                    <div className='left-btn'>
                        <button onClick={() => navigate("/")}>Cancel</button>
                    </div>
                    {/* <div className='right-btn'>
                        <button style={{ "backgroundColor": "#000", "color": "#fff", "marginLeft": "15px" }}>Continue</button>
                    </div> */}
                </div>
                <div className="container d-flex justify-content-center align-items-center">
                    <div className="row d-flex justify-content-center w-100 h-100">
                        <div className="col-lg-10 col-sm-12">
                            <div className='post-head'>Submit to Yoeavnu</div>
                            <div className='post-form'>
                                {showImg === "" ? (
                                    <div className="pre-post" style={{ "height": "100%" }} {...getRootProps()}>
                                        <input {...getInputProps()} style={{ display: "none" }} />
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <div>
                                                <div className='text-center'>
                                                    <img src="assets/images/upload_icon.png" alt="upload icon" />
                                                </div>
                                                <div className='text-center mt-2 mb-2'>
                                                    <p>{isDragActive ? "Drop the image here..." : "Drag and drop an Image or Browse"}</p>
                                                </div>
                                                <div className='text-center mb-4' style={{ "fontSize": "0.875rem", "color": "#92b0b3" }}>
                                                    <p>Minimum 1600px width recommended. Max 20MB for Videos.</p>
                                                </div>
                                                <div className='d-flex'>
                                                    <ul>
                                                        <li>High resolution images (png, jpg, jpeg)</li>
                                                        <li>Animated gifs not Supported</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Videos (mp4) are Currently not Supported</li>
                                                        <li>Only upload media you own the rights to</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                ) : (
                                    <div className='after-post'>
                                        <div className="row d-flex justify-content-between" style={{ "margin": "0 15px 15px 15px" }}>
                                            <div className="col-12 d-flex justify-content-between" style={{ "margin": "15px 0" }}>
                                                <div className="preview">Preview</div>
                                                <div className="reset-btn"><button onClick={resetForm}><i className="fa-solid fa-xmark"></i>&nbsp; Reset Form</button></div>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <div className='preview-img'><img src={showImg} alt="preview" /></div>
                                            </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <form onSubmit={handleSubmit}>
                                                <div class="mb-3 mt-3">
                                                    <label htmlFor="title" class="form-label">Title :</label>
                                                    <input type="text" class="form-control" id="title" placeholder="Enter Title for your Image" name="title" onChange={(e) => setTitle(e.target.value)} />
                                                </div>
                                                <div class="mb-3">
                                                    <label htmlFor="category" class="form-label">Category:</label>
                                                    <select class="form-select" name="category" id="category" onChange={(e) => setCategory(e.target.value)}>
                                                        <option className='text-center'>-------- Select Suitable Category --------</option>
                                                        {allCats.length === 0 ? (
                                                            <option className='text-center'>-------- No Categories to Show --------</option>
                                                        ) : (
                                                            allCats.map((cat, index) => (
                                                                <option>{cat.cat_title}</option>
                                                            ))
                                                        )}
                                                    </select>
                                                </div>
                                                <div class="mb-3 mt-3">
                                                    <label htmlFor="tags" class="form-label">Tags :</label>
                                                    <textarea class="form-control" id="tags" placeholder="Enter Tags Separated by Comma ',' " name="tags" value={inputTags.join(', ')} onChange={(e) => setInputTags(e.target.value.split(',').map(tag => tag.trim()))} required autoComplete='off' rows="2"></textarea>
                                                </div>
                                                <div className="row data-row">
                                                    <div className="col-lg-12">
                                                        <label>Previous Tags</label>
                                                        <div className='form-control data-div mt-2'>
                                                            {postTags.length === 0 ? (
                                                                <div className='options bg-secondary'>-------- No Tags to Show --------</div>
                                                            ) : (
                                                                postTags.map((tag, index) => (
                                                                    <div className='options' key={index}>
                                                                        {tag.tag}
                                                                        <button className={`btn btn-sm delete-btn ${inputTags.includes(tag.tag) ? "bg-danger" : "bg-success"}`} type='button' onClick={() => handleTagClick(tag.tag)}>
                                                                            {inputTags.includes(tag.tag) ? (<i className='fa-solid fa-trash'></i>) : (<i className='fa-solid fa-plus'></i>)}
                                                                        </button>
                                                                    </div>
                                                                ))
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-3 mt-3">
                                                    <label htmlFor="desc" class="form-label">Description :</label>
                                                    <textarea class="form-control" id="desc" placeholder="Enter Image Description" name="desc" onChange={(e) => setDescription(e.target.value)} rows="3" />
                                                </div>
                                                <div class="mb-3 mt-3">
                                                    <button type="submit" class="btn btn-primary">Submit</button>
                                                </div>
                                            </form>
                                        </div>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right" />
            </div>
        </>
    )
}

export default Post;