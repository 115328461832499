import React from 'react'

  const WordLimit = ({ text, limit }) => {
    if (text.length <= limit) {
      return <span>{text}</span>;
    } else {
      const truncatedText = text.slice(0, limit);
      return (
        <span>
          {truncatedText}...
        </span>
      );
    }
  };

export default WordLimit