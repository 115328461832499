import React, { useEffect, useState } from 'react'
import './Admin.css';
import { getSingleAdmin, getSingleAdminAccess, getSingleUser, updateAdminAccess } from '../../../services/Apis';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';

const AdminAccess = () => {
  const [adminData, setAdminData] = useState({});
  const { user_id } = useParams();

  const singleAdminData = async () => {
    const response = await getSingleAdmin(user_id);
    if (response.status === 200) {
      setAdminData(response.data);
    }
    else {
      console.log("Error in Fetching Admin Details.");
    }
  }

  const [adminAccess, setAdminAccess] = useState({
    add_admin: "", view_admins: "", edit_admin: "", delete_admin: "", change_admin_access: "", add_user: "", view_users: "",
    edit_user: "", delete_user: "", add_post: "", view_posts: "", edit_post: "", delete_post: "", change_post_settings: "", add_job: "", view_jobs: "", edit_job: "", delete_job: "",
    send_notification: "", view_send_notifications: "", send_message: "", view_user_chats: "", send_mail: "", view_send_mails: ""
  });

  const singleAdminAccess = async () => {
    const response = await getSingleAdminAccess(user_id);
    if (response.status === 200) {
      setAdminAccess(response.data);
    }
    else {
      console.log("Error in Fetching Admin Details.");
    }
  }

  useEffect(() => {
    singleAdminData();
    singleAdminAccess();
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminAccess((prevData) => ({
      ...prevData,
      [name]: !prevData[name],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = { "Content-Type": "application/json" }
    const res = await updateAdminAccess(user_id, adminAccess, config);
    if (res.status === 200) {
      alert("Access Updated Successfully");
      singleAdminAccess();
      // navigate("/admin/view-user");
    }
    else {
      console.log(res);
      if (res.response.data.message) {
        alert(res.response.data.message)
      }
      else {
        alert("Some Unknown Error Occured")
      }
    }
  };

  return (
    <>
      <AccessChecker accessKey="change_admin_access" />
      <div className="form-card" id="admin-access">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-4 form-head d-flex align-items-end">
            <h1>Minor Admin Access</h1>
          </div>
          <div className="col-lg-6 d-flex align-items-baseline justify-content-end">
            <div className='admin-details'>
              <div className='info'>Name : {adminData.fname + " " + adminData.lname}</div>
              <div className='info'>Username : {adminData.username}</div>
            </div>
            <div className='admin-img'>
              <img src={`${BASE_URL}/uploads/admins-users-img/${adminData.user_img}`} />
            </div>
          </div>
        </div>
        <hr />
        <form className="form-div" onSubmit={handleSubmit}>
          <div className="row">
            {/*<---------------------------------- Admin Settings ---------------------------------->*/}
            <div className="col-lg-4">
              <div className='access-box'>
                <div className='access-head'>
                  <h4>Admin Access</h4>
                </div>
                <hr />
                <div className='check-list'>
                  <div class="form-check">
                    <input type="checkbox" className="form-check-input" id="add_admin" name="add_admin" value="true" checked={adminAccess.add_admin} onChange={handleChange} />
                    <label class="form-check-label" for="add_admin">Add Admin</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="view_admins" name="view_admins" value="true" checked={adminAccess.view_admins} onChange={handleChange} />
                    <label class="form-check-label" for="view_admins">View Admins</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="edit_admin" name="edit_admin" value="true" checked={adminAccess.edit_admin} onChange={handleChange} />
                    <label class="form-check-label" for="edit_admin">Edit Admin</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="delete_admin" name="delete_admin" value="true" checked={adminAccess.delete_admin} onChange={handleChange} />
                    <label class="form-check-label" for="delete_admin">Delete Admin</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="change_admin_access" name="change_admin_access" value="true" checked={adminAccess.change_admin_access} onChange={handleChange} />
                    <label class="form-check-label" for="change_admin_access">Change Admin Access</label>
                  </div>
                </div>
              </div>
            </div>
            {/*<---------------------------------- User Settings ---------------------------------->*/}
            <div className="col-lg-4">
              <div className='access-box'>
                <div className='access-head'>
                  <h4>User Access</h4>
                </div>
                <hr />
                <div className='check-list'>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="add_user" name="add_user" value="true" checked={adminAccess.add_user} onChange={handleChange} />
                    <label class="form-check-label" for="add_user">Add User</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="view_users" name="view_users" value="true" checked={adminAccess.view_users} onChange={handleChange} />
                    <label class="form-check-label" for="view_users">View Users</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="edit_user" name="edit_user" value="true" checked={adminAccess.edit_user} onChange={handleChange} />
                    <label class="form-check-label" for="edit_user">Edit User</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="delete_user" name="delete_user" value="true" checked={adminAccess.delete_user} onChange={handleChange} />
                    <label class="form-check-label" for="delete_user">Delete User</label>
                  </div>
                </div>
              </div>
            </div>
            {/*<---------------------------------- Post Settings ---------------------------------->*/}
            <div className="col-lg-4">
              <div className='access-box'>
                <div className='access-head'>
                  <h4>Post Access</h4>
                </div>
                <hr />
                <div className='check-list'>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="add_post" name="add_post" value="true" checked={adminAccess.add_post} onChange={handleChange} />
                    <label class="form-check-label" for="add_post">Add Post</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="view_posts" name="view_posts" value="true" checked={adminAccess.view_posts} onChange={handleChange} />
                    <label class="form-check-label" for="view_posts">View Posts</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="edit_post" name="edit_post" value="true" checked={adminAccess.edit_post} onChange={handleChange} />
                    <label class="form-check-label" for="edit_post">Edit Post</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="delete_post" name="delete_post" value="true" checked={adminAccess.delete_post} onChange={handleChange} />
                    <label class="form-check-label" for="delete_post">Delete Post</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="change_post_settings" name="change_post_settings" value="true" checked={adminAccess.change_post_settings} onChange={handleChange} />
                    <label class="form-check-label" for="change_post_settings">Change Post Settings</label>
                  </div>
                </div>
              </div>
            </div>
            {/*<---------------------------------- Jobs Settings ---------------------------------->*/}
            <div className="col-lg-4">
              <div className='access-box'>
                <div className='access-head'>
                  <h4>Jobs Access</h4>
                </div>
                <hr />
                <div className='check-list'>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="add_job" name="add_job" value="true" checked={adminAccess.add_job} onChange={handleChange} />
                    <label class="form-check-label" for="add_job">Add Job</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="view_jobs" name="view_jobs" value="true" checked={adminAccess.view_jobs} onChange={handleChange} />
                    <label class="form-check-label" for="view_jobs">View Jobs</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="edit_job" name="edit_job" value="true" checked={adminAccess.edit_job} onChange={handleChange} />
                    <label class="form-check-label" for="edit_job">Edit Job</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="delete_job" name="delete_job" value="true" checked={adminAccess.delete_job} onChange={handleChange} />
                    <label class="form-check-label" for="delete_job">Delete Job</label>
                  </div>
                </div>
              </div>
            </div>
            {/*<---------------------------------- Message Settings ---------------------------------->*/}
            <div className="col-lg-4">
              <div className='access-box'>
                <div className='access-head'>
                  <h4>Messages Access</h4>
                </div>
                <hr />
                <div className='check-list'>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="send_message" name="send_message" value="true" checked={adminAccess.send_message} onChange={handleChange} />
                    <label class="form-check-label" for="send_message">Send Message</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="view_user_chats" name="view_user_chats" value="true" checked={adminAccess.view_user_chats} onChange={handleChange} />
                    <label class="form-check-label" for="view_user_chats">View User Chats</label>
                  </div>
                </div>
              </div>
            </div>
            {/*<---------------------------------- Notification Settings ---------------------------------->*/}
            <div className="col-lg-4">
              <div className='access-box'>
                <div className='access-head'>
                  <h4>Notifications Access</h4>
                </div>
                <hr />
                <div className='check-list'>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="send_notification" name="send_notification" value="true" checked={adminAccess.send_notification} onChange={handleChange} />
                    <label class="form-check-label" for="send_notification">Send Notification</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="view_send_notifications" name="view_send_notifications" value="true" checked={adminAccess.view_send_notifications} onChange={handleChange} />
                    <label class="form-check-label" for="view_send_notifications">View Send Notifications</label>
                  </div>
                </div>
              </div>
            </div>
            {/*<---------------------------------- Email Settings ---------------------------------->*/}
            <div className="col-lg-4">
              <div className="access-box">
                <div className='access-head'>
                  <h4>Emails Access</h4>
                </div>
                <hr />
                <div className='check-list'>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="send_mail" name="send_mail" value="true" checked={adminAccess.send_mail} onChange={handleChange} />
                    <label class="form-check-label" for="send_mail">Send Mail</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="view_send_mails" name="view_send_mails" value="true" checked={adminAccess.view_send_mails} onChange={handleChange} />
                    <label class="form-check-label" for="view_send_mails">View Send Mails</label>
                  </div>
                </div>
              </div>
            </div>
            {/*<---------------------------------- Email Settings End ---------------------------------->*/}
          </div>
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-end">
              <button className='btn btn-primary' type='submit'>Update Access</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AdminAccess