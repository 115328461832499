import React, { createContext, useState } from 'react';

export const pageTheme = createContext();
export const isSideNavOpen = createContext();

const ContextProvider = ({ children }) => {
  const [theme, setTheme] = useState("light-th");
  const [sideNav, setSideNav] = useState(false);

  return (
    <>
      <pageTheme.Provider value={{ theme, setTheme }}>
        <isSideNavOpen.Provider value={{ sideNav, setSideNav }}>
          {children}
        </isSideNavOpen.Provider>
      </pageTheme.Provider>
    </>
  )
}

export default ContextProvider