import React, { useState, useEffect } from 'react';
import { BsUpload } from "react-icons/bs";
import img_icon from '../../assets/images/img-icon.png';
import { useNavigate, useParams } from 'react-router-dom';
import { adminUpdate, getSingleAdmin } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import { decrypt, isValidUsername } from '../../components/OtherFunctions/otherFunctions';

const EditAdmin = () => {

  let history = useNavigate();
  const [changePass, setChangePass] = useState(false);

  //<------------------- Previous Admin Data ------------------->  
  const [adminData, setAdminData] = useState({
    fname: "",
    lname: "",
    username: "",
    email: "",
    password: "",
    user_type: "",
    status: "",
  })
  const [prevUsername, setPrevUsername] = useState("");
  const [prevEmail, setPrevEmail] = useState("");
  const [prevPassword, setPrevPassword] = useState("");
  const [admin_img, setAdminImg] = useState("");
  const [showImage, setShowImage] = useState("");

  const { id } = useParams();

  const singleAdminData = async () => {
    const response = await getSingleAdmin(id);
    if (response.status === 200) {
      setAdminData(response.data);
      setAdminImg(response.data.user_img);
      setPrevUsername(response.data.username);
      setPrevEmail(response.data.email);
      setPrevPassword(response.data.password);
    }
    else {
      console.log("Error in Fetching Admin Details.");
    }
  }

  useEffect(() => {
    singleAdminData();
  }, [])

  const setInputValue = (e) => {
    const { name, value } = e.target;
    setAdminData({ ...adminData, [name]: value });
  }

  //<------------------- Select Image and Show Selected Image ------------------->
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImage(URL.createObjectURL(selectedFile));
        setAdminImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  //<------------------- Submit Form ------------------->
  const handleSubmit = async (e) => {
    e.preventDefault()
    const { fname, lname, username, email, user_type, status, password } = adminData;
    if (!admin_img) {
      alert("Please Add User Image");
    }
    else if(!isValidUsername(username)){
      alert("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed")
    }
    else {
      const formData = new FormData();
      formData.append("user_img", admin_img);
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("username", username);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("user_type", user_type);
      formData.append("status", status);
      formData.append("prev_username", prevUsername);
      formData.append("prev_email", prevEmail);
      formData.append("prev_password", prevPassword);
      const config = { "Content-Type": "multipart/form-data" }
      const response = await adminUpdate(id, formData, config);
      if (response.status === 200) {
        alert("Admin Updated Successfully");
        history("/admin/view-admins");
      }
      else {
        alert("Sorry Updation Unsuccessfull");
      }
    }

  }

  return (
    <>
      <AccessChecker accessKey="edit_user" />
      <div className='form-card'>
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Edit Admin</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={handleSubmit}>
          <div class="form-group img-upload-wrap">
            <div className='upld-img-icon'>
              <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={!showImage ? `${BASE_URL}/uploads/admins-users-img/${admin_img}` : showImage} /></label>
              <input type="file" id="imageInput" accept="image/*" name="admin_img" style={{ display: 'none' }} onChange={handleChange} />
            </div>
            <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><span className='upload-icon-btn'><BsUpload /></span></label>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>First Name</label>
                <input type="text" class="form-control" value={adminData.fname} name="fname" onChange={setInputValue} required />
              </div>
              <div className="col-lg-6">
                <label>Last Name</label>
                <input type="text" class="form-control" value={adminData.lname} name="lname" onChange={setInputValue} required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Username</label>
                <input type="text" class="form-control" value={adminData.username} name="username" onChange={setInputValue} required />
              </div>
              <div className="col-lg-6">
                <label>Email</label>
                <input type="email" class="form-control" value={adminData.email} name="email" onChange={setInputValue} required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Role</label>
                <select name="user_type" value={adminData.user_type} onChange={setInputValue} className="form-control" required>
                  <option value="0">Select Admin Role</option>
                  <option value="minor_admin">Minor Admin</option>
                  <option value="super_admin">Super Admin</option>
                </select>
              </div>
              {changePass?(
                <div className="col-lg-6">
                <label>Password</label>
                <input type="text" class="form-control" name="password" onChange={setInputValue} required />
              </div>
              ):(
                <div className="col-lg-6">
                <label>Old Password</label>
                <div class="form-control  old-pass" >{decrypt(adminData.password)}<button className='btn btn-sm btn-success' type="button" onClick={()=>setChangePass(true)}><i class="fa-solid fa-pen-to-square"></i></button></div>
              </div>
              )}
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Status</label>
                <select name="status" value={adminData.status} onChange={setInputValue} className="form-control" required>
                  <option value="0">Select Admin Role</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                </select>
              </div>
              <div className="col-lg-6 d-flex justify-content-end align-items-end">
                <button type="submit" class="btn btn-primary">Update Admin</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditAdmin;