import React, {useState, useEffect} from 'react';
import HeaderTop from '../../../components/header/large-screen/HeaderTop';
import Footer from '../../../components/footer/Footer';
import './editProfile.css';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import GeneralSet from './settings/GeneralSet';
import EditP from './settings/EditP';
import Pwd from './settings/Pwd';
import Social from './settings/Social';
import { getSingleUser } from '../../../../services/Apis';
import { BASE_URL } from '../../../../services/helper';
import ExportUserData from './settings/ExportUserData';
import SmallFooter from '../../../components/footer/SmallFooter';
import { isTokenValid } from '../../../components/functions/OtherFunctions';

const EditProfile = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if(!valid){
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);
  
    const [sett, setSett] = useState("general");
    const switchSettings = (sett) =>{
        setSett(sett);
    }

    const username = sessionStorage.getItem("user_username");
    const [userData, setUserData] = useState({});
  
    const userProfileGet = async(username)=>{
      const res = await getSingleUser(username);
      if(res.status==200){
        setUserData(res.data);
      }
      else{
        console.log("Error in Fetching User Details.");
      }
    }
  
    useEffect(()=>{
        userProfileGet(username);
    },[])

  return (
    <>
    {isSmallScreen ? (""):(<HeaderTop />)}
    <div className="container" id="edit-profile-page" style={{"marginTop":"120px"}}>
        <div className="row">
            <div className="edit-head d-flex justify-content-center">
                <div className="col-lg-10 col-sm-12 edit-head-wrap">
                <div className="row d-flex justify-content-center">
                <div className="col-2 profile-img d-flex justify-content-end">
                    <NavLink to={`/${userData.username}`}><img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} width="48px" height="48px" className='rounded-circle'/></NavLink>
                </div>
                <div className="col-10">
                    <div className='user-title'>{userData.fname+" "+userData.lname} / General</div>
                    <div className='settings-title'>Update your username and manage your account</div>
                </div>
                </div>
                </div>
            </div>
        </div>
        <div className="row" style={{"marginTop": "35px"}}>
            <div className="edit-bottom d-flex justify-content-center">
                <div className="col-10  edit-profile-nav">
                    <div className="row d-flex justify-content-center">
                        <div className="col-3" id="edit-sidebar">
                            <ul>
                                <li className={sett === "general"?'active':''} onClick={()=>switchSettings("general")}><NavLink>General</NavLink></li>
                                <li className={sett === "editP"?'active':''} onClick={()=>switchSettings("editP")}><NavLink>Edit Profile</NavLink></li>
                                <li className={sett === "pwd"?'active':''} onClick={()=>switchSettings("pwd")}><NavLink>Password</NavLink></li>
                                <li className={sett === "social"?'active':''} onClick={()=>switchSettings("social")}><NavLink>Social Profiles</NavLink></li>
                                {/* <li className={sett === "email"?'active':''} onClick={()=>switchSettings("email")}><NavLink>Email Notifications</NavLink></li> */}
                                {/* <li className={sett === "sessions"?'active':''} onClick={()=>switchSettings("sessions")}><NavLink>Sessions</NavLink></li> */}
                                {/* <li className={sett === "apps"?'active':''} onClick={()=>switchSettings("apps")}><NavLink>Application</NavLink></li> */}
                                <li className={sett === "dataExp"?'active':''} onClick={()=>switchSettings("dataExp")}><NavLink>Data Export</NavLink></li>
                                <hr />
                                <li><NavLink className="text-danger" to="/confirm-delete-user">Delete Account</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-8">
                            {sett === "general"? (<GeneralSet />):''}
                            {sett === "editP"? (<EditP />):''}
                            {sett === "pwd"?(<Pwd userData={userData} />):''}
                            {sett === "social"?(<Social />):''}
                            {sett === "dataExp"?(<ExportUserData />):''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {isSmallScreen?(<SmallFooter />):(<Footer />)}
    </>
  )
}

export default EditProfile