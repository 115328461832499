import React, { useState, useEffect } from 'react';
import { BsUpload } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCats, getAllTags, getSinglePost, postUpdate } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './post.css';

const EditPost = () => {
  const navigate = useNavigate();

  const [postData, setPostData] = useState({
    _id: "",
    title: "",
    category: "",
    tags: "",
    description: "",
    likes: "",
    comments: "",
    shared: "",
    views: "",
    status: "",
  });

  const [showImg, setShowImg] = useState("");
  const [postImg, setPostImg] = useState("");
  const [postCat, setPostCat] = useState([]);
  const [postTags, setPostTags] = useState([]);

  const { id } = useParams();

  const fetchSinglePost = async () => {
    const response = await getSinglePost(id);
    if (response.status === 200) {
      setPostData(response.data.singlePost);
      setPostImg(response.data.singlePost.post_img);
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  const fetchAllCategory = async () => {
    const response = await getAllCats();
    if (response.status === 200) {
      setPostCat(response.data);
      console.log(postCat);
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  const fetchAllTags = async () => {
    const response = await getAllTags();
    if (response.status === 200) {
      setPostTags(response.data);
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  useEffect(() => {
    fetchSinglePost();
    fetchAllCategory();
    fetchAllTags();
  }, []);

  const selectImg = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImg(URL.createObjectURL(selectedFile));
        setPostImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  const setInputValue = (e) => {
    const { name, value } = e.target;
    setPostData({ ...postData, [name]: value });
  }

  const handleTagAction = (tag) => {
    if (postData.tags.includes(tag)) {
      const updatedTags = postData.tags.split(", ").filter(item => item !== tag).join(", ");
      setPostData(prevState => ({
        ...prevState,
        tags: updatedTags
      }));
    } else {
      setPostData(prevState => ({
        ...prevState,
        tags: prevState.tags ? prevState.tags + ", " + tag : tag
      }));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { _id, title, category, tags, description, likes, comments, views, shared, status } = postData;
    const formData = new FormData();
    formData.append("post_img", postImg);
    formData.append("title", title);
    formData.append("category", category);
    formData.append("description", description);
    formData.append("tags", tags);
    formData.append("likes", likes);
    formData.append("comments", comments);
    formData.append("views", views);
    formData.append("shared", shared);
    formData.append("status", status);

    if (!postImg) { alert("Please Select Image"); }
    else {
      const config = {
        "Content-Type": "multipart/form-data"
      }

      const response = await postUpdate(_id, formData, config);
      if (response.status === 200) {
        alert("Post Updated Successfully");
        fetchSinglePost();
        // navigate("/admin/view-posts");
      }
      else {
        alert("Sorry Registration Unsuccessful");
      }
    }
  }

  return (
    <>
      <AccessChecker accessKey="edit_post" />
      <div className='form-card' id="edit-post">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Edit Post</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-group img-upload-wrap">
            <div className='upld-img-icon'>
              <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={!showImg ? `${BASE_URL}/uploads/posts-img/${postImg}` : showImg} /></label>
              <input type="file" id="imageInput" accept="image/*" name="post_img" style={{ display: 'none' }} onChange={selectImg} />
            </div>
            <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><span className='upload-icon-btn'><BsUpload /></span></label>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Title</label>
                <input type="text" className="form-control" placeholder="Enter your Post Title" name="title" onChange={setInputValue} value={postData.title} required />
              </div>
              <div className="col-lg-6">
                <label>Select Category</label>
                <select name="category" onChange={setInputValue} className="form-control" value={postData.category} required>
                  <option value="0">Select your Post Category</option>
                  {postCat.map((post, index) => (
                    <option key={index} value={post.cat_title}>{post.cat_title}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Tags <span>(Separate tags using comma ",")</span></label>
                <input type="text" className="form-control" placeholder="Enter your Post Tags" name="tags" onChange={setInputValue} value={postData.tags} required />
              </div>
            </div>
          </div>
          <div className="row data-row" style={{ "padding": "0" }}>
            <div className="col-lg-12">
              <label>Select From Popular Tags</label>
              <div className='form-control data-div mt-2'>
                {postTags.length === 0 ? (
                  <div className='options bg-secondary'>-------- No Tags to Show --------</div>
                ) : (
                  postTags.map((tag, index) => (
                    <div key={index} className='options bg-primary'>
                      {tag.tag}
                      <button className={`btn btn-sm delete-btn ${postData.tags.includes(tag.tag) ? "bg-danger" : "bg-success"}`} type='button' onClick={() => handleTagAction(tag.tag)}>
                        <i className={postData.tags.includes(tag.tag) ? "fa-solid fa-trash" : "fa-solid fa-check"} style={{ "color": "#fff" }}></i>
                      </button>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Description</label>
                <textarea className="form-control" placeholder="Write something about your Post" name="description" onChange={setInputValue} value={postData.description} rows="3" required></textarea>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-3">
                <label>Likes</label>
                <input type="number" className="form-control" name="likes" onChange={setInputValue} value={postData.likes} required />
              </div>
              <div className="col-lg-3">
                <label>Shares</label>
                <input type="number" className="form-control" name="shared" onChange={setInputValue} value={postData.shared} required />
              </div>
              <div className="col-lg-3">
                <label>Comments</label>
                <input type="number" className="form-control" name="comments" onChange={setInputValue} value={postData.comments} required />
              </div>
              <div className="col-lg-3">
                <label>Views</label>
                <input type="number" className="form-control" name="views" onChange={setInputValue} value={postData.views} required />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className='row'>
              <div className='col-lg-6'>
                <label>Status</label>
                <select name="status" onChange={setInputValue} className="form-control" value={postData.status} required>
                  <option value="0">Select Post Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              <div className='col-lg-6 d-grid justify-content-end align-items-end'>
                <button type="submit" className="btn btn-primary">Update Post</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditPost;
