import React, { useState, useEffect } from 'react';
import './Message.css';
import receiver_img from './obaid_img.jpg';
import sender_img from './my_official.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import { chatById, msgByChatId } from '../../../services/Apis';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import { BASE_URL } from '../../../services/helper';

const ViewMessages = () => {
  const navigate = useNavigate();
  //<-------------------------- Messaging Functionality -------------------------->+
  const { id } = useParams();

  const [chat, setChat] = useState({});
  const getChat = async () => {
    try {
      const res = await chatById(id);
      if (res.status === 200) {
        setChat(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const [msgs, setMsgs] = useState([]);
  const getMessages = async () => {
    try {
      const res = await msgByChatId(id);
      if (res.status === 200) {
        setMsgs(res.data);
        console.log("Messages" + res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    getChat();
    getMessages();
  }, []);
  return (
    <>
      <AccessChecker accessKey="view_user_chats" />
      <div className="form-card" id="send-message">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>View Messages</h1>
          </div>
        </div>
        <hr />
        <div className='form-div msg'>
          <div className='msg-wrapper'>
            <div className='msg-head'>
              <div className='user-name'>From : {chat.sender}&nbsp; To: {chat.receiver}</div>
              <div className='close-btn' onClick={() => navigate("/admin/view-chats")}><i class="fa-solid fa-xmark"></i></div>
            </div>
            <div className='msg-box' style={{ "height": "555px" }}>
              {msgs.map((msg, index) => (<>
                {msg.sender === chat.sender ? (
                  <div className='receiver'>
                    <div className='user-p'><img src={`${BASE_URL}/uploads/admins-users-img/${chat.sender_img}`}/></div>
                    <div className='user-msg'>{msg.content}</div>
                  </div>
                ) : (
                  <div className='sender'>
                    <div className='user-msg'>{msg.content}</div>
                    <div className='user-p'><img src={`${BASE_URL}/uploads/admins-users-img/${chat.receiver_img}`}/></div>
                  </div>
                )}
              </>))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewMessages;