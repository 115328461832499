import React, { useState } from 'react';
import { BsUpload } from "react-icons/bs";
import img_icon from '../../assets/images/img-icon.png';
import { useNavigate } from 'react-router-dom';
import { addAdmin, addAdminAccess } from '../../../services/Apis';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import { isValidUsername } from '../../components/OtherFunctions/otherFunctions';

const AddAdmin = () => {

  let history = useNavigate();

  //<------------------- Add New Admin ------------------->
  const [userImg, setUserImg] = useState(null);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");

  //<------------------- Select Image and Show Selected Image ------------------->
  const [showImage, setShowImage] = useState(img_icon);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImage(URL.createObjectURL(selectedFile));
        setUserImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  //<------------------- Submit Form ------------------->
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!userImg) {
      alert("Please Add Admin Image");
    }
    else if (!isValidUsername(username)) {
      alert("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed")
    }
    else {
      const formData = new FormData();
      formData.append("user_img", userImg);
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("username", username);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("user_type", userType);
      formData.append("status", "active");
      const config = { "Content-Type": "multipart/form-data" }
      const res = await addAdmin(formData, config);
      if (res.status === 200) {
        if (userType === "minor_admin") {
          const data = { user_id: res.data._id };
          const config2 = { "Content-Type": "application/json" };
          const res2 = await addAdminAccess(data, config2);
          if (res2.status === 200) {
            alert("Minor Admin Registered Successfully");
          }
        }
        else {
          alert("Admin Registered Successfully");
        }
        history("/admin/view-admins");
      }
      else {
        if (res.response.data.message) {
          alert(res.response.data.message)
        } else {
          alert("Some Unknown Error Occurred")
        }
      }
    }

  }

  //<------------------- Form Reset Function ------------------->
  const refreshPage = () => {
    window.location.reload(false);
  }

  return (
    <>
      <AccessChecker accessKey="add_admin" />
      <div className='form-card' id="add-admin">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Add Admin</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={handleSubmit}>
          <div class="form-group img-upload-wrap">
            <div className='upld-img-icon'>
              <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={showImage} /></label>
              <input type="file" id="imageInput" accept="image/*" name="admin_img" style={{ display: 'none' }} onChange={handleChange} />
            </div>
            <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><span className='upload-icon-btn'><BsUpload /></span></label>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>First Name</label>
                <input type="text" class="form-control" placeholder="First Name" name="fname" onChange={(e) => setFname(e.target.value)} required />
              </div>
              <div className="col-lg-6">
                <label>Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" name="lname" onChange={(e) => setLname(e.target.value)} required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Username</label>
                <input type="text" class="form-control" placeholder="Username" name="username" onChange={(e) => setUsername(e.target.value)} required />
              </div>
              <div className="col-lg-6">
                <label>Email</label>
                <input type="email" class="form-control" placeholder="abc@domain.com" name="email" onChange={(e) => setEmail(e.target.value)} required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Role</label>
                <select name="admin_role" onChange={(e) => setUserType(e.target.value)} className="form-control" required>
                  <option value="0">Select Admin Role</option>
                  <option value="minor_admin">Minor Admin</option>
                  <option value="super_admin">Super Admin</option>
                </select>
              </div>
              <div className="col-lg-6">
                <label>Password</label>
                <input type="password" class="form-control" name="password" onChange={(e) => setPassword(e.target.value)} required />
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end pt-2'>
            <button type="button" class="btn btn-danger" onClick={refreshPage}>Reset Form</button>
            <span className='px-3'></span>
            <button type="submit" class="btn btn-primary">Add Admin</button>
          </div>

        </form>
      </div>
    </>
  )
}

export default AddAdmin;