import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { increasePostShareViews } from '../../../../services/Apis';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SharePostModal = (props) => {

  const handleCopyBtn = async () => {
    const result = await handleIncreaseShare();
    if (result) {
      navigator.clipboard.writeText(props.postLink)
        .then(() => {
          toast.success('Link copied to clipboard!');
        })
        .catch(err => {
          console.error('Failed to copy the text: ', err);
          // alert('Failed to copy the link. Please try again.');
          toast.error('Failed to copy the link. Please try again.');
        });
    } else {
      // alert('Failed to copy the link. Please try again.');
      toast.error('Failed to copy the link. Please try again.');
    }
  }

  const shareLinks = {
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(props.postLink)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.postLink)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(props.postLink)}&text=${encodeURIComponent('Check this out!')}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(props.postLink)}&title=${encodeURIComponent('Check this out!')}`,
    email: `mailto:?subject=${encodeURIComponent('Check this out!')}&body=${encodeURIComponent(props.postLink)}`,
    pinterest: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(props.postLink)}&media=&description=${encodeURIComponent('Check this out!')}`
  };

  const handleIncreaseShare = async () => {
    try {
      const response = await increasePostShareViews(props.post_id, { action: "shared" });
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error increasing share count:', error);
      return false;
    }
  }

  return (
    <>
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered id="post-share-modal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Share Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="social-share">
            <div className="wrap">
              <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" onClick={handleIncreaseShare}>
                <div className="icon"><i className="fa-brands fa-square-whatsapp whatsapp"></i></div>
                <div className="title">WhatsApp</div>
              </a>
            </div>
            <div className="wrap">
              <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer" onClick={handleIncreaseShare}>
                <div className="icon"><i className="fa-brands fa-square-facebook facebook"></i></div>
                <div className="title">Facebook</div>
              </a>
            </div>
            <div className="wrap">
              <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer" onClick={handleIncreaseShare}>
                <div className="icon"><i className="fa-brands fa-square-x-twitter twitter"></i></div>
                <div className="title">Twitter</div>
              </a>
            </div>
            <div className="wrap">
              <a href={shareLinks.linkedin} target="_blank" rel="noopener noreferrer" onClick={handleIncreaseShare}>
                <div className="icon"><i className="fa-brands fa-linkedin linkedin"></i></div>
                <div className="title">LinkedIn</div>
              </a>
            </div>
            <div className="wrap">
              <a href={shareLinks.email} target="_blank" rel="noopener noreferrer" onClick={handleIncreaseShare}>
                <div className="icon"><i className="fa-solid fa-square-envelope email"></i></div>
                <div className="title">Email</div>
              </a>
            </div>
            <div className="wrap">
              <a href={shareLinks.pinterest} target="_blank" rel="noopener noreferrer" onClick={handleIncreaseShare}>
                <div className="icon"><i className="fa-brands fa-square-pinterest pinterest"></i></div>
                <div className="title">Pinterest</div>
              </a>
            </div>
          </div>
          <div className="link-share">
            <label htmlFor="share-link" className="form-label">Shareable Link</label>
            <input type="text" id="share-link" value={props.postLink} readOnly className="form-control" />
          </div>
          <ToastContainer position="top-center" autoClose={1000} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCopyBtn}>Copy</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SharePostModal;
