import React, { useState, useContext } from 'react';
import { isSideNavOpen, pageTheme } from '../context/ContextProvider';
import { Link, useNavigate } from 'react-router-dom';
import './common.css';
import defaultAvatar from '../../assets/images/default-avatar.jpg'

const Header = () => {

    const navigate = useNavigate();
    const { theme, setTheme } = useContext(pageTheme);
    const changeTheme = () => {
        if (theme === "light-th") {
            setTheme("dark-th");
        }
        else {
            setTheme("light-th");
        }
    }
    const logOut = () => {
        sessionStorage.clear();
        navigate("/admin-login");
    };
    const { sideNav, setSideNav } = useContext(isSideNavOpen);

    return (
        <>
            <div id="admin-nav">
                <nav className={theme == "dark-th" ? ("navbar navbar-expand-lg dark-th-bg") : ("navbar navbar-expand-lg navbar-light ")}>
                    <div className="containerFluid" >
                        <button type="button" id="sidebarCollapse" className="navbar-btn" onClick={() => setSideNav(!sideNav)}>
                            {sideNav ? (<i className="fa-solid fa-bars"></i>) : (<i className='fa-solid fa-xmark'></i>)}

                        </button>
                        {/* <button className="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-align-justify"></i>
                        </button> */}
                    </div>

                    {/* <div className='col-md-10'>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item styled-nav">
                                    <a className="nav-link" href="#"><i className="fa-solid fa-message"></i><span className='count-symbol bg-primary'></span></a>
                                </li>
                                <li className="nav-item styled-nav">
                                    <a className="nav-link" href="#"><i className="fa-solid fa-bell"></i><span className='count-symbol bg-danger'></span></a>
                                </li>
                                <li className="nav-item styled-nav">
                                    <a className="nav-link" href="#"><i className="fa-solid fa-envelope"></i><span className='count-symbol bg-success'></span></a>
                                </li>
                                <li className="nav-item styled-nav">
                                    <a className="nav-link" href="#"><i className="fa-solid fa-user"></i>&nbsp; Welcome Obaidullah</a>
                                </li>
                                <li className="nav-item styled-nav">
                                    <Link className="nav-link" onClick={changeTheme}><i className="fa-solid fa-sun"></i>&nbsp; setting</Link>
                                </li>
                                <li className="nav-item styled-nav">
                                    <button className="nav-link" type='button' onClick={logOut}><i className="fa-solid fa-power-off"></i></button>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                   
                    <div className='rightNavbar'  >
                        <li className="nav-item">
                            <Link className="nav-link" href="#"><i className="fa-solid fa-message"></i><span></span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" href="#"><i className="fa-solid fa-bell"></i><span className='count-symbol bg-danger'></span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" href="#"><i className="fa-solid fa-envelope"></i><span className='count-symbol bg-success'></span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" onClick={changeTheme}><i className="fa-solid fa-sun"></i></Link>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" type='button' onClick={logOut}><i className="fa-solid fa-power-off"></i></button>
                        </li>
                        <div className='UserNameAndAvatar'>
                        <img src={defaultAvatar} alt="Avatar not found" />
                        <span className='userNameAdmin'>
                            <span style={{fontSize:'17px',fontWeight:'600'}} className="user-name">Obaidullah_zeeshan</span>
                            <span style={{fontSize:'13px',fontWeight:'500'}} className="user-sub-title">super Admin</span>
                        </span>
                    </div>
                    </div>
                </nav>
                   
            </div>
        </>
    )
}
export default Header;