import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { getSingleAdmin, getSingleUser } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import { decrypt } from '../../components/OtherFunctions/otherFunctions';

const ViewSingleAdmin = () => {

  //<------------------- Get Single Admin ------------------->  
  const [adminData, setAdminData] = useState({})
  const [admin_img, setAdminImg] = useState("");

  const { username } = useParams();

  const singleAdminData = async () => {
    const response = await getSingleUser(username);
    if (response.status === 200) {
      setAdminData(response.data);
      setAdminImg(response.data.user_img);
    }
    else {
      console.log("Error in Fetching Admin Details.");
    }
  }

  useEffect(() => {
    singleAdminData();
  }, [])

  return (
    <>
      <AccessChecker accessKey="view_admins" />
      <div className='form-card' id="single-admin">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>View Admin</h1>
          </div>
        </div>
        <hr />
        <div className='form-div'>
          <div class="form-group img-upload-wrap">
            <div className='upld-img-icon'>
              <img src={`${BASE_URL}/uploads/admins-users-img/${admin_img}`} />
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>First Name</label>
                <div className='form-control'>{adminData.fname}</div>
              </div>
              <div className="col-lg-6">
                <label>Last Name</label>
                <div className='form-control'>{adminData.lname}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Username</label>
                <div className='form-control'>{adminData.username}</div>
              </div>
              <div className="col-lg-6">
                <label>Email</label>
                <div className='form-control'>{adminData.email}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Role</label>
                <div className='form-control'>{adminData.admin_role === "minor_admin" ? "Minor Admin" : "Super Admin"}</div>
              </div>
              <div className="col-lg-6">
                <label>Password</label>
                <div className='form-control'>Hidden</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Status</label>
                <div className='form-control'>{adminData.status === "active" ? "Active" : "Inactive"}</div>
              </div>
              <div className="col-lg-6 d-flex justify-content-end align-items-end">
                <NavLink to={`/admin/edit-admin/${adminData._id}`} className="btn btn-primary text-decoration-none" style={{ "color": "#fff" }}>Edit Admin</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewSingleAdmin;