import React, { useContext, useEffect, useRef, useState } from 'react';
import { addCollection, addComment, createChat, getCommentsByPostid, getLatestPosts, getSinglePost, getSingleUser, getUserCollections, getUserFollowers, getUserLikedPosts, getUserPosts, postLikeUnlike, updatePostTags, userFollowUnfollow } from '../../../services/Apis';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderTop from '../../components/header/large-screen/HeaderTop';
import Sidebar from '../../components/common/Sidebar';
import { BASE_URL } from '../../../services/helper';
import { mainTheme } from '../../components/context/ContextProvider';
import { formatDate, isTokenValid } from '../../components/functions/OtherFunctions';
import TimeAgoConverter from '../../components/functions/TimeAgoConverter';
import SharePostModal from '../home/large-view/SharePostModal';
import SmallFooter from '../../components/footer/SmallFooter';
import SmallHeader from '../../components/header/small-screen/SmallHeader';
import { ToastContainer, toast } from 'react-toastify';
const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL;

const ViewPost = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const { post_id, post_by } = useParams();
  const username = sessionStorage.getItem('user_username') || "";
  const user_id = sessionStorage.getItem('user_id') || "";

  const [postData, setPostData] = useState({});
  const [tagsArray, setTagsArray] = useState([]);
  const [postByUser, setPostByUser] = useState({});
  const [comments, setComments] = useState([]);
  const [ltPost, setLtPosts] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [postLiked, setPostLiked] = useState([]);
  const [collections, setCollections] = useState([]);
  const [userPosts, setUserPosts] = useState([]);
  const [text, setText] = useState('');
  const textAreaRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [postLink, setPostLink] = useState();
  const { pageTheme, setPageTheme } = useContext(mainTheme);
  const [showAddTag, setShowAddTag] = useState(false);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const fetchSinglePost = async () => {
    const response = await getSinglePost(post_id);
    if (response.status === 200) {
      setPostData(response.data.singlePost);
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  useEffect(() => {
    if (postData.tags) {
      setTagsArray(postData.tags.split(','));
    }
  }, [postData.tags]);

  const userProfileGet = async () => {
    try {
      const res = await getSingleUser(post_by);
      if (res.status === 200) {
        setPostByUser(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error in Fetching User Details:", error);
    }
  };

  const fetchPostComments = async () => {
    try {
      const res = await getCommentsByPostid(post_id);
      if (res.status === 200) {
        setComments(res.data);
      } else {
        console.log("Error in Fetching Comments.");
      }
    } catch (error) {
      console.error("Error in Fetching Comments:", error);
    }
  };

  const fetchLatestPosts = async () => {
    try {
      const res = await getLatestPosts();
      if (res.status === 200) {
        setLtPosts(res.data);
      } else {
        console.log('Error in Fetching Posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const getCurrentUserData = async () => {
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setCurrentUser(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error in Fetching User Details:", error);
    }
  };

  const getLikedPost = async () => {
    try {
      const res = await getUserLikedPosts(username);
      if (res.status === 200) {
        setPostLiked(res.data.likedPosts);
      } else {
        console.log('Error in Fetching Liked Posts.');
      }
    } catch (error) {
      console.error('Error fetching liked posts:', error);
    }
  };

  const fetchCollections = async () => {
    try {
      const res = await getUserCollections(username);
      if (res.status === 200) {
        setCollections(res.data);
      } else {
        console.log('Error in Fetching Collections.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchUserPosts = async () => {
    try {
      const res = await getUserPosts(post_by);
      if (res.status === 200) {
        setUserPosts(res.data.userPosts);
      } else {
        console.log('Error in Fetching Posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const [followers, setFollowers] = useState([]);
  const fetchFollowers = async () => {
    try {
      const res = await getUserFollowers(post_by);
      if (res.status === 200) {
        setFollowers(res.data.followers);
      } else {
        console.log('Error in Fetching Followers.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchAll = async () => {
    await fetchSinglePost();
    await userProfileGet();
    await fetchPostComments();
    await fetchLatestPosts();
    await fetchUserPosts();
    if (username) {
      await getCurrentUserData();
      await getLikedPost();
      await fetchCollections();
      await fetchFollowers();
    }
  };

  useEffect(() => {
    fetchAll();
  }, [post_id, post_by]);

  const handleltPostClick = (username, post_id) => {
    setTimeout(() => {
      const newUrl = `/view-post/${username}/${post_id}`;
      window.location.href = newUrl;
    }, 300);
  };

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [text]);

  const submitComment = async () => {
    const commentData = { post_id, content: text, commented_by: username, posted_by: post_by };
    if (text === "") {
      // alert("Comment cannot be Empty");
      toast.error("Comment cannot be Empty");
    } else {
      try {
        const config = { "Content-Type": "application/json" };
        const res = await addComment(commentData, config);
        if (res.status === 200) {
          setText("");
          fetchPostComments();
          // alert("Commented Successfully");
          toast.success("Commented Successfully");
        } else {
          // alert("Comment Cannot be Added Now");
          toast.error("Comment Cannot be Added Now");
        }
      } catch (error) {
        console.error('Error:', error.res.data);
      }
    }
  };

  const handlePostModal = () => {
    setModalShow(true);
    setPostLink(`${WEBSITE_URL}/view-post/${post_by}/${post_id}`);
  };

  const handleLike = async () => {
    if (isValid === true) {
      const data = { post_id, liked_by: username, posted_by: post_by };
      try {
        const config = { 'Content-Type': 'application/json', };
        const res = await postLikeUnlike(data, config);
        if (res.status === 200) {
          getLikedPost();
          fetchSinglePost();
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  };

  const handleCollection = async () => {
    if (isValid === true) {
      const data = { post_id, post_img: postData.post_img, post_title: postData.title, posted_by: post_by, user_id };
      try {
        const config = { 'Content-Type': 'application/json' };
        const res = await addCollection(data, config);
        if (res.status === 200) {
          fetchCollections(username);
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  };

  const handleFollow = async () => {
    if (isValid === true) {
      const data = { user_id: postByUser._id, follower_id: user_id };
      try {
        const config = { 'Content-Type': 'application/json', };
        const res = await userFollowUnfollow(data, config);
        if (res.status === 200) {
          fetchFollowers();
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  };

  const handleTagsChange = (e) => {
    setPostData({ ...postData, tags: e.target.value });
  };

  const submitTags = async () => {
    if (postData.tags) {
      const config = {
        "Content-Type": "application/json"
      }
      const res = await updatePostTags(post_id, { tags: postData.tags }, config);
      if (res.status === 200) {
        // alert("Tags Updated Successfully");
        alert("Tags Updated Successfully");
        fetchSinglePost();
      }
      else {
        console.log(res.response.data.message);
        if (res.response.data.message) {
          // alert(res.response.data.message)
          toast.error(res.response.data.message)
        }
        else {
          // alert("Some Unknown Error Occured")
          toast.error("Some Unknown Error Occured")
        }
      }
    } else {
      // alert("Tag cannot be Empty please Add atleast one Tag")
      toast.error("Tag cannot be Empty please Add atleast one Tag")
    }
  };

  const openChat = async () => {
    if (isValid === true) {
      if (username === post_by) {
        navigate("/edit-profile");
      }
      else {
        const data = { sender: username, receiver: post_by };
        try {
          const config = { 'Content-Type': 'application/json', };
          const res = await createChat(data, config);
          if (res.status === 200) {
            navigate("/message");
          }
        } catch (error) {
          console.error('Error:', error.response.data);
        }
      }
    }
    else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  }

  return (
    <>{!isSmallScreen ? (<>
      <HeaderTop />
      <Sidebar />
    </>) : (
      <SmallHeader />
    )}
      <div className="container-fluid" id="view-user-post">
        <div className="row post-wrapper">
          <div className="col-lg-9 left-wrapper">
            <div className="post-img">
              <img src={`${BASE_URL}/uploads/posts-img/${postData.post_img}`} alt={postData.title} />
            </div>
            <div className="title-div">
              <div className="user-profile">
                <div className="user-img"><img src={`${BASE_URL}/uploads/admins-users-img/${postByUser.user_img}`} alt={postByUser.username} /></div>
                <div className="post-title">
                  <div className="title">{postData.title}</div>
                  <div className="follow">by <span>{postByUser.username}</span>
                    {username !== post_by ? (<>
                      <button type='button' onClick={handleFollow}>
                        {followers.length > 0 ?
                          (followers.some((follow) => follow.follower_id === user_id) ?
                            (<><i className="fa-solid fa-check"></i> Following</>) :
                            (<><i className="fa-solid fa-plus"></i> Follow</>)) :
                          (<><i className="fa-solid fa-plus"></i> Follow</>)
                        }
                      </button>
                    </>) : ('')}
                  </div>
                </div>
              </div>
              <div className="options">
                <div className="small-follow-div">
                  {username !== post_by ? (<>
                    <button type='button' onClick={handleFollow}>
                      {followers.length > 0 ?
                        (followers.some((follow) => follow.follower_id === user_id) ?
                          (<><i className="fa-solid fa-check"></i> Following</>) :
                          (<><i className="fa-solid fa-plus"></i> Follow</>)) :
                        (<><i className="fa-solid fa-plus"></i> Follow</>)
                      }
                    </button>
                  </>) : ('')}
                </div>
                <div className='icon-div'>
                  <div className="icons" onClick={handleLike}>
                    {postLiked.length > 0 ? postLiked.some((liked) => liked.post_id === post_id) ? <i className="fa-solid fa-heart" style={{ color: 'red' }}></i> : <i className="fa-regular fa-heart"></i> : <i className="fa-regular fa-heart"></i>}
                  </div>
                  <div className="icons" onClick={handlePostModal}><i className="fa-regular fa-share-from-square"></i></div>
                  <div className="icons" onClick={handleCollection}>
                    {collections.length > 0 ? collections.some((coll) => coll.post_id === post_id) ? <i class="fa-solid fa-bookmark" style={{ color: 'rgb(59, 113, 202)' }}></i> : <i class="fa-regular fa-bookmark"></i> : <i class="fa-regular fa-bookmark"></i>}
                  </div>
                </div>
              </div>
            </div>
            <div className="desc-div">{postData.description}</div>
            <div className="post-info">
              <div className="other-info">{postData.likes} Likes . {postData.comments} Comments . {postData.shared} Shared . {postData.views} Views</div>
              <div className="date">Published on : {formatDate(postData.post_date)}</div>
            </div>
            {tagsArray.length > 0 ?
              <div className="related-tags">
                <div className="head">Related Tags</div>
                <div className="tags">
                  {(tagsArray.map((tag, index) => (
                    <div key={index} className="each-tag">#{tag.trim()}</div>
                  )))}
                  {isValid && post_by === username ? (<div className="each-tag icon" onClick={() => setShowAddTag(!showAddTag)}>
                    {showAddTag ? (<i className='fa-solid fa-minus'></i>) : (<i className='fa-solid fa-plus'></i>)}
                  </div>) : ('')}
                </div>
                {showAddTag ? (
                  <div className="add-tags">
                    <div className='input-div'><input value={postData.tags} onChange={handleTagsChange} /><div className='hint'>E.g; Art, Photography, Nature, Background, Potrait</div></div>
                    <div className='btn-div'><button onClick={submitTags}>Add Tag</button></div>
                  </div>
                ) : ('')}
              </div>
              : ('')}
            <div className="view-comments">
              <div className="head">Comments</div>
              <div className="comments-wrap add-comment">
                {isValid ? (
                  <>
                    <div className="user-img"><img src={`${BASE_URL}/uploads/admins-users-img/${currentUser.user_img}`} alt={currentUser.username} /></div>
                    <div className="comment">
                      <textarea ref={textAreaRef} value={text} onChange={(e) => setText(e.target.value)} placeholder="Type your comment..." />
                      {text ? (<div className="options">
                        <button type='button' onClick={() => setText('')}>Cancel</button>
                        <button type='button' onClick={submitComment}>Comment</button>
                      </div>) : ('')}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="user-img"><img src="/assets/images/default-user.png" alt="default user" /></div>
                    <div className="comment"><span style={{ "fontWeight": "500" }}>Join our Community</span> to add your comment. Already a member? <Link to="/login">Log In</Link></div>
                  </>
                )}
              </div>
              {comments.map((comm, index) => (
                <div key={index} className="comments-wrap">
                  <div className="user-img"><img src={`${BASE_URL}/uploads/admins-users-img/${comm.user.user_img}`} alt={comm.user.username} /></div>
                  <div className="comment">
                    <div className="info"><div className='username'>{comm.user.username}</div><div className='time'><TimeAgoConverter dateTime={comm.comment_date} /></div></div>
                    <div className='content'>{comm.content}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-lg-3 right-wrapper">
            <div className="profile-div">
              <div className="profile-img">
                <div className="cover-img">
                  {postByUser.cover_img ? (<img src={`${BASE_URL}/uploads/users-cover-img/${postByUser.cover_img}`} alt="cover" />
                  ) : (
                    pageTheme === "dark_th" ? (<img src="/assets/images/profile-page/cover-img-dark.jpg" alt="cover" />
                    ) : (
                      <img src="/assets/images/profile-page/cover-img-light.jpg" alt="cover" />
                    ))}
                </div>
                <div className="profile-dp">
                  <div className="img"><img src={`${BASE_URL}/uploads/admins-users-img/${postByUser.user_img}`} alt={postByUser.username} /></div>
                  <div className="username">{postByUser.fname + " " + postByUser.lname} <br /> @{postByUser.username}</div>
                </div>
              </div>
              <div className="profile-btn">
                <div className='follow'><button type="button" onClick={() => navigate(`/${post_by}`)}>View Profile</button></div>
                {username === post_by ? (<div className='msg'><button type="button" onClick={openChat}>Edit Profile</button></div>) :
                  (<div className='msg'><button type="button" onClick={openChat}>Message</button></div>)}
              </div>
            </div>
            <div className="more-posts-div">
              <div className="head">More by <span>{postByUser.username}</span></div>
              <hr className='post-divider' />
              <div className="post-wrap">
                {userPosts.slice(0, 6).map((post, index) => (
                  <div key={index} className="more-img" onClick={() => handleltPostClick(post.username, post._id)}><img src={`${BASE_URL}/uploads/posts-img/${post.post_img}`} alt={post.title} /></div>
                ))}
              </div>
            </div>
            <div className="latest-posts-div">
              <div className="head">Latest Posts</div>
              <hr className='post-divider' />
              <div className="post-wrap">
                {ltPost.map((ltposts, index) => (
                  <div key={index} className="more-img" onClick={() => handleltPostClick(ltposts.username, ltposts._id)}><img src={`${BASE_URL}/uploads/posts-img/${ltposts.post_img}`} alt={ltposts.title} /></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSmallScreen? <SmallFooter /> : ''}
      <ToastContainer position="top-center" autoClose={1000} />
      <SharePostModal show={modalShow} postLink={postLink} onHide={() => setModalShow(false)} post_id={post_id} fetchSinglePost={fetchSinglePost} />
    </>
  );
}

export default ViewPost;
