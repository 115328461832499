import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getSinglePost } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './post.css';

const ViewSinglePost = () => {
  //<------------------- View Single Post ------------------->
  const [postData, setPostData] = useState({});

  const [postFormatDate, setPostFormatDate] = useState("");
  const [postUpdDate, setPostUpdDate] = useState("");

  const { id } = useParams();

  const fetchSinglePost = async () => {
    const response = await getSinglePost(id);
    if (response.status === 200) {
      setPostData(response.data.singlePost);
      const postDate = new Date(response.data.singlePost.post_date);
      setPostFormatDate(formatDate(postDate));
      const updDate = new Date(response.data.singlePost.upd_date);
      setPostUpdDate(formatDate(updDate))
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    fetchSinglePost();
  }, []);

  return (
    <>
      <AccessChecker accessKey="view_posts" />
      <div className='form-card' id="add-post">
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Post</h1>
            <div><Link to={`/admin/edit-post/${postData._id}`}><button className='btn btn-primary'>Edit Post</button></Link></div>
          </div>
        </div>
        <hr />
        <form className='form-div'>
          <div class="form-group img-upload-wrap">
            <div className='upld-img-icon'>
              <img src={`${BASE_URL}/uploads/posts-img/${postData.post_img}`} alt="Post" />
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Posted By </label>
                <div class="form-control">{postData.username}</div>
              </div>
              <div className="col-lg-6">
                <label>Posted On </label>
                <div class="form-control">{postFormatDate}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Title</label>
                <div class="form-control">{postData.title}</div>
              </div>
              <div className="col-lg-6">
                <label>Category</label>
                <div class="form-control">{postData.category}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Tags</label>
                <div class="form-control">{postData.tags}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Description</label>
                <div class="form-control">{postData.description}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Likes</label>
                <div class="form-control">{postData.likes}</div>
              </div>
              <div className="col-lg-6">
                <label>Comments</label>
                <div class="form-control">{postData.comments}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Shared</label>
                <div class="form-control">{postData.shared}</div>
              </div>
              <div className="col-lg-6">
                <label>Views</label>
                <div class="form-control">{postData.views}</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Status</label>
                <div class="form-control">{postData.status}</div>
              </div>
              <div className="col-lg-6">
                <label>Date of Update</label>
                <div class="form-control">{postUpdDate ? postUpdDate : "Not Updated Yet"}</div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </>
  );
};

export default ViewSinglePost;
