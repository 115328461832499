// import React, { useContext } from "react";
// import { Chart } from "react-google-charts";
// import { pageTheme } from "../../../components/context/ContextProvider";

// export const data = [
//   ["Users", "Visits (in millions)"],
//   ["Assam", 13],
//   ["west Bengal", 83],
//   ["Andhra Pradesh", 1.4],
//   ["Bihar", 2.3],
//   ["Gujarat", 46],
//   ["Chhattisgarh", 300],
//   ["Karnataka", 38],
//   ["Kashmir", 5.5],
//   ["Punjab", 5],
//   ["Rajasthan", 20],
//   ["Sikkim", 33],
//   ["Tamil Nadu", 1.5],
//   ["Telangana", 72],
//   ["Tripura", 2.9],
//   ["Uttar Pradesh", 33],
//   ["Uttarakhand", 29],
//   ["Meghalaya", 0.01],
//   ["Nagaland", 6.5],
//   ["Mizoram", 2.5],
//   ["Haryana", 61],
//   ["Gujarat", 74],
//   ["Goa", 52],
// ];

// export const options = {
//   title: "State Wise Users",
//   legend: "none",
//   pieSliceText: "label",
//   slices: {
//     4: { offset: 0.2 },
//     12: { offset: 0.3 },
//     14: { offset: 0.4 },
//     15: { offset: 0.5 },
//   },
  
//   backgroundColor:theme==='dark-th-bg'?'red':'white',
// };

// export function DifferentStateUser() {
//   return (
//     <Chart
//       chartType="PieChart"
//       data={data}
//       options={options}
//       width={"100%"}
//       height={"109%"}
//     />
//   );
// }



import React, { useContext } from "react";
import { Chart } from "react-google-charts";
import { pageTheme } from "../../../components/context/ContextProvider";

export const data = [
  ["Users", "Visits (in millions)"],
  ["Assam", 13],
  ["West Bengal", 83],
  ["Andhra Pradesh", 1.4],
  ["Bihar", 2.3],
  ["Gujarat", 46], // Removed the duplicated Gujarat value
  ["Chhattisgarh", 300],
  ["Karnataka", 38],
  ["Kashmir", 5.5],
  ["Punjab", 5],
  ["Rajasthan", 20],
  ["Sikkim", 33],
  ["Tamil Nadu", 1.5],
  ["Telangana", 72],
  ["Tripura", 2.9],
  ["Uttar Pradesh", 33],
  ["Uttarakhand", 29],
  ["Meghalaya", 0.01],
  ["Nagaland", 6.5],
  ["Mizoram", 2.5],
  ["Haryana", 61],
  ["Goa", 52],
];

export function DifferentStateUser() {
  const { theme } = useContext(pageTheme);
  const options = {
    title: "State Wise Users",
    legend: "none",
    pieSliceText: "label",
    slices: {
      4: { offset: 0.2 },
      12: { offset: 0.3 },
      14: { offset: 0.4 },
      15: { offset: 0.5 },
    },
    backgroundColor: theme === 'dark-th' ? '#032d46' : '#fff',
  };

  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"109%"}
    />
  );
}