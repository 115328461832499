import React, { useContext, useEffect, useState } from 'react';
import './SmallHeader.css';
import light_logo from '../../../assets/images/yoeavnu_logo.png';
import dark_logo from '../../../assets/images/dark_logo.png'
import { mainTheme } from '../../context/ContextProvider';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import { getSingleUserUnseenNotifs, getUnseenMsgs } from '../../../../services/Apis';
import { isTokenValid } from '../../functions/OtherFunctions';
import { FaBell } from "react-icons/fa";
import { PiChatTeardropTextFill } from "react-icons/pi";
import { FaEllipsisVertical } from "react-icons/fa6";

const SmallHeader = () => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const { pageTheme, setPageTheme } = useContext(mainTheme);
  const username = sessionStorage.getItem("user_username");

  const [msgNotif, setMsgNotif] = useState([]);
  const fetchMsgNotif = async () => {
    try {
      const res = await getUnseenMsgs(username);
      if (res.status === 200) {
        // console.log(res.data);
        setMsgNotif(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const [notifs, setNotifs] = useState([]);
  const fetchAllNotifs = async () => {
    try {
      const res = await getSingleUserUnseenNotifs(username);
      if (res.status === 200) {
        setNotifs(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchMsgNotif();
      await fetchAllNotifs();
    };
    fetchData(); // Call initially

    const interval = setInterval(fetchData, 1000); // Call every second
    return () => clearInterval(interval); // Cleanup
  }, [username]);

  return (
    <>
      <div className="container-fluid" id="small-header">
        <div className="row">
          <div className="col-7">
            <div className="logo-wrapper">
              <Link to="/">{pageTheme === "blue_th" || pageTheme === "dark_th" ? (<img src={dark_logo} alt="Company Logo" />) : (<img src={light_logo} alt="Company Logo" />)}</Link>
            </div>
          </div>
          <div className="col-5">
            <div className="icon-wrapper">
              {isValid?(<>
              <Link to="/notification" className='nav-icon'>
                {/* <i className="fa-regular fa-bell"></i> */}
                <FaBell />
                {notifs.length > 0 && (<span className='msg-count-symbol bg-danger'></span>)}
              </Link>
              <Link to="/message" className='nav-icon'>
                {/* <i className="fa-regular fa-message"></i> */}
                <PiChatTeardropTextFill />
                {msgNotif.length > 0 && (<span className='msg-count-symbol bg-success'></span>)}
              </Link>
              </>):("")}
              <Link to="#">
                <Dropdown className="text-center">
                  <Dropdown.Toggle className="action" id="dropdown-basic">
                    <FaEllipsisVertical />
                    {/* <i className="fa-solid fa-ellipsis-vertical"></i> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setPageTheme("dark_th")}>
                      <div><i className="fa-solid fa-moon" style={{ color: "#000000" }}></i></div><div>Dark Theme</div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setPageTheme("blue_th")}>
                      <div><i className="fa-solid fa-sun" style={{ color: "darkblue" }}></i></div><div>Blue Theme</div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setPageTheme("light_th")}>
                      <div><i className="fa-solid fa-brush" style={{ color: "#D9D9D9" }}></i></div><div>Light Theme</div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmallHeader;
