import React, { useCallback, useState, useEffect, useContext } from 'react';
import './signup.css';
import { checkUserEmailExist, signUpFunc, socialLogin, socialSignUp } from '../../../services/Apis';
import { Link, useNavigate } from 'react-router-dom';
import login_light from '../../assets/login-job-imgs/login-light.png'
import login_dark from '../../assets/login-job-imgs/login-dark.png'
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';
import { GoogleLoginButton, FacebookLoginButton, GithubLoginButton } from 'react-social-login-buttons';
import { BASE_URL } from '../../../services/helper';
import { loginAlert, mainTheme } from '../../components/context/ContextProvider';
import { isValidUsername } from '../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const FACEBOOK_APP_ID = import.meta.env.VITE_FACEBOOK_APP_ID;
const GITHUB_CLIENT_ID = import.meta.env.VITE_GITHUB_CLIENT_ID;

const Login = () => {
  const { pageTheme, setPageTheme } = useContext(mainTheme);

  // <---------------------------------------------- Change Screen Based on Width ---------------------------------------------->

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // <---------------------------------------------- Manual Signup Code ---------------------------------------------->

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    username: '',
    email: '',
    password: '',
    user_img: 'default-avatar',
    status: 'active'
  });

  const [confirmPass, setConfirmPass] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password === confirmPass) {
      if (!isValidUsername(formData.username)) {
        // alert("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed");
        toast.error("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed");
      }
      else {
        try {
          const config = {"Content-Type": "application/json"}
          const res = await signUpFunc(formData, config);
          if (res.status === 200) {
            // const token = res.data;
            // const decodedToken = jwtDecode(token);
            // const userId = decodedToken.userId;
            // const username = decodedToken.username;
            // sessionStorage.setItem('user_token', token);
            // sessionStorage.setItem('user_id', userId);
            // sessionStorage.setItem('user_username', username);
            // navigate(`/complete-profile/${userId}`);
            alert("PLease Verify Email");
          } else {
            if (res.response.data.message) {
              // alert(res.response.data.message)
              toast.error(res.response.data.message)
            } else {
              // alert("Some Unknown Error Occurred")
              toast.error("Some Unknown Error Occurred")
            }
          }
        } catch (error) {
          console.error('Error:', error.res.data);
        }
      }
    } else {
      // alert("Please enter same password for Confirm Password");
      toast.error("Please enter same password for Confirm Password");
    }
  };

  // <---------------------------------------------- choosing Login Type ---------------------------------------------->

  const { login, setLogin } = useContext(loginAlert);
  const [chooseLogin, setChooseLogin] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userExist, setUserExist] = useState(true);

  const handleChooseLogin = (choose) => {
    if (choose === "Google") {
      setChooseLogin(choose);
    }
    else if (choose === "GitHub") {
      if (sessionStorage.getItem("github_accessToken") === null) {
        handleGitHubLogin();
      }
      setChooseLogin(choose);
    }
    else if (choose === "Facebook") {
      setChooseLogin(choose);
    }
  }

  // <---------------------------------------------- Google Login Code ---------------------------------------------->  
  const onResolveGoogleLogin = async ({ provider, data }) => {
    try {
      const { access_token } = data;
      const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user data from Google API');
      }
      const userData = await response.json();
      const { given_name, family_name, email } = userData;
      setFname(given_name);
      setLname(family_name);
      submitSocialLogin(email);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // <---------------------------------------------- GitHub Login Code ---------------------------------------------->
  const [reRender, setReRender] = useState(true);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeParams = urlParams.get("code");

    if (codeParams && (sessionStorage.getItem("github_accessToken") === null)) {
      async function getAccessToken() {
        await fetch(`${BASE_URL}/getGitHubAccessToken?code=${codeParams}`, {
          method: "GET"
        }).then((response) => {
          return response.json();
        }).then((data) => {
          if (data.access_token) {
            sessionStorage.setItem("github_accessToken", data.access_token);
            setReRender(!reRender);
          }
        })
      }
      getAccessToken();
    }
  }, [])

  async function getUserData() {
    await fetch(`${BASE_URL}/getUserData`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + sessionStorage.getItem("github_accessToken")
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      const fullName = data.name;
      const [firstName, lastName] = fullName.split(' ');
      console.log("Profile Picture:", data);
      setFname(firstName);
      setLname(lastName);
      submitSocialLogin(data.email);
    });
  }


  const handleGitHubLogin = () => {
    window.location.assign("https://github.com/login/oauth/authorize?client_id=" + GITHUB_CLIENT_ID);
  }

  // <---------------------------------------------- Facebook Login Code ---------------------------------------------->

  const onResolveFacebookLogin = useCallback(async (data) => {
    try {
      const { userID, accessToken } = data.data;
      const response = await fetch(`https://graph.facebook.com/${userID}?fields=id,name,email,picture&access_token=${accessToken}`);
      const userData = await response.json();
      const [firstName, lastName] = userData.name.split(' ');
      setFname(firstName);
      setLname(lastName);
      submitSocialLogin(userData.email);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  // <---------------------------------------------- Social Login Code ---------------------------------------------->

  const submitSocialLogin = async (email) => {
    if (email) {
      try {
        const EmailExist = await checkUserEmailExist(email);
        if (EmailExist.data.exists) {
          handleSocialLogin(email);
        }
        else {
          setUserExist(false);
          setEmail(email);
        }
      }
      catch (error) {
        console.error('Error:', error);
      }
    }
    else {
      if (chooseLogin) {
        // alert(chooseLogin + " didnt gave access to your Email! Please try someother way");
        toast.error(chooseLogin + " didnt gave access to your Email! Please try someother way");
      }
      else {
        // alert("Cannot get your Email! Please try someother way");
        toast.error("Cannot get your Email! Please try someother way");
      }
    }
  }

  const handleSocialLogin = async (email) => {
    const config = {
      "Content-Type": "application/json"
    };
    const sendData = { email };
    const res = await socialLogin(sendData, config);
    if (res.status === 200) {
      const token = res.data;
      const decodedToken = jwtDecode(token);
      sessionStorage.setItem('user_token', token);
      sessionStorage.setItem('user_id', decodedToken.userId);
      sessionStorage.setItem('user_username', decodedToken.username);
      setLogin(decodedToken.username);
      navigate("/");
    } else {
      if (res.response.data.message) {
        // alert(res.response.data.message);
        toast.error(res.response.data.message);
      } else {
        // alert("Some Unknown Error Occurred");
        toast.error("Some Unknown Error Occurred");
      }
    }
  }

  const handleSocialSignup = async (e) => {
    e.preventDefault();
    const config = { "Content-Type": "application/json" };
    if (fname && lname && username && email && password) {
      if (!isValidUsername(username)) {
        // alert("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed")
        toast.error("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed")
      }
      else {
        const sendData = { fname, lname, username, email, password };
        const res = await socialSignUp(sendData, config);
        if (res.status === 200) {
          const token = res.data;
          const decodedToken = jwtDecode(token);
          sessionStorage.setItem('user_token', token);
          sessionStorage.setItem('user_id', decodedToken.userId);
          sessionStorage.setItem('user_username', decodedToken.username);
          setLogin(decodedToken.username);
          navigate("/");
        } else {
          if (res.response.data.message) {
            // alert(res.response.data.message);
            toast.error(res.response.data.message);
          } else {
            // alert("Some Unknown Error Occurred");
            toast.error("Some Unknown Error Occurred");
          }
        }
      }

    } else {
      // alert("Some Data is Missing");
      toast.error("Some Data is Missing");
    }
  }

  return (
    <>{isSmallScreen ? (

      <>
        <div className="container-fluid" id="small-login">
          <div className="row">
            <div className="col-12">
              <div className="login-head">Sign Up</div>
            </div>
            <hr />
            <div className="login-img">
            {((pageTheme === "dark_th") || (pageTheme === "blue_th")) ?
              (<img src={login_dark} alt="Login" />) :
              (<img src={login_light} alt="Login" />)}
            </div>
            {/* <form className="form-wrapper" onSubmit={handleSubmit}>
              <div className="form-group two-input">
                <input type='text' placeholder='First Name' name="fname" onChange={handleChange} />
                <input type='text' placeholder='Last Name' name="lname" onChange={handleChange} />
              </div>
              <div className="form-group">
                <input type='text' placeholder='Username' name="username" onChange={handleChange} />
              </div>
              <div className="form-group">
                <input type='text' placeholder='Email' name="email" onChange={handleChange} />
              </div>
              <div className="form-group two-input">
                <input type='password' placeholder='Password' name="password" onChange={handleChange} />
                <input type='password' placeholder='Confirm Password' name="password" onChange={(e) => setConfirmPass(e.target.value)} />
              </div>
              <div className='login-btn'><button type='submit'>Sign Up</button></div>
            </form> */}
            {userExist?(<>
              <form className="form-wrapper" onSubmit={handleSubmit}>
              <div className="form-group two-input">
                <input type='text' placeholder='First Name' name="fname" onChange={handleChange} required/>
                <input type='text' placeholder='Last Name' name="lname" onChange={handleChange} required/>
              </div>
              <div className="form-group">
                <input type='text' placeholder='Username' name="username" onChange={handleChange} required/>
              </div>
              <div className="form-group">
                <input type='text' placeholder='Email' name="email" onChange={handleChange} required/>
              </div>
              <div className="form-group two-input">
                <input type='password' placeholder='Password' name="password" onChange={handleChange} required/>
                <input type='password' placeholder='Confirm Password' name="password" onChange={(e) => setConfirmPass(e.target.value)} required/>
              </div>
              <div className='login-btn'><button type='submit'>Sign Up</button></div>
            </form>
            </>):(<>
              <form className="form-wrapper" onSubmit={handleSocialSignup}>
                  <p style={{ "color": "black", "padding": "10px" }}>You are Logging In for the First time please Set your Username and Password</p>
                  <div className='form-group'>
                    <input type='text' placeholder='Username' name="username" onChange={(e) => setUsername(e.target.value)} required />
                  </div>
                  <div className='form-group'>
                    <input type='password' placeholder='Password' name="password" onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <div className='login-btn'><button type='submit'>Sign Up</button></div>
                </form>
            </>)}
            <div className='other-signin'>
              <div className='continue'>Or Continue with</div>
              <div className='icons-wrapper'>
                <div className='icons' onClick={() => handleChooseLogin("Google")}><i className="bi bi-google"></i></div>
                <div className='icons' onClick={() => handleChooseLogin("Facebook")}><i className="bi bi-facebook"></i></div>
                <div className='icons' onClick={() => handleChooseLogin("GitHub")}><i className="bi bi-github"></i></div>
              </div>
            </div>

            {(sessionStorage.getItem("github_accessToken") && chooseLogin !== "Google" && chooseLogin !== "Facebook") || chooseLogin === "GitHub" ? (
              <GithubLoginButton onClick={getUserData} />
            ) : ('')}
            {chooseLogin === "Google" && (
              <>
              <LoginSocialGoogle isOnlyGetToken client_id={GOOGLE_CLIENT_ID} onResolve={onResolveGoogleLogin} onReject={(err) => { console.log(err); }} scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email">
              <GoogleLoginButton />
              </LoginSocialGoogle>
              </>)}
            {chooseLogin === "Facebook" && (
              <>
                <LoginSocialFacebook isOnlyGetToken appId={FACEBOOK_APP_ID} onResolve={onResolveFacebookLogin} onReject={(err) => { console.log(err) }}>
                  <FacebookLoginButton />
                </LoginSocialFacebook>
              </>)}
            <div className='hints'>Have an Account Already ? <Link className='highlight' to="/login">Login.</Link></div>
          </div>
        </div>
      </>
    ) : (
      <div class="container-fluid" id="signup-page">
        <div class="row d-flex h-100">
          <div class="col-lg-5">
            {((pageTheme === "dark_th") || (pageTheme === "blue_th")) ?
              (<img src={login_dark} className='w-100' alt="Login" />) :
              (<img src={login_light} className='w-100' alt="Login" />)}
          </div>
          <div class="col-lg-7 m-auto">
            <div className='login-wrapper'>
              <h1 className='login-head'>Sign Up</h1>
              <hr />
              {userExist ? (<>
                <form onSubmit={handleSubmit}>
                  <div className='input-wrapper d-flex'>
                    <input type='text' placeholder='First Name' name="fname" onChange={handleChange} />
                    <input type='text' placeholder='Last Name' name="lname" onChange={handleChange} />
                  </div>
                  <div className='input-wrapper d-flex'>
                    <input type='text' placeholder='Username' name="username" onChange={handleChange} />
                  </div>
                  <div className='input-wrapper d-flex'>
                    <input type='text' placeholder='Email' name="email" onChange={handleChange} />
                  </div>
                  <div className='input-wrapper d-flex'>
                    <input type='password' placeholder='Password' name="password" onChange={handleChange} />
                    <input type='password' placeholder='Confirm Password' name="password" onChange={(e) => setConfirmPass(e.target.value)} />
                  </div>
                  <p className='forget-pwd'>Forgot Your <Link to="">Password ?</Link></p>
                  <div className='login-btn'><button type='submit'>Sign Up</button></div>
                </form>
              </>) : (<>
                <form onSubmit={handleSocialSignup}>
                  <p style={{ "color": "black", "padding": "10px" }}>You are Logging In for the First time please Set your Username and Password</p>
                  <div className='input-wrapper d-flex'>
                    <input type='text' placeholder='Username' name="username" onChange={(e) => setUsername(e.target.value)} required />
                  </div>
                  <div className='input-wrapper d-flex'>
                    <input type='password' placeholder='Password' name="password" onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <div className='login-btn'><button type='submit'>Sign Up</button></div>
                </form>
              </>)}
              <div className='other-signin'>
                <h1>Or Continue with</h1>
                <div className='icons-wrapper'>
                  <div className='icons' onClick={() => handleChooseLogin("Google")}><i className="bi bi-google"></i></div>
                  <div className='icons' onClick={() => handleChooseLogin("Facebook")}><i className="bi bi-facebook"></i></div>
                  <div className='icons' onClick={() => handleChooseLogin("GitHub")}><i className="bi bi-github"></i></div>
                </div>
              </div>
              {(sessionStorage.getItem("github_accessToken") && chooseLogin !== "Google" && chooseLogin !== "Facebook") || chooseLogin === "GitHub" ? (
                <GithubLoginButton onClick={getUserData} />
              ) : ('')}
              {chooseLogin === "Google" && (
                <>
                <LoginSocialGoogle isOnlyGetToken client_id={GOOGLE_CLIENT_ID} onResolve={onResolveGoogleLogin} onReject={(err) => { console.log(err); }} scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email">
                <GoogleLoginButton />
                </LoginSocialGoogle>
                </>)}
              {chooseLogin === "Facebook" && (
                <>
                  <LoginSocialFacebook isOnlyGetToken appId={FACEBOOK_APP_ID} onResolve={onResolveFacebookLogin} onReject={(err) => { console.log(err) }}>
                    <FacebookLoginButton />
                  </LoginSocialFacebook>
                </>)}
              <div className='signup'>
                <p>Have an account Already?<Link to="/login"> Login</Link></p>
              </div><div className='signup'>
                <p style={{ "fontSize": "17px" }}>Go back to <Link to="/"> Home</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  )
}

export default Login