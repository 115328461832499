// import React,{useContext} from "react";
// import { Chart } from "react-google-charts";
// import { pageTheme } from "../../../components/context/ContextProvider";

// export const data = [
//   ["Task", "Hours per Day"],
//   ["JAN", 11],
//   ["FEB", 2],
//   ["MAR", 2],
//   ["APR", 2],
//   ["MAY", 7],
//   ["JUN", 9],
//   ["JULY", 3],
//   ["AUG",6],
//   ["SEP", 5],
//   ["OCT", 3],
//   ["NOV", 1],
//   ["DEC", 8],
// ];



// export function MonthlyVisitsChart() {
//     const { theme } = useContext(pageTheme);
//      const options = {
//         title: "Users monthly visits",
//         backgroundColor: theme === 'dark-th' ? '#032d46' : '#fff',
//       };
//   return (
//     <Chart
//       chartType="PieChart"
//       data={data}
//       options={options}
//       width={"100%"}
//       height={"110%"}
//     />
//   );
// }



import React,{useContext} from "react";
import { Chart } from "react-google-charts";
import { pageTheme } from "../../../components/context/ContextProvider";
export const data = [
  ["Element", "Visitors", { role: "style" }],
  ["JAN", 8.94, "#038edc"], // RGB value
  ["FEB", 10.49, "#038edc"], // English color name
  ["MAR", 19.3, "#038edc"],
  ["APR", 6, "#038edc"],
  ["MAY", 4, "#038edc"],
  ["JUN", 10, "#038edc"],
  ["JUL", 4, "#038edc"],
  ["AUG", 7, "#038edc"],
  ["SEP", 19.3, "#038edc"],
  ["OCT", 15, "#038edc"],
  ["NOV",6, "#038edc"],
  ["DEC", 17, "#038edc"], // CSS-style declaration
];

export function MonthlyVisitsChart() {
  const { theme } = useContext(pageTheme);
   const options = {   
    backgroundColor: theme === 'dark-th' ? '#032d46' : '#fff',
  };
  return (
    <Chart chartType="ColumnChart" width="100%" height="110%" data={data} options={options} />
  );
}