import React, { useState, useEffect, useContext, useRef } from 'react';
import HeaderTop from '../../components/header/large-screen/HeaderTop';
import Footer from '../../components/footer/Footer';
import './profile.css';
import { Link, NavLink, useParams, useNavigate } from 'react-router-dom';
import { createChat, getSingleUser, getUserFollowers, userCoverImgUpdate, userFollowUnfollow } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import ProfileWorks from './view-profile/ProfileWorks';
import ProfileProjects from './view-profile/ProfileProjects';
import ProfileCollections from './view-profile/ProfileCollections';
import ProfileAbout from './view-profile/ProfileAbout';
import ProfileJobs from './view-profile/ProfileJobs';
import SmallFooter from '../../components/footer/SmallFooter';
import Sidebar from '../../components/common/Sidebar';
import { mainTheme } from '../../components/context/ContextProvider';
import { isTokenValid } from '../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';

const Profile = () => {

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const navigate = useNavigate();

  const [showOpt, setShowOpt] = useState(false);
  const showOptions = () => {
    setShowOpt(!showOpt);
  };

  const [showNav, setShowNav] = useState("works");
  const switchNav = (option) => {
    setShowNav(option);
  };

  const { username } = useParams();
  const [userData, setUserData] = useState({});

  const userProfileGet = async () => {
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setUserData(res.data);
      } else {
        console.log("Error in Fetching User Details.");
        handleUserError();
      }
    } catch (error) {
      console.error("Error in Fetching User Details:", error);
      handleUserError();
    }
  };

  const handleUserError = () => {
    navigate("*");
  };

  useEffect(() => {
    const fetchData = async () => {
      await userProfileGet();
    };

    fetchData();
  }, [username]);

  useEffect(() => {
    if (!userData) {
      handleUserError();
    }
  }, [userData]);


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logOutBtn = () => {
    if (isValid) {
      if (window.confirm("Are you sure you want to log Out?")) {
        sessionStorage.clear();
        navigate("/");
      }
    }
  }

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const [coverImg, setCoverImg] = useState('');
  const [showImg, setShowImg] = useState('');
  const [imgSelected, setImgSelected] = useState(false);

  const handleSelectImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImg(URL.createObjectURL(selectedFile));
        setCoverImg(selectedFile);
        setImgSelected(true);
      } else {
        toast.error("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  const user_id = sessionStorage.getItem("user_id");
  const logged_username = sessionStorage.getItem("user_username");

  const handleSaveImg = async () => {
    if (user_id === "") { toast.error("Sorry some data is Missing") }
    else {
      const data = new FormData();
      data.append("id", user_id)
      data.append("cover_img", coverImg)
      const config = {
        "Content-Type": "multipart/form-data"
      }
      const res = await userCoverImgUpdate(data, config);
      if (res.status === 200) {
        userProfileGet();
        toast.success("Successfully Updated Profile");
        setImgSelected(false);
      }
      else {
        toast.error("Sorry Unable to Updated Profile");
      }
    }
  }

  const openChat = async () => {
    if (isValid === true) {
        const data = { sender: logged_username, receiver: username };
        try {
            const config = { 'Content-Type': 'application/json', };
            const res = await createChat(data, config);
            if (res.status === 200) {
                navigate("/message");
            }
        } catch (error) {
            console.error('Error:', error.response.data);
        }
    }
    else {
        toast.error('Please Login First', {
            onClose: () => navigate('/login')
        });
    }
}

const [followers, setFollowers] = useState([]);
  const fetchFollowers = async () => {
    try {
      const res = await getUserFollowers(username);
      if (res.status === 200) {
        setFollowers(res.data.followers);
      } else {
        console.log('Error in Fetching Followers.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

const handleFollow = async () => {
  if (isValid === true) {
    const data = { user_id: userData._id, follower_id: user_id };
    try {
      const config = { 'Content-Type': 'application/json', };
      const res = await userFollowUnfollow(data, config);
      if (res.status === 200) {
        fetchFollowers();
      }
    } catch (error) {
      console.error('Error:', error.response.data);
    }
  } else {
    // alert("Please Login First");
    toast.error("Please Login First");
  }
};

  const { pageTheme, setPageTheme } = useContext(mainTheme);
  return (
    <>
      {isValid && !isSmallScreen ? (<Sidebar />) : ('')}
      {userData ? (
        <>
          {isSmallScreen ? ("") : (<HeaderTop />)}
          <div className="container-fluid" id="profile-page" style={isValid ? ({}) : ({ "marginTop": "60px" })}>
            <div className="row">

              <div className="large-profile-top">
                <div className="profile-banner">
                  {showImg ? (<img src={showImg} />) : (<>
                    {userData.cover_img ? (<img src={`${BASE_URL}/uploads/users-cover-img/${userData.cover_img}`} />
                    ) : (
                      pageTheme === "dark_th" ? (<img src="assets/images/profile-page/cover-img-dark.jpg" />
                      ) : (
                        <img src="assets/images/profile-page/cover-img-light.jpg" />))}
                  </>)}
                </div>
                <div className="profile-head">
                  <div className="left-head col-2">
                    <Link to={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`}><img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} width="120px" height="120px" className='rounded-circle' /></Link>
                  </div>
                  <div className="right-head col-6">
                    <div className="row">
                      <div className="col-12"><div className='user-title'>{userData.fname + " " + userData.lname}</div></div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className='user-options'>
                        {isValid && (logged_username === username) ? (
                          <>
                            {showImg && imgSelected ? (<>
                              <div className='edit-profile-btn'>
                                <button type='button' onClick={() => setShowImg('')}><i class="fa-solid fa-xmark" style={{"color":"red"}}></i>&nbsp; Cancel</button>
                              </div>
                              <div className='edit-profile-btn'>
                                <button type='button' onClick={handleSaveImg}><i class="fa-solid fa-check" style={{"color":"green"}}></i>&nbsp; Save</button>
                              </div>
                            </>) : (<>
                              <div className='edit-profile-btn'>
                                <input type="file" style={{ "display": "none" }} onChange={handleSelectImg} ref={fileInputRef} />
                                <button type='button' onClick={handleButtonClick}><i class="fa-solid fa-camera"></i>&nbsp; Edit Cover Photo</button>
                              </div>
                            </>)}
                          </>
                        ) : (
                          <div className='edit-profile-btn'><Link to='/signup'>Get In Touch</Link>
                          </div>)}
                        {isValid && (logged_username === username) ? (<>
                          {/* <div><button className='options-btn' onClick={showOptions}><i class="fa-solid fa-ellipsis"></i></button></div>
                          <div className={showOpt ? 'dropdown-opt' : 'dropdown-opt d-none'}>
                            <Link style={{ "textDecoration": "none" }} to="/edit-profile"><p>Edit your account settings</p></Link>
                            <p>Edit work preferences</p>
                          </div> */}
                          <div><button className='options-btn' onClick={()=>navigate("/edit-profile")}><i class="fa-solid fa-gear" style={{ "fontSize": "22px" }}></i></button></div>
                          <div><button className='options-btn' onClick={logOutBtn}><i class="fa-solid fa-power-off" style={{ "color": "red", "fontSize": "22px"  }}></i></button></div>
                        </>) : ('')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
{/* <------------------------------------- SMALL PROFILE TOP START ------------------------------------->*/}
              <div className="small-profile-top">
                <div className="profile-banner">
                  {showImg ? (<img src={showImg} />) : (<>
                    {userData.cover_img ? (<img src={`${BASE_URL}/uploads/users-cover-img/${userData.cover_img}`} />
                    ) : (
                      pageTheme === "dark_th" ? (<img src="assets/images/profile-page/cover-img-dark.jpg" />
                      ) : (
                        <img src="assets/images/profile-page/cover-img-light.jpg" />))}
                  </>)}
                </div>
                <div className="profile-head">
                  <div className="left-head col-2">
                    <Link to={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`}><img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} width="120px" height="120px" className='rounded-circle' /></Link>
                  </div>
                  <div className="right-head col-6">
                    <div className="row">
                      <div className="col-12"><div className='user-title'>{userData.fname + " " + userData.lname}</div></div>
                      <div className="col-12"><div className='user-username'>{userData.username}</div></div>
                    </div>
                  </div>
                  <div className="col-4 right2-head">
                    <div className='user-options'>
                      {isValid && (logged_username === username) ? (
                        <>
                          {/* <div className='edit-profile-btn'>
                            <div><button type='button' onClick={handleButtonClick}><i class="fa-solid fa-camera"></i>&nbsp; Edit Cover Photo</button></div>
                          </div> */}
                           {showImg && imgSelected ? (<>
                              <div className='edit-profile-btn'>
                                <button type='button' onClick={() => setShowImg('')}><i class="fa-solid fa-xmark" style={{"color":"red"}}></i>&nbsp; Cancel</button>
                              </div><div className='edit-profile-btn'>
                                <button type='button' onClick={handleSaveImg}><i class="fa-solid fa-check" style={{"color":"green"}}></i>&nbsp; Save</button>
                              </div>
                            </>) : (<>
                              <div className='edit-profile-btn'>
                                <input type="file" style={{ "display": "none" }} onChange={handleSelectImg} ref={fileInputRef} />
                                <button type='button' onClick={handleButtonClick}><i class="fa-solid fa-camera"></i>&nbsp; Edit Cover Photo</button>
                              </div>
                            </>)}
                        </>
                      ) : (
                        <>
                        <div className='edit-profile-btn'><button onClick={()=> openChat()}>Get In Touch</button></div>
                        <div className='edit-profile-btn'>
                        <button onClick={()=> handleFollow()}>
                        {followers.length > 0 ?
                          (followers.some((follow) => follow.follower_id === user_id) ?
                            (<><i className="fa-solid fa-check"></i> &nbsp;Following</>) :
                            (<><i className="fa-solid fa-plus"></i>  &nbsp;Follow</>)) :
                          (<><i className="fa-solid fa-plus"></i>  &nbsp;Follow</>)
                        }
                        </button></div>
                        </>
                      )}
                      {isValid && (logged_username === username) ? (<>
                        {/* <div><button className='options-btn' onClick={showOptions}><i class="fa-solid fa-ellipsis"></i></button></div>
                        <div className={showOpt ? 'dropdown-opt' : 'dropdown-opt d-none'}>
                          <Link style={{ "textDecoration": "none" }} to="/edit-profile"><p>Edit your account settings</p></Link>
                          <p>Edit work preferences</p>
                        </div> */}
                        <div><button className='options-btn' onClick={()=>navigate("/edit-profile")}><i class="fa-solid fa-gear" style={{ "fontSize": "21px" }}></i></button></div>
                        <div><button className='options-btn' onClick={logOutBtn}><i class="fa-solid fa-power-off" style={{ "color": "red", "fontSize": "21px" }}></i></button></div>
                      </>) : ('')}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="row nav-tab" style={{ "padding": "0 75px" }}>
              <div className="profile-bottom-nav">
                <div className="nav-wrap">
                  <ul class="nav nav-pills">
                    <li class="nav-item">
                      <a class={showNav == "works" ? "nav-link active" : "nav-link"} onClick={() => switchNav("works")}>Works</a>
                    </li>
                    <li class="nav-item">
                      <a class={showNav == "projects" ? "nav-link active" : "nav-link"} onClick={() => switchNav("projects")}>Projects</a>
                    </li>
                    <li class="nav-item">
                      <a class={showNav == "collections" ? "nav-link active" : "nav-link"} onClick={() => switchNav("collections")}>Collections</a>
                    </li>
                    <li class="nav-item">
                      <a class={showNav == "jobs" ? "nav-link active" : "nav-link"} onClick={() => switchNav("jobs")}>Jobs</a>
                    </li>
                    <li class="nav-item">
                      <a class={showNav == "about" ? "nav-link active" : "nav-link"} onClick={() => switchNav("about")}>About</a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className='mt-4 mb-4' />
            </div>
            <div className="row content-tab" style={{ "padding": "0 75px" }}>
              <div className="profile-bottom-content">
                {showNav === "works" ? <ProfileWorks username={username} /> : ''}
                {showNav === "projects" ? <ProfileProjects username={username} /> : ''}
                {showNav === "collections" ? <ProfileCollections username={username} /> : ''}
                {showNav === "jobs" ? <ProfileJobs username={username} /> : ''}
                {showNav === "about" ? <ProfileAbout username={username} /> : ''}
              </div>
            </div>
            <ToastContainer position="top-center" autoClose={1000} />
          </div>
          {isSmallScreen ? (<SmallFooter />) : (<Footer />)}
        </>
      ) : ("")
      }
    </>
  )
}

export default Profile