import React, { useState, useEffect } from 'react';
import { BsUpload } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getSingleJob, jobUpdate } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './job.css';

const AddJob = () => {
  const navigate = useNavigate();

  const [inputdata, setInputData] = useState({
    title: "",
    location: "",
    workplace_type: "",
    designer_type: "",
    employment_type: "",
    apply_link: "",
    company_name: "",
    website: "",
    status: ""
  });
  const [description, setDescription] = useState("");
  const [showImg, setShowImg] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");

  const { id } = useParams();
  const singleJobData = async () => {
    const response = await getSingleJob(id);
    if (response.status === 200) {
      setInputData(response.data.singleJob);
      setDescription(response.data.singleJob.description);
      setCompanyLogo(response.data.singleJob.company_logo);
    }
    else {
      console.log("Error in Fetching Job Details.");
    }
  }

  useEffect(() => {
    singleJobData();
  }, [])

  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputdata, [name]: value });
    if (name === "location") {
      setQuery(value);
    }
  }

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.trim() !== '') {
        try {
          const response = await fetch(`https://photon.komoot.io/api/?q=${query}&limit=5`);
          const data = await response.json();
          if (data.features) {
            const uniqueSuggestions = new Set();
            data.features.forEach(feature => {
              const city = feature.properties.name || '';
              const state = feature.properties.state || '';
              const country = feature.properties.country || '';
              if (state && country) {
                uniqueSuggestions.add(`${city}, ${state}, ${country}`);
              } else if (country) {
                uniqueSuggestions.add(`${city}, ${country}`);
              } else {
                uniqueSuggestions.add(city);
              }
            });
            setSuggestions(Array.from(uniqueSuggestions));
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [query]);


  const handleSuggestionClick = (suggestion) => {
    setInputData({ ...inputdata, location: suggestion });
    setQuery('');
    setSuggestions([]);
  }

  const LogoChange = (e) => {
    setShowImg(URL.createObjectURL(e.target.files[0]));
    setCompanyLogo(e.target.files[0]);
  }

  const submitJob = async (e) => {
    e.preventDefault();
    const { title, location, workplace_type, designer_type, employment_type, apply_link, company_name, website, status } = inputdata;
    if (companyLogo === "") {
      alert("Company Logo is Required");
      return;
    }

    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("location", location);
    data.append("workplace_type", workplace_type);
    data.append("designer_type", designer_type);
    data.append("employment_type", employment_type);
    data.append("apply_link", apply_link);
    data.append("company_name", company_name);
    data.append("website", website);
    data.append("company_logo", companyLogo);
    data.append("status", status);

    const config = {
      "Content-Type": "multipart/form-data"
    }

    try {
      const response = await jobUpdate(id, data, config);
      if (response.status === 200) {
        alert("Job Updated Successful");
        navigate("/admin/view-jobs");
      } else {
        alert("Job Update Unsuccessful");
      }
    } catch (error) {
      console.error('Error updating job:', error);
      alert("Job Update Unsuccessful");
    }
  }

  return (
    <>
      <AccessChecker accessKey="edit_job" />
      <div className='form-card' id="add-job">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Edit Job</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={submitJob} encType="multipart/form-data">
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Job Title</label>
                <input type="text" className="form-control" placeholder="e.g; Senior Web Designer" name="title" value={inputdata.title} onChange={handleChange} required />
              </div>
              <div className="col-lg-6 job-loc-div">
                <label>Job Location</label>
                <input type="text" className="form-control" placeholder="e.g; New York City or San Francisco" name="location" value={inputdata.location} onChange={handleChange} required autoComplete="off" />
                {
                  suggestions.length > 0 && (
                    <div className='loc-suggestion'>
                      <ul>
                        {suggestions.map((suggestion, index) => (
                          <li key={index} onClick={() => handleSuggestionClick(suggestion)}>{suggestion}</li>
                        ))}
                      </ul>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Job Description</label>
                <ReactQuill theme="snow" value={description} onChange={setDescription} />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Workplace Type</label>
                <select className="form-select form-control" name="workplace_type" value={inputdata.workplace_type} onChange={handleChange} required>
                  <option value="">----Select Workplace Type----</option>
                  <option value="remote">Remote</option>
                  <option value="on_site">On-Site</option>
                  <option value="hybrid">Hybrid</option>
                </select>
              </div>
              <div className="col-lg-6">
                <label>What type of designer are you looking for?</label>
                <select className="form-select form-control" name="designer_type" value={inputdata.designer_type} onChange={handleChange} required>
                  <option value="">---- Select Designer Type ----</option>
                  <option value="animation">Animation</option>
                  <option value="graphic_designer">Brand / Graphic Designer</option>
                  <option value="illustration">Illustration</option>
                  <option value="leadership">Leadership</option>
                  <option value="mobile_design">Mobile Design</option>
                  <option value="visual_design">UI / Visual Design</option>
                  <option value="product_design">Product Design</option>
                  <option value="ux_design">UX Design / Research</option>
                  <option value="web_design">Web Design</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Employment Type</label>
                <select className="form-select form-control" name="employment_type" value={inputdata.employment_type} onChange={handleChange} required>
                  <option value="">---- Select Employment Type ----</option>
                  <option value="fulltime">Full-time employee</option>
                  <option value="freelance">Freelance / Contract hire</option>
                </select>
              </div>
              <div className="col-lg-6">
                <label>Where can people apply?</label>
                <input type="text" className="form-control" placeholder="e.g; https://abdcompany.com/jobs-apply" name="apply_link" value={inputdata.apply_link} onChange={handleChange} required />
              </div>
            </div>
          </div>
          <div className="form-group img-upload-wrap mt-3">
            <div className="row w-100">
              <div className="col-lg-6 d-flex align-items-center justify-content-between px-5">
                <div>
                  <div className="upld-img-icon">
                    <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={!showImg ? `${BASE_URL}/uploads/jobs-img/${companyLogo}` : showImg} alt="Company Logo" /></label>
                    <input type="file" id="imageInput" accept="image/*" name="user_img" style={{ display: 'none' }} onChange={LogoChange} required />
                  </div>
                  <div className='pt-2'>Your Company Logo <br /><span style={{ "fontSize": "14px" }}>(Recommended dimensions: 150x150 px)</span></div>
                </div>
                <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><span className="upload-icon-btn"><BsUpload /></span></label>
              </div>
              <div className="col-lg-6">
                <div>
                  <label>What's your company name?</label>
                  <input type="text" className="form-control" name="company_name" value={inputdata.company_name} onChange={handleChange} required />
                </div>
                <div className="mt-5">
                  <label>Your Company Website</label>
                  <input type="text" className="form-control" placeholder="https://domain.com" name="website" value={inputdata.website} onChange={handleChange} required />
                </div>
              </div>
            </div>
          </div>
          <div className='row pt-2'>
            <div className='col-lg-6'>
              <label>Select Job Status</label>
              <select className='form-select form-control' name="status" onChange={handleChange} value={inputdata.status}>
                <option value="0"> ---- Select Job Status ----</option>
                <option value="active">Active</option>
                <option value="inactive">InActive</option>
              </select>
            </div>
            <div className='col-lg-6'>
              <div className="d-flex justify-content-end align-items-end h-100">
                <button type="submit" className="btn btn-primary" onClick={submitJob}>Update Job</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddJob;
