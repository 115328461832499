import React,{useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { getSingleUser } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import { decrypt } from '../../components/OtherFunctions/otherFunctions';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './user.css'

const ViewSingleUser=()=>{

  //<------------------- Previous User Data ------------------->
  const [userData, setUserData] = useState({_id: "", fname: "", lname: "", username:"", email:"", location: "", skills: "", intro: "", bio: "", user_img:"", status:"", password: "" })

  const {username} = useParams();

  const singleUserData = async()=>{
    const response = await getSingleUser(username);
    if(response.status===200){
      console.log(response.data)
      setUserData(response.data);
    }
    else{
      console.log("Error in Fetching User Details.");
    }
  }

  useEffect(()=>{
    singleUserData();
  },[])

    return(
        <>
        <AccessChecker accessKey="view_users" />
        <div className='form-card' id="edit-user">
            <div className="row">
                <div className="col-md-12 form-head d-flex justify-content-between">
                    <h1>View User</h1>
                    <div><Link to={`/admin/edit-user/${userData.username}`}><button className='btn btn-primary'>Edit User</button></Link></div>
                </div>
            </div>
            <hr/>


            {/* //<----------------------------------------- General Settings -----------------------------------------> */}
            <form className='form-div pt-3'>
              <div>
                <h5>General Settings</h5>
                <hr/>
              </div>
              <div class="form-group pt-3">
                <div className="row">
                    <div className="col-lg-6">
                     <label>Username</label>
                     <div class="form-control">{userData.username}</div>
                    </div>
                    <div className="col-lg-6">
                     <label>Email</label>
                     <div class="form-control">{userData.email}</div>
                    </div>
                </div>
              </div>
            </form>

            {/* //<----------------------------------------- Profile Settings -----------------------------------------> */}
            <form className='form-div'>
              <div>
                <h5>Profile Settings</h5>
                <hr/>
              </div>

              <div class="form-group img-upload-wrap pt-3">
                <div className="col-lg-6 img-div">
                <div className='upld-img-icon'>
                  <img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} />
                </div>
                </div>
                <div className="col-lg-6">
                  <div style={{"height":"195px", "display":"grid"}}>
                    <div className="w-100">
                     <label>First Name</label>
                     <div class="form-control">{userData.fname}</div>
                    </div>
                    <div className="w-100">
                     <label>Last Name</label>
                     <div class="form-control">{userData.lname}</div>
                   </div>
                 </div>
                </div>
              </div>

              <div class="form-group">
                <div className="row">
                    <div className="col-lg-6">
                     <label>Location</label>
                     <div class="form-control">{userData.location? userData.location:"------ No Location Added to Show ------"}</div>
                    </div>
                    <div className="col-lg-6">
                     <label>Skills</label>
                     <div class="form-control">{userData.skills? userData.skills:"------ No Skills Added to Show ------"}</div>
                    </div>
                </div>
              </div>

              <div class="form-group">
                <div className="row">
                    <div className="col-lg-6">
                     <label>Short Intro</label>
                     <div class="form-control">{userData.intro? userData.intro: "------ No Intro is Added to Show ------"}</div>
                    </div>
                    <div className="col-lg-6">
                     <label>Bio</label>
                     <div class="form-control">{userData.bio? userData.bio:"------ No Bio is Added to Show ------"}</div>
                    </div>
                </div>
              </div>
            </form>

            {/* //<----------------------------------------- Important Settings -----------------------------------------> */}
            <form className='form-div pt-3'>
              <div>
                <h5>Important Settings</h5>
                <hr/>
              </div>
              <div class="form-group pt-3">
                <div className="row">
                    <div className="col-lg-6">
                      <label>Status</label>
                     <div class="form-control">{userData.status}</div>
                    </div>
                    <div className="col-lg-6">
                     <label>Password</label>
                     <div class="form-control">Hidden</div>
                    </div>
                </div>
              </div>
            </form>
        </div>
        </>
    )
}

export default ViewSingleUser;