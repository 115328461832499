import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { commonSidebar, mainTheme } from '../context/ContextProvider';
import { GoHomeFill } from "react-icons/go";
import { RiEditBoxFill } from "react-icons/ri";
import { FaMoon, FaSun } from "react-icons/fa";
import { FaBrush } from "react-icons/fa6";
import { MdPalette } from "react-icons/md";
import { TbTriangleInvertedFilled, TbTriangleFilled } from "react-icons/tb";
import './sidebar.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { IoChatbubblesSharp } from "react-icons/io5";
import { BiSolidBriefcaseAlt2 } from "react-icons/bi";
import { isTokenValid } from '../functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';

const Sidebar = () => {
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);
  
  const checkUser = (nav_to) => {
    if (isValid) { 
      navigate(nav_to); 
    }
    else {
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }
  const { showMenu, setShowMenu } = useContext(commonSidebar);
  const [themeMenu, setThemeMenu] = useState(false);

  const { pageTheme, setPageTheme } = useContext(mainTheme);

  return (
    <>
      <div class="container-fluid" style={{ "marginTop": "-5px", "padding": "0" }}>
        <div class="row  d-flex" id="common-sidebar">
          <div className={`main-sidebar half-sidebar ${showMenu ? 'd-none' : ''}`}>
            <div id="sidebar-wrapper">
              <div className='icon-wrap' onClick={() => setShowMenu(true)}><div className='side-icon'><i class="fa-solid fa-bars" /></div><div className="icon-title text-center">Menu</div></div>
              <div className='icon-wrap'><NavLink to="/"><div className='side-icon'><GoHomeFill /></div><div className="icon-title text-center">Home</div></NavLink></div>
              <div className='icon-wrap'><div className='side-icon' onClick={()=>checkUser("/post")}><RiEditBoxFill /></div><div className="icon-title text-center">Create Post</div></div>
              <div className='icon-wrap'><NavLink to="/jobs"><div className='side-icon'><BiSolidBriefcaseAlt2 /></div><div className="icon-title text-center">Jobs</div></NavLink></div>
              <div className='icon-wrap'><div className='side-icon' onClick={()=>checkUser("/message")}><IoChatbubblesSharp /></div><div className="icon-title text-center">Chats</div></div>
              <DropdownButton className='theme icon-wrap' id="dropdown-button-drop-end" title={<>
                <div className='side-icon'><MdPalette /></div><div className="icon-title text-center">Theme</div>
              </>} key={'end'} drop={'end'}>
                <Dropdown.Item onClick={() => setPageTheme("dark_th")}><div className='icon-wrap'><div className='side-icon'><FaMoon /></div></div></Dropdown.Item>
                <Dropdown.Item onClick={() => setPageTheme("light_th")}><div className='icon-wrap'><div className='side-icon'><FaSun /></div></div></Dropdown.Item>
                <Dropdown.Item onClick={() => setPageTheme("blue_th")}><div className='icon-wrap'><div className='side-icon'><FaBrush /></div></div></Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className={`main-sidebar full-sidebar ${showMenu ? '' : 'd-none'}`}>
            <div id="sidebar-wrapper">
              <div className='icon-wrap'><div className='side-icon' onClick={() => setShowMenu(false)}><i class="fa-solid fa-xmark" />&nbsp;<p className="icon-title text-center">Menu</p></div></div>
              <div className='icon-wrap'><NavLink to="/"><div className='side-icon'><GoHomeFill /><p className="icon-title">Home</p></div></NavLink></div>
              <div className='icon-wrap'><div className='side-icon' onClick={checkUser}><RiEditBoxFill /><p className="icon-title">Create Post</p></div></div>
              <div className='icon-wrap'><NavLink to="/jobs"><div className='side-icon'><BiSolidBriefcaseAlt2 /><p className="icon-title">Jobs</p></div></NavLink></div>
              <div className='icon-wrap'><NavLink to="/message"><div className='side-icon'><IoChatbubblesSharp /><p className="icon-title">Chats</p></div></NavLink></div>
              <div className='icon-wrap d-flex justify-content-between align-items-center' onClick={() => setThemeMenu(!themeMenu)}><div className='side-icon'><MdPalette /><p className="icon-title">Theme</p></div>&nbsp;{themeMenu ? (<TbTriangleFilled style={{ "fontSize": "17px" }} />) : (<TbTriangleInvertedFilled style={{ "fontSize": "17px" }} />)}</div>
              <ul className={themeMenu ? ('') : ('d-none')}>
                <li><div className='icon-wrap'><div onClick={() => setPageTheme("dark_th")} href=""><div className='side-icon'><FaMoon /><p className="icon-title">Dark Theme</p></div></div></div></li>
                <li><div className='icon-wrap'><div onClick={() => setPageTheme("light_th")} href=""><div className='side-icon'><FaSun /><p className="icon-title">Light Theme</p></div></div></div></li>
                <li><div className='icon-wrap'><div onClick={() => setPageTheme("blue_th")} href=""><div className='side-icon'><FaBrush /><p className="icon-title">Blue Theme</p></div></div></div></li>
              </ul>
            </div>
          </div>
          <div className={showMenu ? 'sidebar-overlay' : 'd-none'}></div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  )
}

export default Sidebar;