import React, { useState, useEffect } from 'react';
import '../editProfile.css';
import { generalUpdate, getSingleUser } from '../../../../../services/Apis';
import { isValidUsername } from '../../../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';

const GeneralSet = () => {
  const uname = sessionStorage.getItem("user_username");

  const prev_username = sessionStorage.getItem("user_username");

  const [userData, setUserData] = useState({ _id: "", username: "", email: "" });
  const userProfileGet = async (uname) => {
    const res = await getSingleUser(uname);
    if (res.status === 200) {
      setUserData(res.data);
    } else {
      console.log("Error in Fetching User Details.");
    }
  };

  useEffect(() => {
    userProfileGet(uname);
  }, [uname]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { _id, username, email } = userData;
    if (!isValidUsername(username)) {
      // alert("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed")
      toast.error("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed")
    }
    else {
      // if (username !== prev_username) {
        try {
          const dataToSend = { id: _id, username, prev_username, email };
          const response = await generalUpdate(dataToSend);
          if (response.status === 200) {
            sessionStorage.setItem("user_username", username);
            // alert("Successfully Updated");
            toast.success("Successfully Updated");
          } else {
            // alert("Sorry Update Unsuccessful");
            toast.error("Sorry Update Unsuccessful");
          }
        } catch (error) {
          console.error("Error:", error);
          // alert("An error occurred while updating");
          toast.error("An error occurred while updating");
        }
      // }
    }
  };

  return (
    <>
      <form id="general-form" onSubmit={handleSubmit}>
        <div className="mb-4 mt-3">
          <label htmlFor="username" className="form-label">Username :</label>
          <input type="text" className="form-control" id="username" value={userData.username} onChange={(e) => setUserData({ ...userData, username: e.target.value })} required />
          <span className='message'>Your Yoeavnu URL : https://yoeavnu.com/{userData.username}</span>
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="form-label">Email :</label>
          <input type="email" className="form-control" id="email" value={userData.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} required />
        </div>
        <div className='mb-4 edit-profile-btn'>
          <button type="submit">Save Changes</button>
        </div>
      </form>
      <ToastContainer position="top-center" autoClose={1000}/>
    </>
  );
}

export default GeneralSet;
