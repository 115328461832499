import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import PlausableAnalytics from "../../components/OtherFunctions/PlausableAnalytics";
import './home.css';
import timeSvg from './svg/time.svg'
import graphUp from './svg/graph-up.svg'
import people from './svg/people.svg'
import eye from './svg/eye.svg'
import WorldMapGraph from "./WorldMapGraph";
import OrdersData from "./OrdersData";
import { Performance } from "./Charts/Perfomance";
import { DifferentStateUser } from "./Charts/DifferentStateUser";
import { MonthlyVisitsChart } from "./Charts/MonthyVisitsChart";
import AdminHomeFooter from "./AdminHomeFooter";
const Home = () => {
    const navigate = useNavigate();
    const [pageData, setPageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await PlausableAnalytics();
                setPageData(data.results);
            } catch (error) {
                setError('Error fetching data');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const options = {
        chart: {
            type: 'line',
        },
        xaxis: {
            categories: ['Pageviews', 'Visit Duration', 'Visitors', 'Bounce Rate'],
        },
    };

    const series = pageData ? [{
        name: 'Value',
        data: [
            pageData.pageviews.value,
            pageData.visit_duration.value,
            pageData.visitors.value,
            pageData.bounce_rate.value,
        ],
    }] : [];

    return (
        <>
            <div id="DashboardContainer">
                <div id="DashboardFirst">
                    <h4>Website Analytics</h4>
                    <div id="DashboardFirstContainer">
                        <div className="AnalyticCard AnalyticCard1">
                            <h4>Page Views</h4>
                            <div><span>Loading... </span><img src={eye} alt="" /></div>
                        </div>
                        <div className="AnalyticCard AnalyticCard2">
                            <h4>Visit Duration</h4>
                            <div><span>Loading... </span><img src={timeSvg} alt="" /></div>
                        </div>
                        <div className="AnalyticCard AnalyticCard3">
                            <h4>Visitors</h4>
                            <div><span>Loading... </span><img src={people} alt="" /></div>
                        </div>
                        <div className="AnalyticCard AnalyticCard4">
                            <h4>Bounce Rate</h4>
                            <div><span>Loading... </span><img src={graphUp} alt="" /></div>
                        </div>
                    </div>
                </div>
                <div id="DashboardSecond">
                    <div id="DashboardSecond1">
                        <div className="DashboardSecond1Card">
                           <h4>Users Monthly Views</h4>
                            <div>
                            <MonthlyVisitsChart/>
                            </div>
                        </div>
                        <div className="DashboardSecond1Card">
                            <h4>Company Performance</h4>
                            <div>
                            <Performance  />
                            </div>
                        </div>
                    </div>
                    <div id="DashboardSecond2">
                        <h4>Visiters from different States</h4>
                        <div>
                        <DifferentStateUser />
                        </div>
                    </div>
                </div>
                <div id="DashboardThird">
                    <OrdersData />
                    <WorldMapGraph />
                </div>
            </div>
            <div >
            <AdminHomeFooter/>
            </div>
        </>
    );
};

export default Home;
