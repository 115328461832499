import React, { useState, useEffect } from 'react';
import { BsUpload } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { addPost, getAllCats, getAllTags } from '../../../services/Apis';
import img_icon from '../../assets/images/img-icon.png';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './post.css';

const AddAdmin = () => {
  const navigate = useNavigate();

  const [showImg, setShowImg] = useState(img_icon);
  const [postImg, setPostImg] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [inputTags, setInputTags] = useState([]);
  const [postCat, setPostCat] = useState([]);
  const [postTags, setPostTags] = useState([]);
  const username = sessionStorage.getItem("admin_username");
  const user_id = sessionStorage.getItem("admin_id");

  const fetchAllCategory = async () => {
    const response = await getAllCats();
    if (response.status === 200) {
      setPostCat(response.data);
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  const fetchAllTags = async () => {
    const response = await getAllTags();
    if (response.status === 200) {
      setPostTags(response.data);
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  useEffect(() => {
    fetchAllCategory();
    fetchAllTags();
  }, []);

  const selectImg = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImg(URL.createObjectURL(selectedFile));
        setPostImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  const handleTagClick = (tag) => {
    if (tag.trim() === '') return; // Ignore empty tags
    let updatedTags;
    if (inputTags.includes(tag)) {
      updatedTags = inputTags.filter(item => item.trim() !== tag.trim());
    } else {
      updatedTags = [...inputTags.filter(item => item.trim() !== ''), tag.trim()];
    }

    setInputTags(updatedTags);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("post_img", postImg);
    formData.append("title", title);
    formData.append("category", category);
    formData.append("description", description);
    formData.append("tags", inputTags.join(", "));
    formData.append("user_id", user_id);
    formData.append("likes", 0);
    formData.append("comments", 0);
    formData.append("status", "active");

    if (!postImg) {
      alert("Please Select Image");
    } else {
      const config = {
        "Content-Type": "multipart/form-data"
      };

      const response = await addPost(formData, config);
      if (response.status === 200) {
        alert("Post Added Successfully");
        // navigate("/admin/view-posts");
      } else {
        alert("Sorry Registration Unsuccessful");
      }
    }
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  return (
    <>
      <AccessChecker accessKey="add_post" />
      <div className='form-card' id="add-post">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Add Post</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-group img-upload-wrap">
            <div className='upld-img-icon'>
              <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={showImg} alt="Post Image" /></label>
              <input type="file" id="imageInput" accept="image/*" name="post_img" style={{ display: 'none' }} onChange={selectImg} />
            </div>
            <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><span className='upload-icon-btn'><BsUpload /></span></label>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Title</label>
                <input type="text" className="form-control" placeholder="Enter your Post Title" name="title" onChange={(e) => setTitle(e.target.value)} required />
              </div>
              <div className="col-lg-6">
                <label>Category</label>
                <select name="category" onChange={(e) => setCategory(e.target.value)} className="form-control" required>
                  <option value="0">Select your Post Category</option>
                  {postCat.map((post, index) => (
                    <option key={index} value={post.cat_title}>{post.cat_title}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Tags</label>
                <input type="text" className="form-control" placeholder="Enter your Post Tags" name="tags" value={inputTags.join(', ')} onChange={(e) => setInputTags(e.target.value.split(',').map(tag => tag.trim()))} required />
              </div>
            </div>
          </div>
          <div className="row data-row">
            <div className="col-lg-12">
              <label>Previous Tags</label>
              <div className='form-control data-div mt-2'>
                {postTags.length === 0 ? (
                  <div className='options bg-secondary'>-------- No Tags to Show --------</div>
                ) : (
                  postTags.map((tag, index) => (
                    <div className='options bg-primary' key={index}>
                      {tag.tag}
                      <button className={`btn btn-sm delete-btn ${inputTags.includes(tag.tag) ? "bg-danger" : "bg-success"}`} type='button' onClick={() => handleTagClick(tag.tag)}>
                        {inputTags.includes(tag.tag) ? (<i className='fa-solid fa-trash'></i>) : (<i className='fa-solid fa-plus'></i>)}
                      </button>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Description</label>
                <textarea className="form-control" placeholder="Write something about your Post" name="desc" onChange={(e) => setDescription(e.target.value)} rows="3" required></textarea>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end pt-2'>
            <button type="button" className="btn btn-danger submit-btn" onClick={refreshPage}>Reset Form</button>
            <span className='px-3'></span>
            <button type="submit" className="btn btn-primary submit-btn">Add User</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddAdmin;
