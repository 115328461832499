import React, { useContext, useEffect, useState } from 'react';
import './footer.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { BiLogIn } from "react-icons/bi";
import { isTokenValid } from '../functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';
import { BiSolidHomeAlt2 } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { TbBriefcase2Filled } from "react-icons/tb";
import { FaPlus } from "react-icons/fa6";
import { mainTheme } from '../context/ContextProvider';

const SmallFooter = () => {
  const navigate = useNavigate();

  const username = sessionStorage.getItem("user_username") || "";
  
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const checkLogin =()=>{
    if(isValid){
      navigate("/post");
    }
    else{
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }

  const {pageTheme, setPageTheme} = useContext(mainTheme);
  return (
    <>
      <div className='small-footer-gap' style={{ "height": "75px"}}></div>
      <div className="container-fluid" id="small-footer">
        <ul>
          <li><NavLink to="/"><BiSolidHomeAlt2 /></NavLink></li>
          <li><NavLink to="/search"><FaSearch /></NavLink></li>
          <li><div className='small-icon post-icon' onClick={checkLogin}><FaPlus /></div></li>
          <li><NavLink to="/jobs"><TbBriefcase2Filled /></NavLink></li>
          {username ? (
            <li>
              <NavLink to={`/${username}`} >
              <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg" className='profile-svg'>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.66667 5.625C7.66667 4.16631 8.28125 2.76736 9.37521 1.73591C10.4692 0.704462 11.9529 0.125 13.5 0.125C15.0471 0.125 16.5308 0.704462 17.6248 1.73591C18.7188 2.76736 19.3333 4.16631 19.3333 5.625C19.3333 7.08369 18.7188 8.48264 17.6248 9.51409C16.5308 10.5455 15.0471 11.125 13.5 11.125C11.9529 11.125 10.4692 10.5455 9.37521 9.51409C8.28125 8.48264 7.66667 7.08369 7.66667 5.625ZM7.66667 13.875C5.7328 13.875 3.87813 14.5993 2.51068 15.8886C1.14323 17.178 0.375 18.9266 0.375 20.75C0.375 21.844 0.835936 22.8932 1.65641 23.6668C2.47688 24.4404 3.58968 24.875 4.75 24.875H22.25C23.4103 24.875 24.5231 24.4404 25.3436 23.6668C26.1641 22.8932 26.625 21.844 26.625 20.75C26.625 18.9266 25.8568 17.178 24.4893 15.8886C23.1219 14.5993 21.2672 13.875 19.3333 13.875H7.66667Z" 
                fill={(pageTheme === "dark_th") || (pageTheme === "blue_th")? "#fff":"#000"}/>
              </svg>
              </NavLink></li>
          ) : (
            <li><NavLink to="/login" style={{ "fontSize": "33px" }}><BiLogIn /></NavLink></li>
          )}
        </ul>
      </div>
      <ToastContainer position="top-center" autoClose={1000}/>
    </>
  )
}

export default SmallFooter;
