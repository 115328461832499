import React, { useState, useEffect } from 'react';
import { BsUpload } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import { generalUpdate, getSingleUser, passwordUpdate, profileUpdate } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import { decrypt, isValidUsername } from '../../components/OtherFunctions/otherFunctions';
import './user.css'

const EditUser = () => {

  let history = useNavigate();
  const [changePass, setChangePass] = useState(false);

  //<------------------- Previous User Data ------------------->
  const [genData, setGenData] = useState({ _id: "", username: "", email: "" });
  const [profileData, setProfileData] = useState({ _id: "", fname: "", lname: "", location: "", skills: "", intro: "", bio: "" });
  const [impData, setImpData] = useState({ _id: "", status: "", password: "" });

  const [user_img, setUserImg] = useState("");
  const [showImage, setShowImage] = useState("");

  const { username } = useParams();
  const prev_username = username;

  const singleUserData = async () => {
    const response = await getSingleUser(username);
    if (response.status === 200) {
      console.log(response.data)
      setGenData(response.data);
      setProfileData(response.data);
      setImpData(response.data);
      setUserImg(response.data.user_img);
    }
    else {
      console.log("Error in Fetching User Details.");
    }
  }

  useEffect(() => {
    singleUserData();
  }, [])

  //<------------------- Handle General Data Change ------------------->
  const changeGenData = (e) => {
    const { name, value } = e.target;
    setGenData({ ...genData, [name]: value });
  }

  const handleGeneral = async (e) => {
    e.preventDefault();
    const { _id, username, email } = genData;

    if (!username) { alert("Username cannot be Empty"); } 
    else if(!isValidUsername(username)) {
      alert("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed")
    }
    else if (!email) { alert("Email cannot be Empty"); }
    else {
      try {
        const dataToSend = { id: _id, username, prev_username, email };
        const response = await generalUpdate(dataToSend);
        if (response.status === 200) {
          alert("Successfully Updated");
          history(`/admin/edit-user/${username}`);
        } else {
          alert("Sorry Update Unsuccessful");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred while updating");
      }
    }
  };

  //<------------------- Handle Important Data Change  ------------------->
  const changeImpData = (e) => {
    const { name, value } = e.target;
    setImpData({ ...impData, [name]: value });
  }

  const handleImpChange = async (e) => {
    e.preventDefault();
    const { _id, password, status } = impData;
    try {
      const config = { "Content-Type": "application/json" }
      const dataToSend = { id: _id, password, status };
      const response = await passwordUpdate(dataToSend, config);
      if (response.status === 200) {
        setChangePass(false);
        alert("Successfully Updated");
        singleUserData();
      } else {
        alert("Sorry Update Unsuccessful");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while updating");
    }
  }

  //<------------------- Select Image and Show Selected Image ------------------->
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImage(URL.createObjectURL(selectedFile));
        setUserImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  //<------------------- Submit Form ------------------->
  const changeProfileData = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  }

  const handleProfile = async (e) => {
    e.preventDefault();
    const { _id, fname, lname, location, skills, intro, bio } = profileData;
    const data = new FormData();
    data.append("id", _id)
    data.append("fname", fname)
    data.append("lname", lname)
    data.append("location", location)
    data.append("skills", skills)
    data.append("intro", intro)
    data.append("bio", bio)
    data.append("user_img", user_img)
    const config = {
      "Content-Type": "multipart/form-data"
    }
    const res = await profileUpdate(data, config);
    if (res.status === 200) {
      alert("Successfully Updated Profile");
      singleUserData();
    }
    else {
      alert("Sorry Unable to Updated Profile");
    }
  }

  return (
    <>
      <AccessChecker accessKey="edit_user" />
      <div className='form-card' id="edit-user">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Edit Users</h1>
          </div>
        </div>
        <hr />


        {/* //<----------------------------------------- General Settings -----------------------------------------> */}
        <form className='form-div pt-3' onSubmit={handleGeneral}>
          <div>
            <h5>General Settings</h5>
            <hr />
          </div>
          <div class="form-group pt-3">
            <div className="row">
              <div className="col-lg-5">
                <label>Username</label>
                <input type="text" class="form-control" name="username" value={genData.username} onChange={changeGenData} required />
              </div>
              <div className="col-lg-5">
                <label>Email</label>
                <input type="email" class="form-control" name="email" value={genData.email} onChange={changeGenData} required />
              </div>
              <div className="col-lg-2 d-flex justify-content-end align-items-end"><button className='btn btn-primary'>Update General</button></div>
            </div>
          </div>
        </form>

        {/* //<----------------------------------------- Profile Settings -----------------------------------------> */}
        <form className='form-div' onSubmit={handleProfile}>
          <div>
            <h5>Profile Settings</h5>
            <hr />
          </div>

          <div class="form-group img-upload-wrap pt-3">
            <div className="col-lg-6 img-div">
              <div className='upld-img-icon'>
                <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={!showImage ? `${BASE_URL}/uploads/admins-users-img/${user_img}` : showImage} /></label>
                <input type="file" id="imageInput" accept="image/*" name="user_img" style={{ display: 'none' }} onChange={handleChange} />
              </div>
              <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><span className='upload-icon-btn'><BsUpload /></span></label>
            </div>
            <div className="col-lg-6">
              <div style={{ "height": "195px", "display": "grid" }}>
                <div className="w-100">
                  <label>First Name</label>
                  <input type="text" class="form-control" value={profileData.fname} name="fname" onChange={changeProfileData} required />
                </div>
                <div className="w-100">
                  <label>Last Name</label>
                  <input type="text" class="form-control" value={profileData.lname} name="lname" onChange={changeProfileData} required />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Location</label>
                <input type="text" class="form-control" name="location" value={profileData.location} onChange={changeProfileData} placeholder="e.g; Mumbai, Maharashtra" />
              </div>
              <div className="col-lg-6">
                <label>Skills</label>
                <input type="text" class="form-control" name="skills" value={profileData.skills} onChange={changeProfileData} placeholder="e.g; Artist, Coding, Painiting" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Short Intro</label>
                <textarea type="text" class="form-control" value={profileData.intro} name="intro" onChange={changeProfileData} rows="3" placeholder="e.g;  I am ....." ></textarea>
              </div>
              <div className="col-lg-6">
                <label>Bio</label>
                <textarea type="text" class="form-control" value={profileData.bio} name="bio" onChange={changeProfileData} rows="3" placeholder="e.g;  My Portfolio consists...."></textarea>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-end align-items-end">
                <button type="submit" class="btn btn-primary">Update Profile</button>
              </div>
            </div>
          </div>
        </form>

        {/* //<----------------------------------------- Important Settings -----------------------------------------> */}
        <form className='form-div pt-3' onSubmit={handleImpChange}>
          <div>
            <h5>Important Settings</h5>
            <hr />
          </div>
          <div class="form-group pt-3">
            <div className="row">
              <div className="col-lg-5">
                <label>Status</label>
                <select name="status" className="form-control" value={impData.status} onChange={changeImpData} required>
                  <option value="0">Select Admin Role</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                </select>
              </div>
              {changePass ? (
                <div className="col-lg-5">
                  <label>Password</label>
                  <input type="text" name="password" class="form-control" onChange={changeImpData} required />
                </div>
              ) : (
                <div className="col-lg-5">
                  <label>Old Password</label>
                  <div class="form-control old-pass" >{decrypt(impData.password)}<button className='btn btn-sm btn-success' type="button" onClick={() => setChangePass(true)}><i class="fa-solid fa-pen-to-square"></i></button></div>
                </div>
              )}
              <div className="col-lg-2 d-flex justify-content-end align-items-end"><button className='btn btn-primary' type='submit'>Save Changes</button></div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditUser;