import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import UserContextProvider from './user_site/components/context/ContextProvider.jsx';
import AdminContextProvider from './admin_site/components/context/ContextProvider.jsx';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <UserContextProvider>
      <AdminContextProvider>
        <App />
      </AdminContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
)