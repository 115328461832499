import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import { signUpFunc } from '../../../services/Apis';
import { isValidUsername } from '../../components/OtherFunctions/otherFunctions';
import './user.css'

const AddAdmin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    username: '',
    email: '',
    password: '',
    user_img: 'default-avatar',
    status: 'active'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = { "Content-Type": "application/json" }
    if (!isValidUsername(formData.username)) {
      alert("Please Enter Valid Username. It must contain min-3, max-30 letters and should contain an alphabet no space allowed")
    }
    else {
      const res = await signUpFunc(formData, config);
      if (res.status === 200) {
        alert("User Created Successfully");
        navigate("/admin/view-user");
      } else {
        if (res.response.data.message) {
          alert(res.response.data.message)
        } else {
          alert("Some Unknown Error Occurred")
        }
      }
    }
  };

  const refreshPage = () => {
    window.location.reload(false);
  }
  return (
    <>
      <AccessChecker accessKey="add_user" />
      <div className='form-card'>
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Add User</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={handleSubmit}>
          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>First Name</label>
                <input type="text" class="form-control" placeholder="Enter First Name" name="fname" onChange={handleChange} required />
              </div>
              <div className="col-lg-6">
                <label>Last Name</label>
                <input type="text" class="form-control" placeholder="Enter Last Name" name="lname" onChange={handleChange} required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Username</label>
                <input type="text" class="form-control" placeholder="Enter Username" name="username" onChange={handleChange} required />
              </div>
              <div className="col-lg-6">
                <label>Email</label>
                <input type="text" class="form-control" placeholder="Enter User Email" name="email" onChange={handleChange} required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Password</label>
                <input type="text" class="form-control" placeholder="Enter Password" name="password" onChange={handleChange} required />
              </div>
              <div className="col-lg-6  d-flex justify-content-end align-items-end">
                <button type="button" class="btn btn-danger" onClick={refreshPage}>Reset Form</button>
                <span className='px-3'></span>
                <button type="submit" class="btn btn-primary">Add User</button>
              </div>
            </div>
          </div>



        </form>
      </div>
    </>
  )
}

export default AddAdmin;