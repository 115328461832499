import React,{useContext} from "react";
import { Chart } from "react-google-charts";
import { pageTheme } from "../../../components/context/ContextProvider";


export const data = [
  ["Country", "Popularity"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["RU", 700],
];

export function WorldAlytics() {
  const { theme } = useContext(pageTheme);
   const options = {
    backgroundColor: theme === 'dark-th' ? '#032d46' : '#fff',
  }
  return (
    <Chart
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
            console.log("Selected : " + region);
          },
        },
      ]}
      chartType="GeoChart"
      width="100%"
      height="100%"
      data={data}
      options={options}
    />
  );
}
