import React from 'react';
import { exportUserData } from '../../../../../services/Apis';

const ExportUserData = () => {
    const user_id = sessionStorage.getItem("user_id");

    const exportUser = async () => {
        if (!user_id) {
            alert("Please Login");
            return;
        }

        try {
            const response = await exportUserData(user_id);
            if (response.status === 200) {
                const downloadUrl = response.data.downloadUrl;
                if (downloadUrl) {
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    const filename = downloadUrl.split('/').pop(); // Extract the filename from the URL
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    alert("Download URL is empty.");
                }
            } else {
                alert("Cannot Export User Data");
            }
        } catch (error) {
            console.error("Error exporting user data:", error);
            alert("An error occurred while exporting user data");
        }
    };

    return (
        <div id="export-user">
            <div className="get-data">
                <div className="head">Download your Yoeavnu Data</div>
                <hr />
                <div className="info">As we support your Privacy. Your privacy matters to us hence here is the feature where you can Download your latest data from Yoeavnu. This data will include your profile and shot information which you gave us and is currently present at your Profile.</div>
                {/* <div className="btn-div"><button>Request Data</button></div> */}
                <div className="btn-div export-btn"><button type='button' onClick={exportUser}>Download</button></div>
            </div>
        </div>
    );
};

export default ExportUserData;
