import React, { useState, useEffect } from 'react';
import { getUserCollections } from '../../../../services/Apis';
import { NavLink } from 'react-router-dom';
import { BASE_URL } from '../../../../services/helper';

const ProfileCollections = ({ username }) => {
  const [collections, setCollections] = useState([]);
  const [loadingColl, setLoadingColl]=useState(true);
  const fetchCollections = async (username) => {
    try {
      const res = await getUserCollections(username);
      if (res.status === 200) {
        setCollections(res.data);
      } else {
        console.log('Error in Fetching Collections.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    finally{
      setTimeout(() => {
        setLoadingColl(false);
      }, 200);
    }
  };

  useEffect(() => {
    fetchCollections(username);
  }, [username]);

  return (
    <>
      <div className='col-12'>
        <div id="profile-works">
          <div className="row d-flex justify-content-center">
            {loadingColl?(<div>Loading Collections ...</div>):
            collections.length > 0 ? collections.map((coll, index) => {
              return (
                <>
                  <div className="col-lg-3 col-sm-6 content" style={{ "padding": "0", "marginBottom": "20px" }}>
                    <div class="">
                      <NavLink to={`/view-post/${coll.username}/${coll.post_id}`}>
                        <div class="content-overlay"></div>
                        <img class="content-image" src={`${BASE_URL}/uploads/posts-img/${coll.post_img}`} alt="" />
                        <div class="content-details fadeIn-bottom">
                          <h3 class="content-title">{coll.post_title}</h3>
                          <p class="content-text">
                            <span className='img-icon'><i class="fa-solid fa-heart"></i> <span className='count'>250</span></span><span className='img-icon'><i class="fa-solid fa-comment"></i> <span className='count'>250</span></span><span className='img-icon'><i class="fa-solid fa-retweet"></i> <span className='count'>250</span></span>
                          </p>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </>)
            }) :
              (<div className='col-12 d-flex justify-content-center'>
                <div>
                  <img src="assets/images/no-data.png" width="300px" />
                  <h4 className='text-center mt-2 text'>No Collections Saved</h4>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCollections;