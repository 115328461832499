import React, { useState, useEffect } from 'react';
import { getAllJobs } from '../../../../services/Apis';
import { BASE_URL } from '../../../../services/helper';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const JobsPosted = ({ username, checkUser }) => {
  const navigate = useNavigate();
  const [allJobs, setAllJobs] = useState([]);

  const fetchJobs = async () => {
    try {
      const res = await getAllJobs();
      if (res.status === 200) {
        setAllJobs(res.data);
      } else {
        console.log('Error in Fetching Jobs.');
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);
  
  const applyJob = () => {
    if (username === checkUser) { navigate("/post-job"); }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }
  const viewJobClick = (id) => {
    if (username === checkUser) {
      navigate(`/view-job/${id}`);
    }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }

  return (
    <>
      <div class="view-jobs">
        <h1 className='recent-jobs' style={{ "margin": "5px 0" }}>Jobs Posted</h1><span className='small-screen-msg'>(Click on Jobs to apply)</span>
        {allJobs.length > 0 ? allJobs.map((element, index) => {
          return (<>
            <div class="job-list d-flex justify-content-between mt-2 mb-4">
              <div class="job-deatils-1 d-flex">
                <div class="comp-img"><img src={`${BASE_URL}/uploads/jobs-img/${element.company_logo}`} /></div>
                <div class="comp-name"><p>{element.company_name}</p><h2>{element.title}</h2></div>
              </div>
              <div class="job-deatils-2">
                <div class="more-details">
                  <p style={{ "fontSize": "17px", "color": "#3d3d4e" }} class="text-center"><i class="fa-sharp fa-solid fa-location-dot" style={{ "paddingRight": "8px" }}></i>{element.workplace_type}</p>
                  <p style={{ "fontSize": "14px", "color": "#6e6d7a" }}>Posted about 2 hours ago</p>
                </div>
                <div class="apply-btn">
                  <a href="" onClick={() => viewJobClick(element._id)}>View Job</a>
                  {
                    username === checkUser ? (
                      element.apply_link.includes('http://') || element.apply_link.includes('https://') ? (
                        <a style={{ "marginLeft": "10px" }} href={`${element.apply_link}`} target="_blank">Apply Job</a>
                      ) : (
                        <a style={{ "marginLeft": "10px" }} href={`http://${element.apply_link}`} target="_blank">Apply Job</a>
                      )
                    ) : (
                      <a style={{ "marginLeft": "10px" }} href="" onClick={applyJob}>Apply Job</a>
                    )
                  }

                </div>
              </div>
            </div>
          </>)
        }) : ""}
      </div>
      <ToastContainer position="top-center" autoClose={1000}/>
    </>
  )
}

export default JobsPosted