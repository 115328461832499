import React from 'react'

function AdminHomeFooter() {
  return (
    <div id="AdminHomeFooter">
        <span >2024 © Youeanu.</span>
    </div>
  )
}

export default AdminHomeFooter