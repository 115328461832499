import React, { useState, useEffect, useContext } from 'react';
import LContent from './LContent';
import Header from '../../../components/header/large-screen/Header';
import Footer from '../../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { headerMenu, mainTheme } from '../../../components/context/ContextProvider';
import HomeSidebar from './HomeSidebar';
import { isTokenValid } from '../../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LHomeContent = () => {
  const navigate = useNavigate();
  
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const checkAndNavigate = (navigateTo) => {
    if (isValid) { navigate(navigateTo); }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }
  const { showMenu, setShowMenu } = useContext(headerMenu);
  const [themeMenu, setThemeMenu] = useState(false);

  const { pageTheme, setPageTheme } = useContext(mainTheme);

  return (
    <>
      <Header />
      <div className="container-fluid" id="home1">
        <div className="row d-flex">
          <HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate} pageTheme={pageTheme} setPageTheme={setPageTheme} themeMenu={themeMenu} setThemeMenu={setThemeMenu} />
          <div className='main-page'>
            <div className={showMenu ? ('overlay-main') : ('d-none')}></div>
            <LContent />
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000}/>
      <Footer />
    </>
  )
}

export default LHomeContent