import React, { useEffect, useState, useContext, useRef } from 'react';
import light_logo from '../../assets/images/yoeavnu_logo.png';
import dark_logo from '../../assets/images/dark_logo.png';
import { mainTheme } from '../../components/context/ContextProvider';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiFillHome } from "react-icons/ai";
import { FaBell } from "react-icons/fa";
import { getUserMessages, sendUserMessage, getUserChats, updateMsgStatus, chatById, getSingleUser, getSingleUserUnseenNotifs, updateNotifStatus } from "../../../services/Apis";
import { BASE_URL } from '../../../services/helper';
import { formatDate2, isTokenValid } from '../../components/functions/OtherFunctions';
import { FaSearch } from "react-icons/fa";
import { Dropdown } from 'react-bootstrap';
import admin_icon from '../../assets/notif-imgs/admin.png';
import comment_icon from '../../assets/notif-imgs/comment.png';
import like_icon from '../../assets/notif-imgs/like.png';
import follower_icon from '../../assets/notif-imgs/follower.png';
import TimeAgoConverter from '../../components/functions/TimeAgoConverter';
import WordLimit from '../../components/wordLimit/WordLimit';

const LMessage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if(!valid){
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const { pageTheme, setPageTheme } = useContext(mainTheme);
  const username = sessionStorage.getItem('user_username') || "";
  const user_id = sessionStorage.getItem('user_id') || "";
  const chatMessagesRef = useRef(null); // Ref for chat messages div
  const [userChats, setUserChats] = useState([]);
  const [chatSearchKey, setChatSearchKey] = useState("");
  const [chatData, setChatData] = useState({});
  const [chatReceiver, setChatReceiver] = useState("");
  const [chatSender, setChatSender] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [fetchIntervalId, setFetchIntervalId] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  //<------------------------------------------------- To Fetch Current User Chats ------------------------------------------------->
  const fetchUserChats = async () => {
    try {
      const res = await getUserChats(username, chatSearchKey);
      if (res.status === 200) {
        setUserChats(res.data);
      } else if (res.response.status === 404) {
        console.log("status 404")
        setUserChats([]);
      } else {
        console.log('Error in Fetching Chats.');
      }
    } catch (error) {
      console.error('Error fetching User Chats:', error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchUserChats();
    }
  }, [chatSearchKey]);

  //<------------------------------------------------- To Fetch Selected single Chat Data ------------------------------------------------->
  const singleChatData = async (id) => {
    try {
      const res = await chatById(id);
      if (res.status === 200) {
        setChatData(res.data);
      } else {
        console.log('Error in Fetching Chats.');
      }
    } catch (error) {
      console.error('Error fetching User Chats:', error);
    }
  }

  //<------------------------------------------------- To Handle Chat is Opened ------------------------------------------------->
  const openChat = (chat_id, receiver, sender) => {
    if (isChatOpen) {
      setChatData({});
      setMsgs([]);
      clearInterval(fetchIntervalId);
    }
    singleChatData(chat_id);
    setIsChatOpen(true);
    setChatReceiver(receiver);
    setChatSender(sender);
    getMessages(sender, receiver);
    fetchUserChats();

    const intervalId = setInterval(() => {
      getMessages(sender, receiver);
      fetchUserChats();
    }, 2000);
    setFetchIntervalId(intervalId);
  };

  //<------------------------------------------------- To Get Message of Each Chat ------------------------------------------------->
  const getMessages = async (sender, receiver) => {
    try {
      const res = await getUserMessages(sender, receiver);
      if (res.status === 200) {
        setMsgs(res.data);
      } else {
        console.log("Error in Fetching User Messages.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  //<------------------------------------------------- To Mark Message as Seen for Each Chat ------------------------------------------------->
  const markMessageSeen = async()=>{
    if(msgs.length > 0){
      const unseenMessageIds = msgs.filter((msg) => (msg.status === 'unseen' && msg.receiver === username)).map((msg) => msg._id);
    if (unseenMessageIds.length > 0) {
      const config = { "Content-Type": "application/json" }
      const data = { messageIds: unseenMessageIds }
      await updateMsgStatus(data, config);
    }
  }
  }

  useEffect(()=>{
    markMessageSeen();
  },[msgs])

  //<------------------------------------------------- To Handle Chat is Closed ------------------------------------------------->
  const closeChat = () => {
    setIsChatOpen(false);
    setMsgs([]);
    setChatData({});
    clearInterval(fetchIntervalId);
  };

  //<------------------------------------------------- To Handle when message is sent ------------------------------------------------->
  const [text, setText] = useState("");

  const sentText = async () => {
    if (text !== "") {
      try {
        var data;
        if (username === chatSender) {
          data = { sender: chatSender, receiver: chatReceiver, content: text };
        } else {
          data = { sender: chatReceiver, receiver: chatSender, content: text };
        }
        const config = { "Content-Type": "application/json" };
        const res = await sendUserMessage(data, config);
        if (res.status === 200) {
          setText("");
          getMessages(chatSender, chatReceiver);
        } else {
          alert("Some Unknown Error Occurred");
        }
      } catch (error) {
        console.error('Error:', error.res.data);
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgs]);

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  const [userData, setUserData] = useState({});
  const userProfileGet = async () => {
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setUserData(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error in Fetching User Details:", error);
    }
  };

  useEffect(() => {
    userProfileGet();
  }, [username])

  const [notifs, setNotifs] = useState([]);
  const fetchAllNotifs = async () => {
    try {
      const res = await getSingleUserUnseenNotifs(username);
      if (res.status === 200) {
        setNotifs(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllNotifs();
      // await fetchUserChats();
    };
    fetchData(); // Call initially
    const interval = setInterval(fetchData, 1000); // Call every second
    return () => clearInterval(interval); // Cleanup
  }, [username]);

  const seenStatusChange = async (id) => {
    const response = await updateNotifStatus(id);
    if (response.status === 200) {
      fetchAllNotifs();
    }
    else {
      alert("Cannot Update Notif Status");
    }
  }

  const handleNotifClick = (notif_id, link) => {
    seenStatusChange(notif_id);
    navigate(link);
  }


  return (
    <>
      <div className="container-fluid" id="large-msg">
      {/* Page Header Start */}
        <div className="page-header">
          <div className="row">
            <NavLink to="/" className="col-lg-2 logo">
              {pageTheme == "blue_th" || pageTheme == "dark_th" ? (<img src={dark_logo} alt="Company Logo" width="150px" />) : (<img src={light_logo} alt="Company Logo" width="150px" />)}
            </NavLink>
            <div className="col-lg-10 head-nav">
              <NavLink to="/"><AiFillHome className='nav-icon' /></NavLink>
              <Dropdown className="header-notification position-relative">
                <Dropdown.Toggle className='sidebar-icon'>
                  <FaBell className='nav-icon' />
                  {notifs.length > 0 ? (<div className='new-notif-alert bg-danger'></div>) : ('')}
                </Dropdown.Toggle>
                <Dropdown.Menu className="notif-dropdown">
                  <div className='drop-head'>Notifications</div>
                  {notifs.length > 0 ? (
                    notifs.map((notif, index) => (
                      <React.Fragment key={index}>
                        <ul>
                          <li onClick={() => handleNotifClick(notif._id, notif.redirect_link)}>
                            <div className='left-notif-div'>
                              {notif.sender_type === "admin" ? (
                                <img src={admin_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                              ) : notif.sender_type === "comments" ? (
                                <img src={comment_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                              ) : notif.sender_type === "follows" ? (
                                <img src={follower_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                              ) : (
                                <img src={like_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                              )}
                            </div>
                            <div className='right-notif-div'>
                              <p>{notif.content}</p>
                              <p><TimeAgoConverter dateTime={notif.sent_date} /></p>
                            </div>
                          </li>
                        </ul>
                        <hr style={{ margin: "0" }} />
                      </React.Fragment>
                    ))
                  ) : (
                    <div className='no-new-msg'>No New Notifications</div>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <NavLink to={`/${userData.username}`}><img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} /></NavLink>
            </div>
          </div>
        </div>
        {/* Page Header End */}
        <div className="row msg-wrap">
          <div className="col-lg-3 left-div">
            <div className="head">Chats</div>
            <div className="search-div">
              {/* Search for chat */}
              <FaSearch className='search-icon' />
              <input placeholder='Search...' className='search-input' value={chatSearchKey} onChange={(e) => setChatSearchKey(e.target.value)} />
            </div>
            {userChats.length > 0 ?
              (userChats.map((ch, index) => (
                <>
                  <div className="chats-wrap" onClick={() => openChat(ch.chat._id, ch.chat.receiver, ch.chat.sender)}>
                    <div className="col-lg-3 d-flex justify-content-center">
                      {ch.chat.sender === username ? (
                        <img
                          src={`${BASE_URL}/uploads/admins-users-img/${ch.chat.receiver_img}`} alt="Receiver" onClick={ch.chat.receiver !== 'yoeavnu_admin' ? () => navigate(`/${ch.chat.receiver}`) : undefined} />
                      ) : (
                        <img src={`${BASE_URL}/uploads/admins-users-img/${ch.chat.sender_img}`} onClick={ch.chat.sender !== 'yoeavnu_admin' ? () => navigate(`/${ch.chat.sender}`) : undefined} />
                      )}
                    </div>
                    <div className="col-lg-6 d-grid">
                      <div className='user-name'>{ch.chat.sender === username ? (ch.chat.receiver) : (ch.chat.sender)}</div>
                      <div className='last-msg'>{ch.latestMsg ?  <WordLimit text={ch.latestMsg.content} limit={40} /> : ''}</div>
                    </div>
                    <div className="col-lg-3 position-relative">
                      <div className="chat-date">{formatDate2(ch.chat.chat_created)}</div>
                      {ch.latestMsg && (ch.latestMsg.status === "unseen") && (ch.latestMsg.receiver_id === user_id) && (
                        <div className="new-chat"></div>
                      )}
                    </div>
                  </div>
                  <div className='chat-separator'></div>
                </>
              ))) : (<p className='no-chats'>No Chats Found</p>)}

          </div>
          <div className="right-div col-lg-9">
            {isChatOpen ? (
              <>
                <div className="open-chat">

                  <div className="open-chat-header">
                    <div className='user-details'>
                      <div className='user-pic'>
                        {chatData.sender_id === user_id ? 
                            (<img src={`${BASE_URL}/uploads/admins-users-img/${chatData.receiver_img}`} onClick={() => navigate(`/${chatData.receiver}`)} />)
                          : (<img src={`${BASE_URL}/uploads/admins-users-img/${chatData.sender_img}`} onClick={ () => navigate(`/${chatData.sender}`)} />)
                        }
                      </div>
                      <div className="user-name">{chatReceiver === username ? (chatSender) : (chatReceiver)}</div>
                    </div>
                    <div className='close-chat'>
                      <button type='button' onClick={() => closeChat()}><i className='fa-solid fa-xmark'></i></button>
                    </div>
                  </div>

                  <div className="chat-messages" ref={chatMessagesRef}>
                    {msgs.map((msg, index) => (
                      <>
                        {msg.sender_id !== user_id ? (
                          <div className="msg-sender">
                            <div className="message">
                              {msg.content}
                            </div>
                          </div>) : (
                          <div className="msg-receiver">
                            <div className="message">
                              {msg.content}
                            </div>
                          </div>)}
                      </>))}
                  </div>

                  <div className="sent-chat">
                    <div className="chat-input"><textarea rows="2" value={text} onChange={(e) => setText(e.target.value)}></textarea></div>
                    <div className="send-btn"><button type='button' onClick={sentText}>Send</button></div>
                  </div>

                </div>
              </>) : ('')}

          </div>
        </div>
      </div>
    </>
  )
}

export default LMessage;
