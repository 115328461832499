import React, { useState, useContext, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { headerMenu, postFeaturedKey, postTagsKey } from '../../context/ContextProvider';
import { TbTriangleInvertedFilled, TbTriangleFilled } from "react-icons/tb";
import { featuredTags } from '../../../../services/Apis';
import { CustomNextArrow, CustomPrevArrow } from '../../functions/CustomArrows';

const HeaderBottom = () => {

  const [showDrop, setShowDrop] = useState(false);
  const { showMenu, setShowMenu } = useContext(headerMenu);

  const displayDrop = () => {
    setShowDrop(!showDrop);
  }

  const settings = {
    className: "slider variable-width",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    variableWidth: true,
    initialSlide: 0
  };

  const [featureTags, setFeatureTags] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFeaturedTags = async () => {
    try {
      const res = await featuredTags();
      if (res.status === 200) {
        setFeatureTags(res.data);
      } else {
        console.log("Error in Fetching Featured Tags");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFeaturedTags();
  }, [])

  const { featuredKey, setFeaturedKey } = useContext(postFeaturedKey);

  const { tagsKey, setTagsKey } = useContext(postTagsKey);

  const handleTagClick = (tag) => {
    if (tagsKey === tag) {
      setTagsKey("");
    } else {
      setTagsKey(tag);
    }
  };

  return (
    <>
      <div className="container-fluid" id="header-bottom">
        <div className="row">
          <div className="col-auto d-flex left-part">
            <div className="menu-icon">
              {showMenu ? (
                <i className="fa-solid fa-xmark" onClick={() => setShowMenu(!showMenu)}></i>
              ) : (
                <i className="fa-solid fa-bars" onClick={() => setShowMenu(!showMenu)}></i>
              )}
            </div>
            <div className="drop-wrapper">
              <div className="drop-btn">
                <button onClick={displayDrop}>
                  Featured{" "}
                  <span>
                    {showDrop ? (
                      <TbTriangleFilled className="drop-icon" />
                    ) : (
                      <TbTriangleInvertedFilled className="drop-icon" />
                    )}
                  </span>
                </button>
              </div>
              <div className={showDrop ? "drop-item" : "drop-item d-none"}>
                <ul>
                  <li onClick={() => setFeaturedKey("all")}><a>All</a></li>
                  <li onClick={() => setFeaturedKey("latest")}><a>Latest</a></li>
                  <li onClick={() => setFeaturedKey("popular")}><a>Popular</a></li>
                  <li onClick={() => setFeaturedKey("following")}><a>Following</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col p-0 right-part">
            <div className="nav-wrapper">
              {loading ? (
                <div>Loading Tags.....</div>
              ) : featureTags.length > 0 ? (
                <Slider {...settings}>
                  {featureTags.map((tag, index) => (
                    <div
                      className={`link-btn ${tagsKey === tag.tag ? "active-link-btn" : ""}`}
                      key={index}
                      onClick={() => handleTagClick(tag.tag)}
                    >
                      <a>{tag.tag}</a>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div>---- No Featured Tags to Show ---</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderBottom;