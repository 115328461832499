import React, { useEffect, useState } from 'react';
import './deleteUser.css';
import sorrow_img from './sorrow.gif';
import { userDelete } from '../../../services/Apis';
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from '../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ConfirmDeleteUser = () => {
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      } finally {
        setIsLoading(false);
      }
    };
    checkTokenValidity();
  }, []);

  let user_id = sessionStorage.getItem("user_id");

  useEffect(() => {
    if (!isLoading && !isValid) {
      // alert("Please Login");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }, [isLoading, isValid, navigate]);

  const handleCancelBtn = () => {
    // alert("Thank You !! for changing your Decision 😊");
    // navigate("/");
    toast.success('Thank You !! for changing your Decision 😊', {
      onClose: () => navigate('/')
    });
  }

  const handleDeleteUser = async () => {
    if (user_id && isValid) {
      try {
        const response = await userDelete(user_id);
        if (response.status === 200) {
          // alert("Account Deleted Successfully");
          sessionStorage.clear();
          navigate("/");
          // toast.success('Account Deleted Successfully', {
          //   onClose: () => navigate('/')
          // });
        } else {
          // alert("Cannot Delete User");
          toast.error("Cannot Delete User");
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        // alert("Cannot Delete User");
        toast.error("Cannot Delete User");
      }
    } else {
      // alert("Please Login or Signup");
      // navigate("/login");
      toast.error('Please Login or Signup', {
        onClose: () => navigate('/login')
      });
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="container-fluid" id="delete-user">
        <div className="row">
          <div className="col-lg-4 wrap">
            <div className="image">
              <img src={sorrow_img} alt="Sorrow" />
            </div>
            <div className="head">We're sorry to see you go</div>
            <div className="instruct">
              <p>If you have any valuable feedback please let us know. We'll be happy to hear it from you.</p>
              <p>Be advised, account deletion is final. There will be no way to restore your account.</p>
            </div>
            <div className="btn-div">
              <button className="cancel" type="button" onClick={handleCancelBtn}>Cancel</button>
              <button className="confirm" type="button" onClick={handleDeleteUser}>Delete my Account</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  );
}

export default ConfirmDeleteUser;
