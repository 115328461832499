import { useState } from 'react';
import ForYou from './ForYou';
import Following from './Following';
import './SHome.css' ;
import SmallFooter from '../../../components/footer/SmallFooter';
import SmallHeader from '../../../components/header/small-screen/SmallHeader';

const SHomeContent = () => {
  const [tabs, setTabs] = useState("for_you");
  const switchTabs = (tabName) =>{
    setTabs(tabName);
  }

  return (
    <>
    <SmallHeader />
    <div className="container-fluid" id="s-topbar">
      <div className="row">
        <div className="col-12">
            <ul>
              <li onClick={()=>switchTabs("for_you")} className={tabs === "for_you"?"active":""}>For You</li>
              <li onClick={()=>switchTabs("following")} className={tabs === "following"?"active":""}>Following</li>
            </ul>
        </div>
      </div>
    </div>
    {tabs==="for_you"? (<ForYou/>):(<Following />)}
    <SmallFooter />
    </>
  )
}

export default SHomeContent