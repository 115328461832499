import React, { useState, useEffect } from 'react';
import { getSingleUser } from '../../../../services/Apis';
import { BASE_URL } from '../../../../services/helper';

const CommonSmallHeader = ({ headerTitle }) => {
    const [userData, setUserData] = useState({});

    const username = sessionStorage.getItem("user_username");

    const fetchUserData = async () => {
        if (!username) return;

        try {
            const res = await getSingleUser(username);
            setUserData(res.data);
        } catch (error) {
            console.error('Error fetching user data:', error.response?.data || error.message);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [username]);

    return (
        <div className="container-fluid" id="small-page-topbar">
            <div className="row">
                <div className="col-12 page-head">
                    <div className='page-title'>
                        <h2>{headerTitle}</h2>
                        {userData.user_img && (
                            <img
                                src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`}
                                alt="user-image"
                                className='profile-img'
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommonSmallHeader;
