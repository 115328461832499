import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllUsers, userStatus, userDelete } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import { NavLink } from 'react-router-dom';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './user.css'

const ViewUser = () => {

  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const res = await getAllUsers("");
      if (res.status === 200) {
        setUsers(res.data);
      } else {
        console.log('Error in Fetching Users.');
      }
    } catch (error) {
      console.error('Error fetching Users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const statusChange = async (id, status) => {
    const response = await userStatus(id, status);
    if (response.status === 200) {
      fetchUsers();
    }
    else {
      alert("Cannot Update Admin Status");
    }
  }

  const handleDeleteUser = async (id) => {
    const response = await userDelete(id);
    if (response.status === 200) {
      fetchUsers();
    }
    else {
      alert("Cannot Delete User");
    }
  }

  return (
    <>
      <AccessChecker accessKey="view_users" />
      <div className='form-card expand-card'>
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Users</h1>
            <div><Link to="/admin/add-user"><button className='btn btn-primary'>Add User</button></Link></div>
          </div>
        </div>
        <div>
          <hr />
          <table class="table table-bordered" style={{ width: '90%', margin: 'auto', marginTop: '30px' }}>
            <thead>
              <th className='text-left'>Sl No.</th>
              <th>Profile</th>
              <th>Fullname</th>
              <th>Username</th>
              <th>Email</th>
              <th>Status</th>
              <th>Action</th>
            </thead>

            <tbody>
              {users.filter(users => users.user_type === "user").map((users, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td><img src={`${BASE_URL}/uploads/admins-users-img/${users.user_img}`} width="60px" height="60px" style={{ "borderRadius": "50%" }} /></td>
                  <td>{users.fname + " " + users.lname}</td>
                  <td>{users.username}</td>
                  <td>{users.email}</td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                        <Badge bg={users.status == "active" ? "primary" : "danger"}>
                          {users.status === "active" ? "Active" : "InActive"} <i className="fa-solid fa-angle-down"></i>
                        </Badge>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => statusChange(users._id, "active")}>Active</Dropdown.Item>
                        <Dropdown.Item onClick={() => statusChange(users._id, "inactive")}>InActive</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle variant="light" className="action" id="dropdown-basic">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink to={`/admin/single-user/${users.username}`} className="text-decoration-none">
                            <i className="fa-solid fa-eye" style={{ color: "green" }}></i> <span>View</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink to={`/admin/edit-user/${users.username}`} className="text-decoration-none">
                            <i className="fa-solid fa-pen-to-square" style={{ color: "blue" }}></i> <span>Edit</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div onClick={() => handleDeleteUser(users._id)}><i className="fa-solid fa-trash" style={{ color: "red" }}></i> <span>Delete</span></div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>

      </div>
    </>
  )
}

export default ViewUser;