// For Encryption and Decryption of Password
import CryptoJS from 'crypto-js';
import { BASE_URL } from '../../../services/helper';
import axios from 'axios';

const ENCRYPTION_KEY = "84ee106cf2019ad8a3f445644e590134becb7c6daa61783fc2fea649ee8e1b51";

function encrypt(text) {
    const encrypted = CryptoJS.AES.encrypt(text, ENCRYPTION_KEY).toString();
    return encrypted;
}

function decrypt(text) {
    const decrypted = CryptoJS.AES.decrypt(text, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return decrypted;
}

// const isTokenValid = async () => {
//     const token = sessionStorage.getItem('admin_token');

//     if (!token) {
//         return false; // No token found
//     }
//     try {
//         const res = await axios.get(`${BASE_URL}/admin-token-validation`, {
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         });
//         return res.status === 200; // Return true if response status is 200
//     } catch (error) {
//         return false; // Error occurred, token is invalid
//     }
// };

const isTokenValid = async () => {
    const admin_token = sessionStorage.getItem('admin_token');

    if (admin_token) {
        try {
            const res = await axios.get(`${BASE_URL}/user-token-validation`, {
                headers: {
                    Authorization: `Bearer ${admin_token}`
                }
            });
            return res.status === 200; // Return true if response status is 200
        } catch (error) {
            sessionStorage.clear();
            return false;
        }
    }
    else {
        sessionStorage.clear();
        return false;
    }
};

function isValidUsername(username) {
    if (username.length < 3 || username.length > 30) {
        return false;
    }
    const alphabetRegex = /[a-zA-Z]/;
    if (!alphabetRegex.test(username)) {
        return false;
    }
    const regex = /^[a-zA-Z0-9._]+$/;
    if (!regex.test(username)) {
        return false;
    }

    return true;
}

export { encrypt, decrypt, isTokenValid, isValidUsername };