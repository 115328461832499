import React, { useState, useEffect, useContext } from 'react';
import './lhome.css';
import { Alert } from 'react-bootstrap';
import { loginAlert, headerMenu, postSearchKey, postFeaturedKey, postTagsKey } from '../../../components/context/ContextProvider';
import { getAllPostsWithUser, getUserLikedPosts, postLikeUnlike, addComment, increasePostShareViews } from '../../../../services/Apis';
import { BASE_URL } from '../../../../services/helper';
import WordLimit from '../../../components/wordLimit/WordLimit';
import { isTokenValid } from '../../../components/functions/OtherFunctions';
import { useNavigate } from 'react-router-dom';
import SharePostModal from './SharePostModal';
import { ToastContainer, toast } from 'react-toastify';
const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL;

const LContent = () => {
  const navigate = useNavigate();
  const { login, setLogin } = useContext(loginAlert);

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();

    if(login){
      const timer = setTimeout(() => {
        setLogin('');
      }, 3000);
  
      // Cleanup the timer when the component unmounts
      return () => clearTimeout(timer);  
    }
  }, []);

  const username = sessionStorage.getItem('user_username') || "";
  const { searchKey, setSearchKey } = useContext(postSearchKey);
  const { featuredKey, setFeaturedKey } = useContext(postFeaturedKey);
  const { tagsKey, setTagsKey } = useContext(postTagsKey);

  const postClick = async (post_id, post_by) => {
    try {
      const response = await increasePostShareViews(post_id, { action: "viewed" });
      if (response.status === 200) {
        navigate(`view-post/${post_by}/${post_id}`);
      } else {
        toast.error("Cannot View Post");
      }
    } catch (error) {
      console.error('Error increasing post views:', error);
      toast.error("Cannot View Post");
    }
  };

  const [postLoading, setPostLoading] = useState(true);
  const [allPost, setAllPost] = useState([]);
  const fetchPosts = async () => {
    try {
      const res = await getAllPostsWithUser(searchKey, featuredKey, username, tagsKey);
      if (res.status === 200) {
        setAllPost(res.data);
      } else {
        console.log('Error in Fetching Posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
    finally{
      setTimeout(() => {
        setPostLoading(false);
      }, 500);
    }
  };

  const [postLiked, setPostLiked] = useState([]);
  const getLikedPost = async () => {
    try {
      const res = await getUserLikedPosts(username);
      if (res.status === 200) {
        setPostLiked(res.data.likedPosts);
      } else {
        console.log('Error in Fetching Liked Posts.');
      }
    } catch (error) {
      console.error('Error fetching liked posts:', error);
    }
  };

  useEffect(() => {
    fetchPosts();
    if (username) {
      getLikedPost();
    }
  }, [searchKey, featuredKey, username, tagsKey]);

  const handleLike = async (post_id, posted_by) => {
    if (isValid) {
      const data = { post_id, liked_by: username, posted_by };
      try {
        const res = await postLikeUnlike(data);
        if (res.status === 200) {
          getLikedPost();
          fetchPosts();
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    } else {
      toast.error("Please Login First");
    }
  };

  const [commBox, setCommBox] = useState({
    post_id: "",
    show: false
  });
  const showCommBox = (post_id) => {
    if (isValid) {
      setCommBox(prevState => ({
        post_id: post_id,
        show: !prevState.show
      }));
    } else {
      toast.error("Please Login First");
    }
  };

  const [comm, setComm] = useState("");
  const postComment = async (post_id, posted_by) => {
    const commentData = { post_id, content: comm, commented_by: username, posted_by };
    if (comm.trim() === "") {
      toast.error("Comment cannot be Empty");
    } else {
      try {
        const res = await addComment(commentData);
        if (res.status === 200) {
          setCommBox(prevState => ({ post_id: "", show: !prevState.show }));
          setComm("");
          fetchPosts();
          toast.success("Comment Successfully");
        } else {
          toast.error("Comment Cannot be Added Now");
        }
      } catch (error) {
        console.error('Error:', error.response.data);
        toast.error("Comment Cannot be Added Now");
      }
    }
  };

  const { showMenu, setShowMenu } = useContext(headerMenu);

  const [modalShow, setModalShow] = useState(false);
  const [postLink, setPostLink] = useState();
  const [postShareId, setPostShareId] = useState('');

  const handlePostModal = (post_id, username) => {
    setModalShow(true);
    setPostLink(`${WEBSITE_URL}/view-post/${username}/${post_id}`);
    setPostShareId(post_id);
  };

  return (
    <>
      <div className="content-box" id="lhome-wrap" style={showMenu ? { filter: "blur(3px)" } : {}}>
        <div className="feed box-content">
          {login && (
            <Alert variant="success" onClose={() => setLogin('')} dismissible>
              Welcome {login}
            </Alert>
          )}
          {postLoading?(
            <div id="post-loader" className='row'>
            {Array.from({ length: 8 }, (_, index) => (
              <div key={index} className="wrap-div col-3">
                <div className="img-div"></div>
                <div className="details-div">
                  <div className="left-div">
                    <div className="post-title">
                      <div className="title"></div>
                    </div>
                    <div className="user-div">
                      <div className="user-img"></div>
                      <div className="user-name"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          ):
          allPost.length > 0 ? (
            <div className="post-row">
              <div className="col-t">
                {allPost.map((post, index) => (
                  <div className="card col-t-card" key={index}>
                    <img src={`${BASE_URL}/uploads/posts-img/${post.post_img}`} alt={post.post_img} onClick={() => postClick(post._id, post.user.username)} />
                    <div className="card-body">
                      <div className="row card-row" style={{ paddingBottom: "10px" }}>
                        <div className="col-8 p-0">
                          <div className="img-title">
                            <WordLimit text={post.title} limit={20} />
                          </div>
                        </div>
                        <div className="col-4 p-0">
                          <div className="row">
                            <div className="col-6 d-flex align-items-center">
                              <div onClick={() => handlePostModal(post._id,post.user.username)}>
                                <i className="fa-solid fa-retweet icons"></i>
                              </div>
                              <div className="count">{post.shared}</div>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                              <div>
                                <i className="fas fa-align-left icons" style={{ rotate: '-90deg' }}></i>
                              </div>
                              <div className="count">{post.views}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row card-row">
                        <div className="col-8 d-flex justify-content-start align-items-center p-0" style={{ gap: "7px" }}>
                          <div className="author-img">
                            {post.user.user_img ? (
                              <img src={`${BASE_URL}/uploads/admins-users-img/${post.user.user_img}`} alt={post.title} width="35px" height="35px" className="rounded-circle" />
                            ) : null}
                          </div>
                          <div className="author-title">{post.user.username}</div>
                        </div>
                        <div className="col-4 p-0">
                          <div className="row">
                            <div className="col-6 d-flex align-items-center">
                              {postLiked.length > 0 ? (
                                postLiked.some((liked) => liked.post_id === post._id) ? (
                                  <div>
                                    <i className="fa-solid fa-heart heart-icon icons" onClick={() => handleLike(post._id, post.user.username)} style={{ color: 'red' }}></i>
                                  </div>
                                ) : (
                                  <div>
                                    <i className="fa-solid fa-heart heart-icon icons" onClick={() => handleLike(post._id, post.user.username)}></i>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <i className="fa-solid fa-heart heart-icon icons" onClick={() => handleLike(post._id, post.user.username)}></i>
                                </div>
                              )}
                              <div className="count">{post.likes}</div>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                              <div>
                                <i className="fa-solid fa-comment icons" onClick={() => showCommBox(post._id)}></i>
                              </div>
                              <div className="count">{post.comments}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {commBox.post_id === post._id && commBox.show && (
                        <div className="row comment-row">
                          <form>
                            <div className="col-9 p-0">
                              <div className="form-group">
                                <textarea className="form-control" rows="2" value={comm} onChange={(e) => setComm(e.target.value)}></textarea>
                              </div>
                            </div>
                            <div className="col-3 p-0 text-center">
                              <button className='post-btn' type='button' onClick={() => postComment(post._id, post.user.username)}>Post</button>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>No Posts to Show</div>
          )}
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
      <SharePostModal show={modalShow} postLink={postLink} onHide={() => setModalShow(false)} post_id={postShareId} />
    </>
  );
};

export default LContent;
