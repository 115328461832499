import React, {useState, useEffect} from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../profile.css' ;
import { getSingleUser, userSocial } from '../../../../services/Apis';
import { isTokenValid } from '../../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';

const ProfileAbout = ({username}) => {
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);
  
  
  const logged_username = sessionStorage.getItem("user_username");

  const [socialLinks, setSocialLinks] = useState({})
  const [nullSocialLinks, setNullSocialLinks] = useState(false);

  const getUserSocialLinks = async () => {
    try {
      const res = await userSocial(username);
      if (res.status === 200) {
        if(res.data === null){
          setNullSocialLinks(true);
          setSocialLinks({
            facebook:"", instagram:"", twitter:"", linkedin:"", github:"", youtube:"",
          })
        }
        else{
        setSocialLinks(res.data);
      }
      } else {
        console.log('Error in Fetching Posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const [userData, setUserdata] = useState({})
  const getUserInfo = async () => {
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setUserdata(res.data);
      } else {
        console.log('Error in Fetching Posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  
  useEffect(() => {
    getUserInfo();
    getUserSocialLinks();
  }, []);

  const noLink = () =>{
    if(isValid && (username === logged_username)){
      // alert("No Link was added go to edit profile to add links");
      toast.error("No Link was added go to edit profile to add links");
    }
    else{
      // alert("No Link was added by the User");
      toast.error("No Link was added by the User");
    }
    
  }

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
  
    const options = { year: 'numeric', month: 'long' };
    const formattedDate = dateObj.toLocaleDateString('en-US', options);
    return formattedDate;
  }
  
  return (
    <>
    <div className="col-12" id="profile-about">
      <div className="row">
        <div className="col-6 about-col-1" style={{"padding":"0 10px"}}>
          <div className="mt-1">
          <div className='d-flex justify-content-between'><h5 className='content-head'>Intro</h5>{isValid && (username === logged_username)?( <NavLink className="edit-btn" to="/edit-profile"><i class="fa-regular fa-pen-to-square"></i> Edit</NavLink>):('')}</div>
            {userData.intro?(<p className='content'>{userData.intro}</p>):(<p className='content'>------ No Intro Added ------</p>)}
          </div>
          <div className="mt-3">
            <div className='d-flex justify-content-between'><h5 className='content-head'>Bio</h5></div>
            {userData.bio?(<p className='content'>{userData.bio}</p>):(<p className='content'>------ No Bio Added ------</p>)}
            {/* <p className='content'>------ No Bio Added ------</p> */}
          </div>
          <div className="mt-3">
            <div className='d-flex justify-content-between'><h5 className='content-head'>Skills</h5></div>
            {userData.skills?(<p className='content'>{userData.skills}</p>):(<p className='content'>------ No Skills Added ------</p>)}
            {/* <p className='content'>------ No Skills Added -------</p> */}
          </div>
        </div>
        <div className="col-3 about-col-2" style={{"marginLeft":"15px"}}>
          <div className='row membership'>
            <div className="col-12"><p style={{"marginBottom":"5px"}}><i class="bi bi-geo-alt"></i>&nbsp; {userData.location?(userData.location):('---- No Location Added ----')}</p></div>
            <div className="col-12"><p style={{"marginTop":"5px"}}><i class="bi bi-person-circle"></i>&nbsp; Member Since {formatDate(userData.reg_date)}</p></div>
          </div>
            <div className="row social-div">
            <div className='col-12'>Your Social Links</div>
            {nullSocialLinks?(<>
            <div className="col-6"><div className="social-icon"><Link to="" onClick={noLink}><i class="fa-brands fa-facebook" style={socialLinks.facebook?{"color":"#4267B2"}:{}}></i></Link></div></div>
            <div className="col-6"><div className="social-icon"><Link to="" onClick={noLink}><i class="fa-brands fa-instagram" style={socialLinks.instagram?{"color":"#e95950"}:{}}></i></Link></div></div>
            <div className="col-6"><div className="social-icon"><Link to="" onClick={noLink}><i class="fa-brands fa-twitter" style={socialLinks.twitter?{"color":"#1DA1F2"}:{}}></i></Link></div></div>
            <div className="col-6"><div className="social-icon"><Link to="" onClick={noLink}><i class="fa-brands fa-linkedin" style={socialLinks.linkedin?{"color":"#0077b5"}:{}}></i></Link></div></div>
            <div className="col-6"><div className="social-icon"><Link to="" onClick={noLink}><i class="fa-brands fa-github" style={socialLinks.github?{"color":"#333"}:{}}></i></Link></div></div>
            <div className="col-6"><div className="social-icon"><Link to="" onClick={noLink}><i class="fa-brands fa-youtube" style={socialLinks.youtube?{"color":"#FF0000"}:{}}></i></Link></div></div>
            </>):(<>
            
              <div className="col-6"><div className="social-icon"><a href={socialLinks.facebook} target='_blank'><i class="fa-brands fa-facebook" style={socialLinks.facebook?{"color":"#4267B2"}:{}}></i></a></div></div>
            <div className="col-6"><div className="social-icon"><a href={socialLinks.instagram} target='_blank'><i class="fa-brands fa-instagram" style={socialLinks.instagram?{"color":"#e95950"}:{}}></i></a></div></div>
            <div className="col-6"><div className="social-icon"><a href={socialLinks.twitter} target='_blank'><i class="fa-brands fa-twitter" style={socialLinks.twitter?{"color":"#1DA1F2"}:{}}></i></a></div></div>
            <div className="col-6"><div className="social-icon"><a href={socialLinks.linkedin} target='_blank'><i class="fa-brands fa-linkedin" style={socialLinks.linkedin?{"color":"#0077b5"}:{}}></i></a></div></div>
            <div className="col-6"><div className="social-icon"><a href={socialLinks.github} target='_blank'><i class="fa-brands fa-github" style={socialLinks.github?{"color":"#333"}:{}}></i></a></div></div>
            <div className="col-6"><div className="social-icon"><a href={socialLinks.youtube} target='_blank'><i class="fa-brands fa-youtube" style={socialLinks.youtube?{"color":"#FF0000"}:{}}></i></a></div></div>
            </>)}
            </div>
        </div>
      </div>
    </div>
    <ToastContainer position="top-center" autoClose={1000}/>
    </>
  )
}

export default ProfileAbout