import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllJobs, jobStatus, jobDelete } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import { NavLink } from 'react-router-dom';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import './job.css';

const ViewJobs = () => {

  const [jobs, setJobs] = useState([]);

  const fetchJobs = async () => {
    try {
      const res = await getAllJobs();
      if (res.status === 200) {
        setJobs(res.data);
      } else {
        console.log('Error in Fetching Jobs.');
      }
    } catch (error) {
      console.error('Error fetching Jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const statusChange = async (id, status) => {
    const response = await jobStatus(id, status);
    if (response.status === 200) {
      fetchJobs();
    }
    else {
      alert("Cannot Update Admin Status");
    }
  }

  const handleDeleteJob = async (id) => {
    const response = await jobDelete(id);
    if (response.status === 200) {
      fetchJobs();
    }
    else {
      alert("Cannot Delete Job");
    }
  }

  return (
    <>
      <AccessChecker accessKey="view_jobs" />
      <div className='form-card expand-card'>
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Jobs</h1>
            <div><Link to="/admin/add-user"><button className='btn btn-primary'>Add Jobs</button></Link></div>
          </div>
        </div>
        <div>
          <hr />
          <table class="table table-bordered" style={{ width: '90%', margin: 'auto', marginTop: '30px' }}>
            <thead>
              <th className='text-left'>Sl No.</th>
              <th>Logo</th>
              <th>Company Name</th>
              <th>Username</th>
              <th>Job Title</th>
              <th>Status</th>
              <th>Action</th>
            </thead>

            <tbody>
              {jobs.map((jobs, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td><img src={`${BASE_URL}/uploads/jobs-img/${jobs.company_logo}`} width="60px" height="60px" style={{ "borderRadius": "50%" }} /></td>
                  <td>{jobs.company_name}</td>
                  <td>{jobs.username}</td>
                  <td>{jobs.title}</td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                        <Badge bg={jobs.status == "active" ? "primary" : "danger"}>
                          {jobs.status === "active" ? "Active" : "InActive"} <i className="fa-solid fa-angle-down"></i>
                        </Badge>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => statusChange(jobs._id, "active")}>Active</Dropdown.Item>
                        <Dropdown.Item onClick={() => statusChange(jobs._id, "inactive")}>InActive</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle variant="light" className="action" id="dropdown-basic">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink to={`/admin/single-job/${jobs._id}`} className="text-decoration-none">
                            <i className="fa-solid fa-eye" style={{ color: "green" }}></i> <span>View</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink to={`/admin/edit-job/${jobs._id}`} className="text-decoration-none">
                            <i className="fa-solid fa-pen-to-square" style={{ color: "blue" }}></i> <span>Edit</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div onClick={() => handleDeleteJob(jobs._id)}><i className="fa-solid fa-trash" style={{ color: "red" }}></i> <span>Delete</span></div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>

      </div>
    </>
  )
}

export default ViewJobs;