import React, { useState, useEffect } from 'react';
import './Message.css';
import receiver_img from './obaid_img.jpg';
import { createChat, getAllUsers, getUserChats, getUserMessages, sendUserMessage, updateMsgStatus } from '../../../services/Apis';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';
import { BASE_URL } from '../../../services/helper';

const SendMessage = () => {

  const username = sessionStorage.getItem("admin_username");

  //<-------------------------- Select User for Chat -------------------------->
  const [users, setUsers] = useState([]);
  const fetchAllUsers = async () => {
    try {
      const res = await getAllUsers();
      setUsers(res.data);
    } catch (error) {
      console.error('Error fetching User Chats:', error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  //<-------------------------- Open Chat System -------------------------->
  const [msgSender, setMsgSender] = useState("");
  const [chatReceiver, setChatReceiver] = useState("");
  const [chatStart, setChatStart] = useState(false);
  const [fetchIntervalId, setFetchIntervalId] = useState(null);

  const [chatData, setChatdata] = useState({});

  const createUserChat = async () => {
    if (username && chatReceiver) {
      const data = { sender: username, receiver: chatReceiver };
      try {
        const config = { 'Content-Type': 'application/json', };
        const res = await createChat(data, config);
        if (res.status === 200) {
          setChatdata(res.data)
          getMessages(username, chatReceiver);
          const intervalId = setInterval(() => {
            getMessages(username, chatReceiver);
          }, 2000);
          setChatStart(true);
          setFetchIntervalId(intervalId);
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    }
    else if (!chatReceiver) {
      alert("Please select User");
    }
    else {
      alert("Please Login First");
    }
  }

  const handleChatClose = () => {
    setChatStart(false);
    setChatReceiver("");
    clearInterval(fetchIntervalId);
  }

  //<-------------------------- Messaging Functionality -------------------------->
  const [msgs, setMsgs] = useState([]);

  const getMessages = async (sender, receiver) => {
    try {
      const res = await getUserMessages(sender, receiver);
      if (res.status === 200) {
        setMsgs(res.data);
        const unseenMessageIds = res.data
          .filter((msg) => msg.status === 'unseen' && msg.receiver === username)
          .map((msg) => msg._id);
        if (unseenMessageIds.length > 0) {
          const config = { "Content-Type": "application/json" }
          const data = { messageIds: unseenMessageIds }
          await updateMsgStatus(data, config);
        }
  
      } else {
        console.log("Error in Fetching User Messages.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const [text, setText] = useState("");
  const sentText = async () => {
    if (text !== "") {
      try {
        var data;
        if (username) {
          data = { sender: username, receiver: chatReceiver, content: text };
        } else {
          data = { sender: chatReceiver, receiver: username, content: text };
        }
        const config = { "Content-Type": "application/json" };
        const res = await sendUserMessage(data, config);
        if (res.status === 200) {
          setText("");
          getMessages(username, chatReceiver);
        } else {
          alert("Some Unknown Error Occurred");
        }
      } catch (error) {
        console.error('Error:', error.res.data);
      }
    }
  };

  return (
    <>
      <AccessChecker accessKey="send_message" />
      <div className="form-card" id="send-message">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Send Message</h1>
          </div>
        </div>
        <hr />
        {!chatStart ? (<>
          <div className="form-div add-chat">
            <div className='row'>
              <div className="col-lg-3">
                <label>Select user to send Message :</label>
              </div>
              <div className="col-lg-7">
                <select className='form-control form-select' onChange={(e) => setChatReceiver(e.target.value)}>
                  <option value="0">---------- Select User from Below ----------</option>
                  {users.map((user, index) => (
                    <option value={user.username} key={index}>{user.fname + " " + user.lname + " " + user.username}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-2"><button className='btn btn-primary' type='button' onClick={createUserChat}>Start Chat</button></div>
            </div>
          </div>
        </>) : (<>
          <div className='form-div msg'>
            <div className='msg-wrapper'>
              <div className='msg-head'>
                <div className='user-name'>{chatReceiver}</div>
                <div className='close-btn' onClick={handleChatClose}><i class="fa-solid fa-xmark"></i></div>
              </div>
              <div className='msg-box'>
                {msgs.map((msg, index) => (<>
                  {msg.sender !== username ? (
                    <div className='receiver'>
                      <div className='user-p'>
                        {msg.sender === chatData.sender?(<img src={`${BASE_URL}/uploads/admins-users-img/${chatData.sender_img}`}/>):(<img src={`${BASE_URL}/uploads/admins-users-img/${chatData.receiver_img}`}/>)}
                      </div>
                      <div className='user-msg'>{msg.content}</div>
                    </div>
                  ) : (
                    <div className='sender'>
                      <div className='user-msg'>{msg.content}</div>
                      <div className='user-p'>
                        {msg.receiver === chatData.receiver?(<img src={`${BASE_URL}/uploads/admins-users-img/${chatData.sender_img}`}/>):(<img src={`${BASE_URL}/uploads/admins-users-img/${chatData.receiver_img}`}/>)}
                      </div>
                    </div>
                  )}
                </>))}
              </div>
              <div className="write-msg">
                <div className='in-field'><textarea value={text} onChange={(e) => setText(e.target.value)}></textarea></div>
                <button className='sent-btn' type="button" onClick={sentText}><i class="fa-regular fa-paper-plane"></i></button>
              </div>
            </div>
          </div>
        </>)}
      </div>
    </>
  )
}

export default SendMessage