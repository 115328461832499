import React, { useRef, useState } from 'react';
import './signup.css';
import LocationSuggestion from '../../components/functions/LocationSuggestion';
import men1 from '../../assets/avatar-imgs/men-1.png';
import men2 from '../../assets/avatar-imgs/men-2.png';
import men3 from '../../assets/avatar-imgs/men-3.png';
import men4 from '../../assets/avatar-imgs/men-4.jpg';
import men5 from '../../assets/avatar-imgs/men-5.png';
import men6 from '../../assets/avatar-imgs/men-6.png';
import men7 from '../../assets/avatar-imgs/men-7.png';
import women1 from '../../assets/avatar-imgs/women-1.png';
import women2 from '../../assets/avatar-imgs/women-2.png';
import women3 from '../../assets/avatar-imgs/women-3.png';
import women4 from '../../assets/avatar-imgs/women-4.png';
import women5 from '../../assets/avatar-imgs/women-5.png';
import women6 from '../../assets/avatar-imgs/women-6.png';
import { completeProfile } from '../../../services/Apis';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const CompleteProfile = () => {

  const navigate = useNavigate();
  const { id } = useParams();

  const inputFileRef = useRef(null);
  const [showImg, setShowImg] = useState('');
  const [user_img, setUserImg] = useState('default-avatar');
  const [location, setLocation] = useState('');
  const [query, setQuery] = useState('');

  const handleAvatarClick = (imgSrc, imgName) => {
    setShowImg(imgSrc);
    setUserImg(imgName);
  };

  const setProfile = (e) => {
    setShowImg(URL.createObjectURL(e.target.files[0]));
    setUserImg(e.target.files[0]);
  };

  const setUserLocation = (e) => {
    setLocation(e.target.value);
    setQuery(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setLocation(suggestion);
    setQuery('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!location) {
      alert("Please Enter Location");
    }
    else {
      const data = new FormData();
      data.append("user_img", user_img);
      data.append("location", location);

      const config = {
        "Content-Type": "multipart/form-data"
      }
      const response = await completeProfile(data, config, id);
      if (response.status === 200) {
        navigate("/");
      }
      else {
        // alert("Sorry Registration Unsuccessfull");
        toast.error("Sorry Registration Unsuccessfull");
      }
    }
  }

  return (
    <>
      <div className='container-fluid' id='complete-profile'>
        <div className='row box-wrap'>
          <div className='col-lg-5'>
            <div className='form-wrapper'>
              <div className='form-head'>Welcome! Let's Complete your Profile</div>
              <div className='form-caption'>Let others get to know you better</div>
              <form onSubmit={handleSubmit}>
                <div className="form-group pt-4">
                  <label className='py-3'>Add an Avatar</label>
                  <div className='img-wrap'>
                    <div className='show-img'><img src={showImg} className='profile-img' /></div>
                    <div className='input-img'>
                      <input type="file" accept="image/*" style={{ display: 'none' }} ref={inputFileRef} onChange={setProfile} />
                      <button type="button" onClick={() => inputFileRef.current.click()}>Choose Image</button><br/>
                      <span className='hint'>(You can also Select from Below Avatars)</span>
                      <div className="avatar-imgs">
                        <img src={men1} onClick={() => handleAvatarClick(men1, "men-1.png")} />
                        <img src={women1} onClick={() => handleAvatarClick(women1, "women-1.png")} />
                        <img src={men2} onClick={() => handleAvatarClick(men2, "men-2.png")} />
                        <img src={women2} onClick={() => handleAvatarClick(women2, "women-2.png")} />
                        <img src={men3} onClick={() => handleAvatarClick(men3, "men-3.png")} />
                        <img src={women3} onClick={() => handleAvatarClick(women3, "women-3.png")} />
                        <img src={men4} onClick={() => handleAvatarClick(men4, "men-4.jpg")} />
                        <img src={women4} onClick={() => handleAvatarClick(women4, "women-4.png")} />
                        <img src={men5} onClick={() => handleAvatarClick(men5, "men-5.png")} />
                        <img src={women5} onClick={() => handleAvatarClick(women5, "women-5.png")} />
                        <img src={men6} onClick={() => handleAvatarClick(men6, "men-6.png")} />
                        <img src={women6} onClick={() => handleAvatarClick(women6, "women-6.png")} />
                        <img src={men7} onClick={() => handleAvatarClick(men7, "men-7.png")} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group' style={{"position":"relative"}}>
                  <label className='py-3'>Add your Location</label>
                  <br />
                  <input type='text' className='location-input' name='location' value={location} onChange={setUserLocation} autoComplete='off'/>
                  {query?(<LocationSuggestion query={query} limit={10} onSuggestionClick={handleSuggestionClick} />):('')}
                </div>
                <div className='form-group continue-div'>
                  <button className='continue-btn' type='submit'>
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000}/>
    </>
  );
};

export default CompleteProfile;
