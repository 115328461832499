import React, { useContext, useState, useEffect } from "react";
import SmallFooter from '../../components/footer/SmallFooter';
import './message.css';
import { getUserMessages, sendUserMessage, getUserChats, updateMsgStatus, chatById } from "../../../services/Apis";
import { BASE_URL } from "../../../services/helper";
import CommonSmallHeader from "../../components/header/small-screen/CommonSmallHeader";
import { formatDate2, isTokenValid } from "../../components/functions/OtherFunctions";
import WordLimit from "../../components/wordLimit/WordLimit";
import { useNavigate } from "react-router-dom";

const SMessage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if(!valid){
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const username = sessionStorage.getItem('user_username') || "";
  const user_id = sessionStorage.getItem('user_id') || "";
  const [userChats, setUserChats] = useState([]);
  const [chatData, setChatData] = useState({});
  const [chatReceiver, setChatReceiver] = useState("");
  const [chatSender, setChatSender] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [fetchIntervalId, setFetchIntervalId] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  //<------------------------------------------------- To Fetch Current User Chats ------------------------------------------------->
  const fetchUserChats = async () => {
    try {
      const res = await getUserChats(username, "");
      if (res.status === 200) {
        setUserChats(res.data);
      } else {
        console.log('Error in Fetching Chats.');
      }
    } catch (error) {
      console.error('Error fetching User Chats:', error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchUserChats();
    }
  }, []);

  //<------------------------------------------------- To Fetch Selected single Chat Data ------------------------------------------------->
  const singleChatData = async (id) => {
    try {
      const res = await chatById(id);
      if (res.status === 200) {
        setChatData(res.data);
      } else {
        console.log('Error in Fetching Chats.');
      }
    } catch (error) {
      console.error('Error fetching User Chats:', error);
    }
  }

  //<------------------------------------------------- To Handle Chat is Opened ------------------------------------------------->
  const openChat = (chat_id, receiver, sender) => {
    singleChatData(chat_id);
    setIsChatOpen(true);
    setChatReceiver(receiver);
    setChatSender(sender);
    getMessages(sender, receiver);
    fetchUserChats(username);

    const intervalId = setInterval(() => {
      getMessages(sender, receiver);
      if(!isChatOpen){
        fetchUserChats();
      }
    }, 2000);
    setFetchIntervalId(intervalId);
  };

  //<------------------------------------------------- To Get Message of Each Chat ------------------------------------------------->
  const getMessages = async (sender, receiver) => {
    try {
      const res = await getUserMessages(sender, receiver);
      if (res.status === 200) {
        setMsgs(res.data);

      } else {
        console.log("Error in Fetching User Messages.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  //<------------------------------------------------- To Mark Message as Seen for Each Chat ------------------------------------------------->
  const markMessageSeen = async()=>{
    if(msgs.length > 0){
      const unseenMessageIds = msgs.filter((msg) => (msg.status === 'unseen' && msg.receiver === username)).map((msg) => msg._id);
    if (unseenMessageIds.length > 0) {
      const config = { "Content-Type": "application/json" }
      const data = { messageIds: unseenMessageIds }
      await updateMsgStatus(data, config);
    }
  }
  }

  useEffect(()=>{
    markMessageSeen();
  },[msgs])

  //<------------------------------------------------- To Handle Chat is Closed ------------------------------------------------->
  const closeChat = () => {
    setChatData({});
    setIsChatOpen(false);
    setMsgs([]);
    clearInterval(fetchIntervalId);
  };

  //<------------------------------------------------- To Handle when message is sent ------------------------------------------------->
  const [text, setText] = useState("");
  const sentText = async () => {
    if (text !== "") {
      try {
        var data;
        if (username === chatSender) {
          data = { sender: chatSender, receiver: chatReceiver, content: text };
        } else {
          data = { sender: chatReceiver, receiver: chatSender, content: text };
        }
        const config = { "Content-Type": "application/json" };
        const res = await sendUserMessage(data, config);
        if (res.status === 200) {
          setText("");
          getMessages(chatSender, chatReceiver);
        } else {
          alert("Some Unknown Error Occurred");
        }
      } catch (error) {
        console.error('Error:', error.res.data);
      }
    }
  };

  return (
    <>
      {!isChatOpen ? (
        <>
          <CommonSmallHeader headerTitle={"Messages"} />
          <div className="container-fluid mt-2" id="small-msg-chats">
            {userChats.map((ch, index) => (
              <>
                <div className="row">
                  <div className="col-12 d-flex" onClick={() => openChat(ch.chat._id, ch.chat.receiver, ch.chat.sender)}>
                    <div className="left-img">
                      {ch.chat.sender === username ? (<img src={`${BASE_URL}/uploads/admins-users-img/${ch.chat.receiver_img}`} />) : (<img src={`${BASE_URL}/uploads/admins-users-img/${ch.chat.sender_img}`} />)}
                    </div>
                    <div className="right-details row">
                      <div className="col-8">
                        <div className="top-details"><div className='username'>{ch.chat.sender === username ? (ch.chat.receiver) : (ch.chat.sender)}</div></div>
                        <div className="bottom-details">{ch.latestMsg ? <WordLimit text={ch.latestMsg.content} limit={40} /> : ''}</div>
                      </div>
                      <div className="col-4 position-relative">
                        <div className="chat-date">{formatDate2(ch.chat.chat_created)}</div>
                        {ch.latestMsg && (ch.latestMsg.status === "unseen") && (ch.latestMsg.receiver_id === user_id) && (
                        <div className="unseen-chat bg-success"></div>
                      )}
                      </div>
                    </div>
                  </div>
                  <hr className='notif-separator' />
                </div>

              </>
            ))}
          </div>
          <SmallFooter />
        </>
      ) : (
        <div className="container-fluid" id="small-page-topbar" style={{ "padding": "0", "height": "100vh" }}>
          <div className="row px-2">
            <div className="col-12 page-head">
              <div className='page-title'>
                <div className="user-data">
                  {username === chatData.sender ? (
                    <img src={`${BASE_URL}/uploads/admins-users-img/${chatData.receiver_img}`} className='profile-img' />
                  ) : (
                    <img src={`${BASE_URL}/uploads/admins-users-img/${chatData.sender_img}`} className='profile-img' />)}
                  <h2>{chatReceiver === username ? (chatSender) : (chatReceiver)}</h2>
                </div>
                <div className="close-chat">
                  <button type="button" onClick={() => closeChat()}><i class="fa-solid fa-xmark"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid" id="small-msg">
            <div className="msg-body">
              {msgs.map((msg, index) => (
                <>
                  {msg.sender !== username ? (
                    <div className="sender mt-2">
                      <div>
                        {username === chatData.sender ? (
                          <img src={`${BASE_URL}/uploads/admins-users-img/${chatData.receiver_img}`} width="40px" height="40px" style={{ "borderRadius": "50%" }} />
                        ) : (
                          <img src={`${BASE_URL}/uploads/admins-users-img/${chatData.sender_img}`} width="40px" height="40px" style={{ "borderRadius": "50%" }} />)}
                      </div>
                      <div className="get-msg">{msg.content}</div>
                    </div>
                  ) : (
                    <div className="receiver mt-2">
                      <div className="send-msg">{msg.content}</div>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="msg-input">
              <div className="input-div"><textarea rows="2" className="input-field" value={text} onChange={(e) => setText(e.target.value)} /></div>
              <div className="send-div"><button type="button" onClick={sentText}><i class="fa-regular fa-paper-plane"></i></button></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SMessage