import React, { useState, useEffect, useRef } from 'react';
import '../editProfile.css';
import { profileUpdate, getSingleUser } from '../../../../../services/Apis';
import { BASE_URL } from '../../../../../services/helper';
import default_img from './default-avatar.jpg';
import LocationSuggestion from '../../../../components/functions/LocationSuggestion';
import { ToastContainer, toast } from 'react-toastify';

const EditP = () => {
  const uname = sessionStorage.getItem("user_username");
  const [userData, setUserData] = useState({ _id: "", fname: "", lname: "", skills: "", intro: "", bio: "", user_img: "" });
  const [showImg, setShowImg] = useState("");
  const [user_img, setUserImg] = useState("");
  const fileInputRef = useRef(null);
  const [location, setLocation] = useState('');
  const [query, setQuery] = useState('');

  useEffect(() => {
    userProfileGet(uname);
  }, [uname]);

  const userProfileGet = async (uname) => {
    try {
      const res = await getSingleUser(uname);
      if (res.status === 200) {
        setUserData(res.data);
        setLocation(res.data.location);
        setShowImg(res.data.user_img ? `${BASE_URL}/uploads/admins-users-img/${res.data.user_img}` : default_img);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.log("Error fetching user details:", error);
    }
  };

  const selectImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImg(URL.createObjectURL(selectedFile));
        setUserImg(selectedFile);
      } else {
        // alert("Only png, jpg, jpeg Images are Allowed");
        toast.error("Only png, jpg, jpeg Images are Allowed");
      }
    }
  };

  const deleteImg = () => {
    setShowImg(default_img);
    setUserImg("default-avatar.jpg");
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const setUserLocation = (e) => {
    setLocation(e.target.value);
    setQuery(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setLocation(suggestion);
    setQuery('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { _id, fname, lname, skills, intro, bio } = userData;

    if (!fname) {
      // alert("First Name Cannot be Empty");
      toast.error("First Name Cannot be Empty");
      return;
    }
    if (!lname) {
      // alert("Last Name Cannot be Empty");
      toast.error("Last Name Cannot be Empty");
      return;
    }

    const data = new FormData();
    data.append("id", _id);
    data.append("fname", fname);
    data.append("lname", lname);
    data.append("location", location);
    data.append("skills", skills);
    data.append("intro", intro);
    data.append("bio", bio);
    if (user_img !== "") {
      data.append("user_img", user_img);
    } else {
      data.append("user_img", userData.user_img);
    }

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const res = await profileUpdate(data, config);
      if (res.status === 200) {
        // alert("Successfully Updated Profile");
        toast.success("Successfully Updated Profile");
        userProfileGet(uname);
      } else {
        // alert("Sorry, Unable to Update Profile");
        toast.error("Sorry, Unable to Update Profile");
      }
    } catch (error) {
      console.log("Error updating profile:", error);
    }
  };

  return (
    <>
      <form id="general-form" onSubmit={handleSubmit}>
        <div className="mb-4 mt-3 row">
          <div className="col-12 d-flex">
            <div>
              <img src={showImg} alt="Profile Pic" width="80px" height="80px" className="rounded-circle" />
              <input type="file" style={{ display: "none" }} onChange={selectImg} ref={fileInputRef} />
            </div>
            <div className="d-flex justify-content-center align-items-center edit-p-buttons">
              <button onClick={handleButtonClick} type="button" className="upload_img">Upload new picture</button>
              <button type="button" onClick={deleteImg} className="delete_img">Delete</button>
            </div>
          </div>
        </div>

        <div className="mb-4 row d-flex">
          <div className="col-6 fname-col">
            <label htmlFor="fname" className="form-label">First Name :</label>
            <input type="text" className="form-control" id="fname" placeholder="Tony" value={userData.fname} name="fname" onChange={handleChange} />
          </div>
          <div className="col-6 lname-col">
            <label htmlFor="lname" className="form-label">Last Name :</label>
            <input type="text" className="form-control" id="lname" placeholder="Stark" value={userData.lname} name="lname" onChange={handleChange} />
          </div>
        </div>

        <div className="mb-4 position-relative">
          <label htmlFor="location" className="form-label">Location :</label>
          <input type="text" className="form-control" id="location" placeholder="e.g; Mumbai, Maharashtra" value={location} name="location" onChange={setUserLocation} autoComplete='off' />
          {query ? (<LocationSuggestion query={query} limit={10} onSuggestionClick={handleSuggestionClick} />) : ('')}
        </div>

        <div className="mb-4">
          <label htmlFor="skills" className="form-label">Skills :&nbsp;<span className="message">(Separate with comma)</span></label>
          <input type="text" className="form-control" id="skills" placeholder="e.g; Artist, Coding, Painting" value={userData.skills} name="skills" onChange={handleChange} />
        </div>

        <div className="mb-4">
          <label htmlFor="intro" className="form-label">Short Intro :</label>
          <textarea type="text" className="form-control" id="intro" placeholder="e.g;  I am ....." value={userData.intro} name="intro" onChange={handleChange} />
          <span className="message">Describe yourself, what you do?</span>
        </div>

        <div className="mb-4">
          <label htmlFor="bio" className="form-label">Bio :</label>
          <textarea type="text" className="form-control" id="bio" placeholder="e.g;  My Portfolio consists...." value={userData.bio} name="bio" onChange={handleChange} />
          <span className="message">Brief description for your profile. URLs are hyperlinked.</span>
        </div>

        <div className="mb-4 edit-profile-btn">
          <button type="submit">Save Profile</button>
        </div>
      </form>
      <ToastContainer position="top-center" autoClose={1000}/>
    </>
  );
};

export default EditP;
