import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../../services/helper';
import { Dropdown } from 'react-bootstrap';
import { getSingleUserUnseenNotifs, getUnseenMsgs, updateNotifStatus } from '../../../../services/Apis';
import admin_icon from '../../../assets/notif-imgs/admin.png';
import comment_icon from '../../../assets/notif-imgs/comment.png';
import like_icon from '../../../assets/notif-imgs/like.png';
import msg_icon from '../../../assets/notif-imgs/message.png';
import follower_icon from '../../../assets/notif-imgs/follower.png';
import TimeAgoConverter from '../../functions/TimeAgoConverter';

const HeaderTopRight = ({ user_img, fname, lname, profileClick, userLogout, showProfile, isValid }) => {
  const username = sessionStorage.getItem("user_username");
  const navigate = useNavigate();

  const [msgNotif, setMsgNotif] = useState([]);
  const fetchMsgNotif = async () => {
    try {
      const res = await getUnseenMsgs(username);
      if (res.status === 200) {
        setMsgNotif(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const [notifs, setNotifs] = useState([]);
  const fetchAllNotifs = async () => {
    try {
      const res = await getSingleUserUnseenNotifs(username);
      if (res.status === 200) {
        setNotifs(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    if (isValid) {
      const fetchData = async () => {
        await fetchMsgNotif();
        await fetchAllNotifs();
      };
      fetchData(); // Call initially

      const interval = setInterval(fetchData, 1000); // Call every second
      return () => clearInterval(interval); // Cleanup
    }
  }, [username]);

  const seenStatusChange = async (id) => {
    const response = await updateNotifStatus(id);
    if (response.status === 200) {
      fetchAllNotifs();
    }
    else {
      alert("Cannot Update Notif Status");
    }
  }

  const handleNotifClick = (notif_id, link) => {
    seenStatusChange(notif_id);
    navigate(link);
  }

  const handleMsgClick = () => {
    navigate("/message");
  }
  return (
    <>
      <Dropdown className="header-notification position-relative">
        <Dropdown.Toggle className='sidebar-icon'>
          <i className='fa-regular fa-comment-dots'></i>
          {msgNotif.length > 0 ? (<span className='count-symbol bg-success'></span>) : ('')}
        </Dropdown.Toggle>
        <Dropdown.Menu className="notif-dropdown">
          <div className='drop-head'>Messages</div>
          {msgNotif.length > 0 ? (
            <>
              {msgNotif.map((msg, index) => (
                <>
                  <ul>
                    <li onClick={() => handleMsgClick()}>
                      <div className='left-div'>
                        <img src={msg_icon} width="40px" height="40px" className='rounded-circle' alt={user_img} />
                      </div>
                      <div className='right-div'>
                        <p>You have a new message from {msg.sender}</p>
                        <p><TimeAgoConverter dateTime={msg.msg_time} /></p>
                      </div>
                    </li>
                  </ul>
                  <hr style={{ "margin": "0" }} />
                </>
              ))}
            </>
          ) : (
            <div className='no-new-msg'>No New Messages</div>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="header-notification position-relative">
        <Dropdown.Toggle className='sidebar-icon'>
          <i className='fa-regular fa-bell'></i>
          {notifs.length > 0 ? (<span className='count-symbol bg-danger'></span>) : ('')}
        </Dropdown.Toggle>
        <Dropdown.Menu className="notif-dropdown">
          <div className='drop-head'>Notifications</div>
          {notifs.length > 0 ? (
            notifs.map((notif, index) => (
              <React.Fragment key={index}>
                <ul>
                  <li onClick={() => handleNotifClick(notif._id, notif.redirect_link)}>
                    <div className='left-div'>
                      {notif.sender_type === "admin" ? (
                        <img src={admin_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                      ) : notif.sender_type === "comments" ? (
                        <img src={comment_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                      ) : notif.sender_type === "follows" ? (
                        <img src={follower_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                      ) : (
                        <img src={like_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                      )}
                    </div>
                    <div className='right-div'>
                      <p>{notif.content}</p>
                      <p><TimeAgoConverter dateTime={notif.sent_date} /></p>
                    </div>
                  </li>
                </ul>
                <hr style={{ margin: "0" }} />
              </React.Fragment>
            ))
          ) : (
            <div className='no-new-msg'>No New Notifications</div>
          )}

        </Dropdown.Menu>
      </Dropdown>

      <div className='header-profile position-relative'>
        <div className='profile-icon' onClick={profileClick}>
          <img src={`${BASE_URL}/uploads/admins-users-img/${user_img}`} />
        </div>
        <div className={`profile-dropdown ${showProfile ? '' : 'd-none'}`}>
          <div className='w-100'>
            <NavLink to={`/${username}`}>
              <div className='d-flex justify-content-center'><img src={`${BASE_URL}/uploads/admins-users-img/${user_img}`} alt="profile-pic" /></div>
              <div className='d-flex justify-content-center'><h5>{fname + " " + lname}</h5></div>
              <div className='d-flex justify-content-center'><p>{username}</p></div>
            </NavLink>
            <ul>
              {/* <li><NavLink><i className="bi bi-person-square"></i> &nbsp; Your Channel</NavLink></li> */}
              <li><NavLink to="/login"><i className="bi bi-toggles2"></i> &nbsp; Switch Accounts</NavLink></li>
              <li><NavLink to="/edit-profile"><i className="bi bi-gear"></i> &nbsp; Settings</NavLink></li>
              <li><NavLink to="/contact-us"><i className="bi bi-envelope"></i> &nbsp; Contact Us</NavLink></li>
            </ul>
            <hr style={{ "margin": "0" }} />
            <ul>
              <li onClick={() => userLogout()}><NavLink><i className="bi bi-box-arrow-right"></i> &nbsp; Sign Out</NavLink></li>
              {/* <li><NavLink><i className="bi bi-question-circle"></i> &nbsp; Help</NavLink></li> */}
              {/* <li><NavLink><i className="bi bi-chat-right-quote"></i> &nbsp; Send Feedback</NavLink></li> */}
            </ul>
            <span><NavLink>Privacy Policy</NavLink>&nbsp;.&nbsp;<NavLink>Terms of Service</NavLink></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTopRight;
