import React from 'react';
import HeaderTop from '../../components/header/large-screen/HeaderTop';

const BuyCode = () => {
  return (
    <>
    <HeaderTop />
    <div className="container-fluid w-100 mx-4 text-center" style={{"marginTop":"100px","height":"100vh"}}><h3>This Feature Will Be Comming Soon.</h3></div>
    </>
  )
}

export default BuyCode