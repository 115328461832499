import React, { useState, useEffect } from 'react';
import { getUserPosts } from '../../../../services/Apis';
import { NavLink } from 'react-router-dom';
import { BASE_URL } from '../../../../services/helper';

const ProfileWorks = ({username}) => {
  const [userData, setUserData] = useState([]);

  const [loadingPost, setLoadingPost] = useState(true);
  const userPostsGet = async (username) => {
    try {
      const res = await getUserPosts(username);
      if (res.status === 200) {
        setUserData(res.data.userPosts);
      } else {
        console.log('Error in Fetching User Details.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    finally{
      setTimeout(() => {
        setLoadingPost(false);
      }, 200);
    }
  };

  useEffect(() => {
    userPostsGet(username);
  }, [username]);

  return (
    <>
    <div className='col-12'>
      <div id="profile-works">
        <div className="row d-flex justify-content-center">
          {
            loadingPost? (<div>Loading Works ...</div>) :
          userData.length > 0 ? userData.map((element, index)=>{return(
          <>
          <div className="col-lg-3 col-sm-6 content" style={{"padding":"0","marginBottom":"20px"}}>
            {/* <div class=""> */}
              <NavLink to={`/view-post/${element.username}/${element._id}`}>
                <div class="content-overlay"></div>
                <img class="content-image" src={`${BASE_URL}/uploads/posts-img/${element.post_img}`} alt="" />
                <div class="content-details fadeIn-bottom">
                  <h3 class="content-title">{element.title}</h3>
                  <p class="content-text">
                    <span className='img-icon'><i class="fa-solid fa-heart"></i> <span className='count'>250</span></span><span className='img-icon'><i class="fa-solid fa-comment"></i> <span className='count'>250</span></span><span className='img-icon'><i class="fa-solid fa-retweet"></i> <span className='count'>250</span></span>
                  </p>
                </div>
              </NavLink>
            </div>
          {/* </div> */}
          </>)}):
          (<div className='col-12 d-flex justify-content-center'>
          <div>
            <img src="assets/images/no-data.png" width="300px" />
            <h4 className='text-center mt-2 text'>No Works Uploaded</h4>
          </div>
          </div>)
          }
        </div>
      </div>
    </div>
    </>
  );
};

export default ProfileWorks;
