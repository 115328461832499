import React from 'react';

const JobsApply = () => {
  return (
    <>
    <div className="view-jobs">
    <h1 className='recent-jobs' style={{"margin":"5px 0"}}>Jobs Applied</h1>
    <div>
      <p className='text-center text'>No Jobs Applied</p>
    </div>
    </div>
    </>
  )
}

export default JobsApply