import React, { useEffect, useState } from 'react';
import SmallFooter from '../../components/footer/SmallFooter';
import './notification.css';
import { getSingleUserUnseenNotifs, updateNotifStatus } from '../../../services/Apis';
import TimeAgoConverter from '../../components/functions/TimeAgoConverter';
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from '../../components/functions/OtherFunctions';
import admin_icon from '../../assets/notif-imgs/admin.png';
import comment_icon from '../../assets/notif-imgs/comment.png';
import like_icon from '../../assets/notif-imgs/like.png';
import msg_icon from '../../assets/notif-imgs/message.png';
import follower_icon from '../../assets/notif-imgs/follower.png';
import CommonSmallHeader from '../../components/header/small-screen/CommonSmallHeader';

const NotificationPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if(!valid){
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const username = sessionStorage.getItem("user_username");

  const [notifs, setNotifs] = useState([]);
  const fetchAllNotifs = async () => {
    try {
      const res = await getSingleUserUnseenNotifs(username);
      if (res.status === 200) {
        setNotifs(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllNotifs();
    };
    fetchData();
    const interval = setInterval(fetchData, 1000);
    return () => clearInterval(interval);
  }, [username]);

  const seenStatusChange = async (id) => {
    const response = await updateNotifStatus(id);
    if (response.status === 200) {
      fetchAllNotifs();
    }
    else {
      alert("Cannot Update Notif Status");
    }
  }

  const handleNotifClick = (notif_id, link) => {
    seenStatusChange(notif_id);
    navigate(link);
  }

  return (
    <>
      <CommonSmallHeader headerTitle={"Notifications"}/>
      <div className="container-fluid mt-2" id="notif">
        <div className="row">
          {notifs.length > 0 ? (
            notifs.map((notif, index) => (
              <React.Fragment key={index}>
                <div className="col-12 d-flex my-2" onClick={() => handleNotifClick(notif._id, notif.redirect_link)}>
                  <div className="left-img">
                    {notif.sender_type === "admin" ? (
                      <img src={admin_icon} alt="profile-pic" className='rounded-circle' />
                    ) : notif.sender_type === "comments" ? (
                      <img src={comment_icon} alt="profile-pic" className='rounded-circle' />
                    ) : notif.sender_type === "follows" ? (
                      <img src={follower_icon} alt="profile-pic" className='rounded-circle' />
                    ) : (
                      <img src={like_icon} alt="profile-pic" className='rounded-circle' />
                    )}
                  </div>
                  <div className="right-details">
                    <div className="top-details">{notif.content}</div>
                    <div className="bottom-details">
                      <TimeAgoConverter dateTime={notif.sent_date} />
                    </div>
                  </div>
                </div>
                <hr className='notif-separator' style={{ margin: "0" }} />
              </React.Fragment>
            ))
          ) : (
            <div className='no-new-msg'>No New Notifications</div>
          )}

        </div>
      </div>
      <SmallFooter />
    </>
  )
}

export default NotificationPage