import React, { createContext, useState } from 'react';

export const loginAlert = createContext();
export const postModal = createContext();
export const postModalData = createContext();
export const showMsgBox = createContext();
export const loginToast = createContext();
export const headerMenu = createContext();
export const mainTheme = createContext();
export const commonSidebar = createContext();
export const postSearchKey = createContext();
export const postFeaturedKey = createContext();
export const postTagsKey = createContext();

const ContextProvider = ({children}) => {

    const [showToast, setShowToast] = useState({
      toastType:"",
      message:"",
    });
    const [login, setLogin] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [msgBox, setMsgBox] = useState()
    const [modalData, setModalData] = useState({
      post_id:"",
      post_img:"",
      user_img:"",
      username:"",
      fname:"",
      lname:""
    })
    const [showMenu, setShowMenu] = useState(false);
    const [pageTheme, setPageTheme] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [featuredKey, setFeaturedKey] = useState("");
    const [tagsKey, setTagsKey] = useState("");

  return (
    <>
    <loginAlert.Provider value={{login, setLogin}}>
      <postModal.Provider value={{showModal, setShowModal}}>
        <postModalData.Provider value={{modalData, setModalData}}>
          <showMsgBox.Provider value={{msgBox, setMsgBox}}>
            <loginToast.Provider value={{showToast, setShowToast}}>
              <headerMenu.Provider value={{showMenu, setShowMenu}}>
                <mainTheme.Provider value={{pageTheme,setPageTheme}}>
                  <commonSidebar.Provider value={{showMenu, setShowMenu}}>
                    <postSearchKey.Provider value={{searchKey, setSearchKey}}>
                      <postFeaturedKey.Provider value={{featuredKey, setFeaturedKey}}>
                        <postTagsKey.Provider value={{tagsKey, setTagsKey}}>
                      {children}
                      </postTagsKey.Provider>
                      </postFeaturedKey.Provider>
                    </postSearchKey.Provider>
                  </commonSidebar.Provider>
                </mainTheme.Provider>
              </headerMenu.Provider>
            </loginToast.Provider>
          </showMsgBox.Provider>
        </postModalData.Provider>
      </postModal.Provider>
    </loginAlert.Provider>
    </>
  )
}

export default ContextProvider