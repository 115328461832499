import React, { useState, useEffect } from 'react';
import { getSingleAdmin, getSingleAdminAccess, getSingleUser } from '../../../services/Apis';
import { useNavigate } from 'react-router-dom';

const AccessChecker = ({ accessKey }) => {
  const [accessValue, setAccessValue] = useState(false);
  const navigate = useNavigate();

  const fetchAdminAccess = async () => {
    const admin_id = sessionStorage.getItem("admin_id");
    const adminData = await getSingleAdmin(admin_id);
    if (adminData.data.user_type === "minor_admin") {
      const response = await getSingleAdminAccess(admin_id);
      if (response.status === 200) {
        const accessData = response.data;
        setAccessValue(accessData[accessKey]);
        if (!accessData[accessKey]) {
          navigate("/admin");
        }

      } else {
        console.log("Error in Fetching Admin Details.");
      }
    }
  };

  useEffect(() => {
    fetchAdminAccess();
  }, [accessKey]);

  return accessValue;
};

export default AccessChecker;
