import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getNotifsByAdmin, deleteNotif } from '../../../services/Apis';
import { useNavigate } from 'react-router-dom';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';

const ViewNotif = () => {

  const navigate = useNavigate();

  const [notifs, setNotifs] = useState([]);

  const fetchNotifs = async () => {
    try {
      const res = await getNotifsByAdmin();
      if (res.status === 200) {
        setNotifs(res.data.allNotifs);
      } else {
        console.log('Error in Fetching Notifications.');
      }
    } catch (error) {
      console.error('Error fetching Notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotifs();
  }, []);

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    const dateTime = new Date(dateTimeString);
    return new Intl.DateTimeFormat('en-US', options).format(dateTime);
  };

  const handleDeleteNotif = async (id) => {
    const response = await deleteNotif(id);
    if (response.status === 200) {
      fetchNotifs();
    }
    else {
      alert("Cannot Delete Notification");
    }
  }

  return (
    <>
      <AccessChecker accessKey="view_send_notifications" />
      <div className='form-card expand-card'>
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Users</h1>
            <div><Link to="/admin/add-user"><button className='btn btn-primary'>Add User</button></Link></div>
          </div>
        </div>
        <div>
          <hr />
          <table class="table table-bordered" style={{ width: '90%', margin: 'auto', marginTop: '30px' }}>
            <thead>
              <th className='text-left'>Sl No.</th>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Content</th>
              <th>Status</th>
              <th>Sent Date</th>
              <th>Redirect Url</th>
              <th>Delete</th>
            </thead>

            <tbody>
              {notifs.map((notif, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{notif.sender}</td>
                  <td>{notif.receiver}</td>
                  <td>{notif.content}</td>
                  <td>{notif.seen_status ? "seen" : "unseen"}</td>
                  <td>{formatDateTime(notif.sent_date)}</td>
                  <td style={{ "cursor": "pointer" }} onClick={() => navigate(notif.redirect_link)} className='text-center'><i class="fa-solid fa-arrow-up-right-from-square" style={{ color: "green" }} ></i></td>
                  <td style={{ "cursor": "pointer" }} onClick={() => handleDeleteNotif(notif._id)} className='text-center'><i className="fa-solid fa-trash" style={{ color: "red" }}></i></td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>

      </div>
    </>
  )
}

export default ViewNotif;