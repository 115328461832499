import React, { useEffect, useState, useContext } from 'react';
import './SHome.css';
import { loginAlert } from '../../../components/context/ContextProvider';
import { getAllPostsWithUser, getUserLikedPosts, postLikeUnlike, addComment, increasePostShareViews, createChat, getUserFollowings, userFollowUnfollow, getAllCommentsWithUser, featuredTags, getUserCollections, addCollection } from '../../../../services/Apis';
import { BASE_URL } from '../../../../services/helper';
import TimeAgoConverter from '../../../components/functions/TimeAgoConverter';
import { isTokenValid } from '../../../components/functions/OtherFunctions';
import { useNavigate, NavLink } from 'react-router-dom';
import SharePostModal from '../large-view/SharePostModal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForYou = () => {
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  //<----------------------------------------------- Filter Topbar ----------------------------------------------->
  const settings = {
    className: 'slider variable-width',
    variableWidth: true,
    initialSlide: -1,
    swipeToSlide: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true, // Enables variable width to handle the last slide better
        }
      }
    ]
  };

  const [featureTags, setFeatureTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true);

  const fetchFeaturedTags = async () => {
    try {
      const res = await featuredTags();
      if (res.status === 200) {
        setFeatureTags(res.data)
      }
      else {
        console.log("Error in Fetching Featured Tags");
      }
    }
    catch (error) {
      console.log(error);
    }
    finally{
      setLoadingTags(false);
    }
  }

  useEffect(() => {
    fetchFeaturedTags();
  }, [])

  const [tagsKey, setTagsKey] = useState('');

  const handleTagClick = (tag) => {
    if(tagsKey === tag){
      setTagsKey("");
    }
    else{
      setTagsKey(tag);
    }
  };

  const username = sessionStorage.getItem('user_username') || "";
  const { login, setLogin } = useContext(loginAlert);
  const user_id = sessionStorage.getItem('user_id') || "";

  //<----------------------------------------------- Handle Modal Open and Set Data ----------------------------------------------->
  const postClick = async (post_id, post_by) => {
    const response = await increasePostShareViews(post_id, { action: "viewed" });
    if (response.status === 200) {
      navigate(`view-post/${post_by}/${post_id}`)
    }
    else {
      // alert("Cannot View Post");
      toast.error("Cannot View Post");
    }
  }

  //<----------------------------------------------- Fetch All Posts ----------------------------------------------->
  const [allPost, setAllPost] = useState([]);
  const [loadingPost, setLoadingPost] = useState(true);

  const fetchPosts = async () => {
    try {
      const res = await getAllPostsWithUser("", "", "", tagsKey);
      if (res.status === 200) {
        setAllPost(res.data);
      } else {
        console.log('Error in Fetching Posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
    finally{
      setTimeout(() => {
        setLoadingPost(false);
      }, 500);
    }
  };

  //<----------------------------------------------- Fetch Posts Liked by user ----------------------------------------------->
  const [postLiked, setPostLiked] = useState([]);
  const getLikedPost = async () => {
    try {
      const res = await getUserLikedPosts(username);
      if (res.status === 200) {
        setPostLiked(res.data.likedPosts);
      } else {
        console.log('Error in Fetching Liked Posts.');
      }
    } catch (error) {
      console.error('Error fetching liked posts:', error);
    }
  };

  //<----------------------------------------------- UseEffect Function ----------------------------------------------->
  useEffect(() => {
    fetchPosts();
    if (isValid === true) {
      getLikedPost();
    }
  }, [username, tagsKey]);

  //<----------------------------------------------- Handle Like Unlike ----------------------------------------------->  
  const handleLike = async (post_id, posted_by) => {
    if (isValid) {
      const data = { post_id, liked_by: username, posted_by };
      try {
        const res = await postLikeUnlike(data);
        if (res.status === 200) {
          getLikedPost();
          fetchPosts();
        }
        console.log(data);
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    }
    else {
      toast.error("Please Login");
    }
  };

  //<----------------------------------------------- Handle Comment ----------------------------------------------->
  const [commBox, setCommBox] = useState({
    post_id: "",
    show: false
  });
  const showCommBox = (post_id) => {
    if (isValid) {
      setCommBox(prevState => ({
        post_id: post_id,
        show: !prevState.show
      }));
    } else { 
      // alert("Please Login First") 
      toast.error("Please Login") 
    }
  }

  const [comm, setComm] = useState("");
  const postComment = async (post_id, posted_by) => {
    const commentData = { post_id, content: comm, commented_by: username, posted_by };
    if (comm.trim() === "") {
      toast.error("Comment cannot be Empty");
    } else {
      try {
        const res = await addComment(commentData);
        if (res.status === 200) {
          setCommBox(prevState => ({ post_id: "", show: !prevState.show }));
          setComm("");
          fetchPosts();
          toast.success("Comment Successfully");
        } else {
          toast.error("Comment Cannot be Added Now");
        }
      } catch (error) {
        console.error('Error:', error.response.data);
        toast.error("Comment Cannot be Added Now");
      }
    }
  };

  //<----------------------------------------------- Message Field ----------------------------------------------->
  const handleOpenChat = async (post_by) => {
    if (isValid === true) {
      if(username == post_by){
        navigate("/message");
      }
      else{
      const data = { sender: username, receiver: post_by };
      try {
        const config = { 'Content-Type': 'application/json', };
        const res = await createChat(data, config);
        if (res.status === 200) {
          navigate("/message");
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    }
    }
    else {
      toast.error("Please Login");
    }
  }
  //<----------------------------------------------- Follow Unfollow Field ----------------------------------------------->
  const [followings, setFollowings] = useState([]);
  const fetchFollowings = async () => {
    try {
      const res = await getUserFollowings(username);
      if (res.status === 200) {
        setFollowings(res.data.followings);
        console.log(res.data.followings);
      } else {
        console.log('Error in Fetching Collections.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFollow = async (post_user_id) => {
    if (isValid) {
      const data = { user_id: post_user_id, follower_id: user_id };
      try {
        const config = { 'Content-Type': 'application/json', };
        const res = await userFollowUnfollow(data, config);
        if (res.status === 200) {
          fetchFollowings();
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  };
  //<----------------------------------------------- Follow Unfollow Field ----------------------------------------------->
  const [allCommentsUser, setAllCommentsUser] = useState([]);

  const fetchAllCommentsWithUser = async () => {
    try {
      const res = await getAllCommentsWithUser();
      setAllCommentsUser(res.data);
    }
    catch (error) {
      console.error('Error:', error.response.data);
    }
  }
  //<----------------------------------------------- Post Share Modal ----------------------------------------------->

  const [modalShow, setModalShow] = useState(false);
  const [postLink, setPostLink] = useState()
  const [postShareId, setPostShareId] = useState('');
  const handlePostModal = (post_id) => {
    setModalShow(true);
    setPostLink(`https://www.yoeavnu/view-post/${post_id}`)
    setPostShareId(post_id);
  }

  const clearTagsFilter = () => {
    setTagsKey("");
  }

  //<----------------------------------------------- Fetch Collections ----------------------------------------------->
  const [collections, setCollections] = useState([]);
    const fetchCollections = async () => {
      try {
        const res = await getUserCollections(username);
        if (res.status === 200) {
          setCollections(res.data);
        } else {
          console.log('Error in Fetching Collections.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    //<----------------------------------------------- Handle Collections ----------------------------------------------->
    const handleCollection = async (post_id, post_img, post_title, posted_by) => {
      if (isValid === true) {
        const data = { post_id, post_img, post_title: post_title, posted_by, user_id };
        try {
          const config = { 'Content-Type': 'application/json' };
          const res = await addCollection(data, config);
          if (res.status === 200) {
            fetchCollections(username);
          }
        } catch (error) {
          console.error('Error:', error.response.data);
        }
      } else {
        toast.error("Please Login First");
      }
    };

    useEffect(() => {
      fetchAllCommentsWithUser();
      if (isValid === true) {
        fetchFollowings();
        fetchCollections();
      }
    }, []);

  return (
    <>
      <div className="container-fluid" id="small-topbar-filter">
        <div className="slider-wrap">
          <Slider {...settings}>
            <div className={`link-btn ${tagsKey === "" ? ('active-link-btn') : ('')}`} onClick={clearTagsFilter}>
              <a>All</a>
            </div>
            {
            loadingTags?(<div>Loading Tags.....</div>):
            featureTags.length > 0 ? (
              featureTags.map((tag, index) => (
                <div className={`link-btn ${tagsKey === tag.tag ? ('active-link-btn') : ('')}`} key={index} onClick={() => handleTagClick(tag.tag)} >
                  <a>{tag.tag}</a>
                </div>
              ))
            ) : (<div>--- No Tags to Show ---</div>)}
          </Slider>
        </div>
      </div>
      <div className="container-fluid" id="s-home">
        <div className="row">
          {/* <--------------------------------- Post Start --------------------------------- >*/}
          {
            loadingPost?(
              <div id="small-post-loader">
              <div className="wrap-div col-12">
                <div className="row">
                  <div className="col-12 profile-wrap">
                    <div className="profile-pic"></div>
                    <div className="username-div"></div>
                  </div>
                  <div className="col-12">
                    <div className="post-div"></div>
                  </div>
                  <div className="col-12">
                    <div className="comments-div"></div>
                    <div className="comments-div"></div>
                  </div>
                </div>
              </div>
            </div>
            ):
          allPost.length > 0 ? (
            allPost.map((post, index) => {
              if (post.status !== "active" || !post.user) {
                return null;
              }
              return (
                <>
                  <div className="post" key={index + 1}>
                    <div className="col-2">
                      <div className="profile-img">
                        <NavLink to={`/${post.user.username}`}>
                          <img src={`${BASE_URL}/uploads/admins-users-img/${post.user.user_img}`}  alt="profile" />
                        </NavLink>
                        {followings.length > 0 ? ('') : ('')}
                        {username !== post.user.username ? (
                          <span className='follow-btn' onClick={() => handleFollow(post.user._id)}>
                            {followings.length > 0 ?
                              (
                                followings.some((follow) => follow.user_id === post.user._id) ?
                                  (<i className="fa-solid fa-check"></i>) :
                                  (<i className="fa-solid fa-plus"></i>)
                              ) :
                              (<i className="fa-solid fa-plus"></i>)}
                          </span>
                        ) : ('')}
                      </div>
                      <div className="vertical-line"></div>
                      <div className="comment-people">
                        {allCommentsUser.length > 0 &&
                          allCommentsUser.some((comm) => comm.post_id === post._id) ? (
                          allCommentsUser
                            .filter((comm) => comm.post_id === post._id).slice(0, 2).map((comm, index) => (
                              <img key={index} src={`${BASE_URL}/uploads/admins-users-img/${comm.user.user_img}`} alt="" className={`img-small-avatar img${++index}`} />
                            ))
                        ) : (
                          ''
                        )
                        }
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="post-header">
                        <div className="post-info">
                          <div className="user-name">{post.user.fname + " " + post.user.lname}</div>
                          <div className="options"><span><TimeAgoConverter dateTime={post.post_date} /></span></div>
                        </div>
                        <div className="post-title">{post.title}</div>
                      </div>
                      <div className="post-img">
                        <img src={`${BASE_URL}/uploads/posts-img/${post.post_img}`} alt={post.post_img} onClick={() => postClick(post._id, post.user.username)} />
                      </div>
                      <div className="post-options">
                        <div className="left-options">
                        {postLiked.length > 0 ? (
                          postLiked.some((liked) => liked.post_id === post._id) ? (
                            <span><i class="fa-regular fa-heart" style={{ color: 'red' }} onClick={() => handleLike(post._id, post.user.username)} ></i></span>
                          ) : (
                            <span><i class="fa-regular fa-heart" onClick={() => handleLike(post._id, post.user.username)}></i></span>
                          )
                        ) : (
                          <span><i class="fa-regular fa-heart" onClick={() => handleLike(post._id, post.user.username)}></i></span>
                        )}
                        <span><i class="fa-regular fa-comment" onClick={() => showCommBox(post._id)}></i></span>
                        {/* <span><i class="fa-regular fa-share-from-square" onClick={() => handlePostModal(post._id)}></i></span> */}
                        <span><i class="fa-regular fa-paper-plane" onClick={() => handleOpenChat(post.user.username)}></i></span>
                          </div>
                          <div className="right-options">
                            <span onClick={()=>handleCollection(post._id, post.post_img, post.title, post.user.username)}>{collections.length > 0 ? collections.some((coll) => coll.post_id === post._id) ? <i class="fa-solid fa-bookmark" style={{ color: 'rgb(59, 113, 202)' }}></i> : <i class="fa-regular fa-bookmark"></i> : <i class="fa-regular fa-bookmark"></i>}</span>
                          </div>
                      </div>
                      {commBox.post_id === post._id && commBox.show === true ? (<>
                        <div className='comment-box'>
                          <textarea rows="1" onChange={(e) => setComm(e.target.value)}></textarea>
                          <button type='button' onClick={() => postComment(post._id, post.user.username)}>Post</button>
                        </div>
                      </>) : ('')}
                      <div className="post-footer">
                        <span>{post.likes} likes</span>{" . "}
                        <span>{post.comments} comments</span>
                      </div>
                    </div>
                  </div>
                  <div className="post-separate"></div>
                  <div style={{ "margin": "0" }} className='post-separate-line'></div>
                  <div className="post-separate"></div>
                </>
              );
            })
          ) : (
            <div>No Posts Uploaded</div>
          )}

          {/* <--------------------------------- Post End --------------------------------- >*/}
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
      <SharePostModal show={modalShow} postLink={postLink} onHide={() => setModalShow(false)} post_id={postShareId} />
    </>
  )
}

export default ForYou