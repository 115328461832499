import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllEmails } from '../../../services/Apis';
import AccessChecker from '../../components/OtherFunctions/AccessChecker';

const ViewMails = () => {
  const [emailData, setEmailData] = useState([]);

  const fetchNotifs = async () => {
    try {
      const res = await getAllEmails();
      if (res.status === 200) {
        setEmailData(res.data);
        console.log(res.data)
      } else {
        console.log('Error in Fetching Emails.');
      }
    } catch (error) {
      console.error('Error fetching Emails:', error);
    }
  };

  useEffect(() => {
    fetchNotifs();
  }, []);

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    const dateTime = new Date(dateTimeString);
    return new Intl.DateTimeFormat('en-US', options).format(dateTime);
  };

  return (
    <>
      <AccessChecker accessKey="view_send_mails" />
      <div className='form-card expand-card'>
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Mails</h1>
            <div><Link to="/admin/send-mail"><button className='btn btn-primary'>Send Mail</button></Link></div>
          </div>
        </div>
        <div>
          <hr />
          <table class="table table-bordered" style={{ width: '90%', margin: 'auto', marginTop: '30px' }}>
            <thead>
              <th className='text-left'>Sl No.</th>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Sent Date</th>
            </thead>

            <tbody>
              {emailData.map((email, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{email.sender_email}</td>
                  <td>{email.receiver_email}</td>
                  <td>{email.subject}</td>
                  <td>{email.message}</td>
                  <td>{formatDateTime(email.sent_date)}</td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>

      </div>
    </>
  )
}

export default ViewMails;